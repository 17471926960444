import Vue from 'vue'
import Loading from 'vue-loading-overlay';
import loaderImg from "@/assets/images/loader.png";
import Endpoint from "@/resource/EndPoints/endpoints"

import "@/assets/css/loader.css"
import 'vue-loading-overlay/dist/vue-loading.css';
const custom = Vue.extend({
  render() {
    return (
      <div class="row">
        <div class="loader-logo">
          <img class="img-fluid" src={loaderImg} />
        </div>
      </div>
    );
  }
});

Vue.component('custom-loader', custom);
Vue.use(Loading, {
  opacity: 0.5,
  zIndex: 9999,
}, {
  default: (new Vue()).$createElement('custom-loader')
});


Vue.prototype.$notify = (message, type = "success", position = 'top-right') => {
  const vm = new Vue()

  let typeTitle;
  typeTitle = type == "danger" ? 'error' : type
  vm.$toast.open({
    message: message,
    type: typeTitle.toLowerCase(),
    duration: 5000,
    dismissible: true,
    position: position
  })
}

Vue.prototype.$endpoint = Endpoint
Vue.config.productionTip = false

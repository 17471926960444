<template>
  <b-form-group>
    <!-- only worked for value which have key id , value name -->
    <validation-provider
      :name="labelName"
      :rules="rules"
      v-slot="validationContext"
    >
      <label>
        <span v-html="computedLabel" />
      </label>
      <multiselect
        :name="labelName"
        v-model="computedInputData"
        :options="options"
        :custom-label="customLabel"
        :multiple="true"
        :searchable="true"
        :placeholder="computedPlaceholder"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :hide-selected="false"
        :show-no-results="false"
        @remove="removeHandler"
        @select="selectHandler"
        @input="toggleHandler"
        @focus="autoCompleteHandler"
        :class="selectClassName(validationContext)"
        :loading="isLoading"
        :options-limit="optionsLimit"
        :internal-search="localSearch"
        :local-search="localSearch"
        :showNoResults="true"
        @search-change="searchHandler"
        @open="openHandler"
      >
        <template slot="selection" slot-scope="{ values }">
          <span class="multiselect__single" v-if="values.length">
            {{ values.includes("ALL") ? 'All ' : values.length }}
            {{ selectionLabel }} Selected
          </span>
        </template>
      </multiselect>
      <b-form-invalid-feedback
        :state="getValidationState(validationContext)"
        id="input-feedback "
      >
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </validation-provider>
  </b-form-group>
</template>
<script>
export default {
  name: "InputMultiSelectMultiple",
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      required: false,
      default: "text",
    },
    vmodel: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    activeData: {
      required: true,
    },
    options: {
      required: true,
    },
    customLabel: {
      required: false,
    },
    optionsLimit: {
      required: false,
      default:300
    },
    labelKey: {
      required: false,
    },
    localSearch: {
      required: false,
    },
    isLoading:{
      required:false
    },
    selectionLabel: {
      required: false,
      default:'Users'
    },
  },
  data: () => ({
    isAll: false,
    allCount: 0,
    inputSelectData: [],
  }),
  computed: {
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `Select ${this.labelName}`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function () {
        if(this.vmodel){
          return this.vmodel;
        }
        return this.inputSelectData;
      },
      set: function (value) {
        this.inputSelectData = value;
        this.$emit("update:vmodel", value ? value : []);
      },
    },
  },
  methods: {
    searchHandler(value) {
      this.$emit("search",value);
    },
    openHandler() {
      this.$emit("open");
    },
    selectClassName(validationContext) {
      let className = "";
      if (validationContext.errors[0]) {
        className = "invalid-input-block";
      } else if (validationContext.valid) {
        className = "valid-input-block";
      } else {
        className = "";
      }
      return `${className} ${this.inputClass}`;
    },
    autoCompleteHandler() {
      document
        .querySelector(".multiselect__input")
        .setAttribute("autocomplete", "off");
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    toggleHandler(value) {
      if (this.isAll) {
        if (value.includes("ALL")) {
          let all = [];
          this.activeData.forEach((val) => {
            //need to update for dynamic key
            if (this.labelKey == "email") {
              all.push(val.email);
            } else {
              all.push(val.id);
            }
          });
          this.inputSelectData = all;
          this.allCount = this.inputSelectData.length;
          this.$emit("update:vmodel", all);
        } else {
          this.inputSelectData = [];
          this.isAll = false;
          this.$emit("update:vmodel", []);
        }
      } else {
        //add all value
        this.isAll = false;
        let selectValues = this.inputSelectData.filter((x) => {
          return x !== "ALL";
        }).length;
        let allmultiSelectExceptAll = this.activeData.filter((x) => {
          return x.selectId !== "ALL";
        }).length;
        if (selectValues === allmultiSelectExceptAll) {
          this.allCount = this.allCount - 1;
          this.inputSelectDatadata.push("ALL");
        }
      }
      let values = this.inputSelectData;
      if (values.length < this.allCount) {
        this.inputSelectData = values.filter((e) => {
          return e !== "ALL";
        });
      }
    },
    selectHandler(value) {
      if (value == "ALL") {
        this.isAll = true;
      } else {
        this.isAll = false;
      }
    },
    removeHandler(value) {
      if (this.activeData.length !== this.inputSelectData && this.inputSelectData.includes('ALL')) {
        this.inputSelectData.shift();
      }
      if (value == "ALL") {
        this.isAll = true;
      } else {
        this.isAll = false;
      }
    },
  },
  watch: {
    vmodel: function (newValue) {
      //if vmodel is less than 1 set this v-model = []
      if (newValue.length < 1) {
        this.inputSelectData = [];
      } else {
         this.inputSelectData = newValue;
      }
    },
  },
};
</script>
<style>
.input-required {
  color: red !important;
}

.invalid-input-block > .multiselect__tags {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.valid-input-block > .multiselect__tags {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": _vm.link,
      "target": "_blank",
      "download": "",
      "title": _vm.title
    }
  }, [_c('i', {
    staticClass: "fas fa-download"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
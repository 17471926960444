const state = {
  dialogClass: "group-app-confirm-dialog",
  sendRepMessageSelectedReps:[],
  sendRepMessageCheckedRep:false,
};

const getters = {
  dialogClass: state => state.dialogClass,
  sendRepMessageSelectedReps: state => state.sendRepMessageSelectedReps,
  sendRepMessageCheckedRep: state => state.sendRepMessageCheckedRep,
};

const actions = {
  getDialogClass({ commit }, val) {
      if(val){
        commit("setDialogClass", val);
      }
  },
};

const mutations = {
  setDialogClass(state, className) {
    state.dialogClass = className;
  },
  setSendRepMessageSelectedReps(state, data) {
    state.sendRepMessageSelectedReps = [...data];
  },
  setSendRepMessageCheckedRep(state,value){
    state.sendRepMessageCheckedRep = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_c('div', {
    staticClass: "upload_section"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', {
          attrs: {
            "id": "label-".concat(_vm.labelFor),
            "for": _vm.labelFor
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        })]), _c('b-form-file', {
          ref: _vm.labelFor,
          attrs: {
            "id": "upload_image",
            "browse-text": "Choose File",
            "accept": "image/*",
            "state": _vm.getValidationState(validationContext),
            "readonly": _vm.readonly,
            "placeholder": _vm.computedPlaceholder
          },
          on: {
            "change": _vm.fileHandler
          },
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]), _vm.showApiError ? _c('div', [_c('span', {
          staticClass: "error-text"
        }, [_vm._v(_vm._s(_vm.apiErrors[_vm.fieldName][0]))])]) : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticClass: "uploaded",
    class: _vm.formGroupClass
  }, [_c('img', {
    class: _vm.imgClass,
    attrs: {
      "src": _vm.imageSrc
    }
  }), _vm.imageSrc.includes('https') && _vm.deleteIcon ? _c('img', {
    staticClass: "upload-close-icon",
    attrs: {
      "src": require("../../assets/images/close-icon.svg")
    },
    on: {
      "click": _vm.deleteImage
    }
  }) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="site-sidebar sidebar-section">
    <sidebar-menu
      @item-click="onItemClick"
      :menu="sideBarMenu"
      width="225px"
    />
    <span class="no-sidebar-menu d-lg-inline d-none" v-if="isLoading">
      Initializing Menu
      <spinner-grow />
    </span>
  </div>
</template>
<script>
import helper from "@/resource/Helper";
import { SidebarMenu } from "vue-sidebar-menu";
import home from "../assets/images/home.svg";
import manage from "../assets/images/manage.svg";
import logout from "../assets/images/logout.svg";
import pendings from "../assets/images/pending.svg";
import directory from "../assets/images/directory.svg";
import notificationLogo from "@/assets/images/bell.svg";
import analytics from "../assets/images/analaytics.svg";
import emarketing from "../assets/images/emarketing.svg";
import address from "../assets/images/address-card.svg";
import email from "../assets/images/mail.svg";
import phone from "../assets/images/phone.svg";
import person from "../assets/images/person.svg";
import groupQuote from '../assets/images/groupQuote.svg';
// import commissions from "../assets/images/commission.svg";
import business from "../assets/images/business.svg"
import pqs from "../assets/images/pqs.svg";
import benefitlogo from "../assets/images/benefit-store-white.svg";
import neurablog from "../assets/images/blog.svg"
import crm from "../assets/images/crm.png"
import acm from"../assets/images/acm-side.svg"
//import calenderLogo from "../assets/images/calendar-rep.svg";
import downlineTree from "@/assets/images/tree.svg"
import Api from "@/resource/Api";
import SpinnerGrow from '@/components/ui/SpinnerGrow';
export default {
  name: "Sidebar",
  components: {
    SidebarMenu,
    SpinnerGrow,
  },
  data() {
    return {
      menuList:[],
      isLoading:false,
      sideBarMenu:[], //generated based on api response
      showCrmMenu: false
    };
  },
  computed: {
    upLineInfo () {
      return this.$store.getters.upLineInfo
    },
    menu () {
       return [
         {
           href: "/",
           title: "Home",
           icon: {
             element: "img",
             attributes: { src: home },
           },
           key:'home'
         },
         {
           href: "/directory",
           title: "Directory",
           icon: {
             element: "img",
             attributes: { src: directory },
           },
           key:'directory'
         },
         {
           href: "/view-group-quote",
           title: "Group Quotes",
           icon: {
             element: "img",
             attributes: { src: groupQuote },
           },
           key: 'groupQuote'
         },
         {
           href: "",
           title: "Manage",
           icon: {
             element: "img",
             attributes: { src: manage },
           },

           key:'manage',
           child: [
             {
               href: "/view-members",
               title: "View Members",
               key:'manage_view_clients'
             },
             {
               href: "/view-employers",
               title: "View Employers & Group",
               key:'manage_employe_and_group'
             },
             {
               href: "/view-downline-reps",
               title: "View Downline Reps",
               key:'manage_view_downline_rep'
             },
             // {
             //   href: "",
             //   title: "View Downline Reps Analytics",
             // },
             {
               href: "/rep-referral",
               title: "Rep Referral",
               key:'rep-referral-view'
             },
             {
               href: "/leads",
               title: "View Leads",
               key:'manage_leads_questions'
             },
             {
               href: "/my-files",
               title: "My Files",
               key:'my_files'
             },
             {
               href: "/licenses",
               title: "Licenses",
               key:'licenses'
             },
             {
               href: "/configured-plans",
               title: "Configured Plans",
               key:'add_homepage_plans'
             },
             {
               href: "/manage-video",
               title: "Manage Videos",
               key:'manage_videos'
             },
             {
               href: "/update-requests",
               title: "Requests",
               key:'rep_info_request'
             },
             {
               href: "/knowledge-feeds",
               title: "Knowledge Feeds",
               key:'knowledge_feeds'
             },
             {
               href: "/manage-menu",
               title: "Manage Menu",
               key:'manage_menu'
             },
             {
               href: "/tickets",
               title: "Tickets",
               key:'manage_tickets'
             },
             {
               href: "/campaign-stats",
               title:  "Campaign Stats",
               key:'cor_contract'
             }
           ],
         },
        //  {
        //    href: "/commissions",
        //    hidden: helper.hideSideBar(),
        //    title: "Commissions",
        //    icon: {
        //      element: "img",
        //      attributes: { src: commissions },
        //    },
        //    key:'commissions'
        //  },
         {
           href: "/business",
           hidden: helper.hideSideBar(),
           title: "Business Statistic",
           icon: {
             element: "img",
             attributes: { src: business },
           },
           key:'business'
         },
         {
           href: "/rep-analytics",
           external: false,
           title: "Analytics",
           icon: {
             element: "img",
             attributes: { src: analytics },
           },
           key:'analytics'
         },
         {
           href: "/admin-message",
           title: "Message Center",
           icon: {
             element: "img",
             attributes: { src: emarketing },
           },
           key:'message_center'
         },
         {
           href: "/pending-items",
           title: "Pending Items",
           icon: {
             element: "img",
             attributes: { src: pendings },
           },
           key:'pending_items'
         },
         {
           href: "/recent-actvities",
           title: "Recent Activity",
           icon: {
             element: "img",
             attributes: { src: notificationLogo },
           },
           key:'recent_activity'
         },
         {
           href: "/downline-tree",
           title: "Downline Tree",
           key:  'downline_tree',
           icon: {
             element: "img",
             attributes: { src: downlineTree },
           },
         },
         {
           href: "",
           title: "Calendar",
           icon: {
             element: "img",
             attributes: { src: manage },
           },
           child: [
             {
               href: helper.calendarAppLink(),
               title: "Calendar",
               external: true,
             },

             {
               href: "/calendar-events",
               title: "Events",
             },
             {
               href: "/calendar-bookings",
               title: "Bookings",
             }

           ],
         },

         /* {
            href: helper.calendarAppLink(),
            external: true,
            title: "Calendar",
            class: "benefit-store-side-menu",
            icon: {
              element: "img",
              attributes: { src: calenderLogo },
            },
            key:'benefit_store'
          },*/
         {
           href: "/view-quotes",
           title: "PQS",
           class: 'benefit-store-side-menu',
           icon: {
             element: "img",
             attributes: { src: pqs },
           },
           key:'view_quotes'
         },
         {
           href: helper.benefitStoreLink(),
           external: true,
           title: "Benefit Store",
           class: "benefit-store-side-menu",
           icon: {
             element: "img",
             attributes: { src: benefitlogo },
           },
           key:'benefit_store'
         },
         {
           href: this.$endpoint.CRM_URL,
           external: true,
           title: "CRM",
           hidden: this.checkCrmMenuState(),
           class: "benefit-store-side-menu",
           icon: {
             element: "img",
             attributes: { src: crm },
           },
           key:'crm'
         },
         {
           href: this.$endpoint.ACM_URL,
           external: true,
           title: "ACM",
           class: "benefit-store-side-menu",
           icon: {
             element: "img",
             attributes: { src: acm },
           },
           key:'acm'
         },
         {
           href:"/neura-blogs",
           title: "Neura Blogs",
           class: "benefit-store-side-menu",
           icon: {
             element: "img",
             attributes: { src: neurablog },
           },
           key:'nuera_blogs'
         },

         {
           title: "Direct Contact Rep",
           class: "contact-rep",
           icon: {
             element: "img",
             attributes: { src: address },
           },
           child:[
             {
               title: this.upLineInfo.name,
               class:"upline-sidebar-icon cursor-text",
               icon: {
                 element: "img",
                 attributes: { src: person },
               }
             },
             {
               title: this.upLineInfo.email,
               class:"upline-sidebar-icon",
               key:'upline-email-info',
               icon: {
                 element: "img",
                 attributes: { src: email },
               }
             },
             {
               title: this.upLineInfo.telephone,
               icon: {
                 element: "img",
                 attributes: { src: phone },
               },
               key:'upline-phone-info',
               class:"upline-sidebar-icon",
             }
           ]
         },

         // {
         //   href: "",
         //   title: "Admin",
         //   icon: {
         //     element: "img",
         //     attributes: { src: manage },
         //   },
         //   child: [
         //     {
         //       href: "/admin-analytics",
         //       title: "Admin Analytics",
         //     },
         //     {
         //       href: "/rep-logins",
         //       title: "Rep Logins",
         //     },
         //     {
         //       href: "/not-logged-in-reps",
         //       title: "Not loggedIn Reps",
         //     },
         //     {
         //       href: "/member-logins",
         //       title: "Member Logins",
         //     },
         //   ],
         // },
         // {
         //   href: "/rep-analytics",
         //   title: "Rep Analytics",
         //   icon: {
         //     element: "img",
         //     attributes: { src: pendings },
         //   },
         // },
         {
           href: "/logout",
           title: "Logout",
           icon: {
             element: "img",
             attributes: { src: logout },
           },
           key:'logout'
         },
       ]
     },
    agentLevel () {
      return this.$store.getters.rep.agentLevel
    },
    agentLevel2 () {
      return this.$store.getters.rep.agentLevel2
    },
  },

  methods: {
    onItemClick(event, item) {
      if (item.title == "Logout") {
        helper.logout();
      }
      if (item.key == "upline-email-info") {
        window.open(`mailto:${this.upLineInfo.email}`, '_blank');
      }
      if (item.key == "upline-phone-info") {
        window.open(`tel:${this.upLineInfo.telephone}`, '_blank');
      }
    },
    getSidebarMenu() {
      let path = "v2/rep/get-menu";
      this.isLoading = true;
      Api.get(path)
        .then((res) => {
          this.menuList = res.data.data;
          this.updateMenuItems();
        })
        .catch((err) => {
          this.menuList = [];
          console.error("Error : ", err.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkCrmMenuState () {
      let app = this
      if (app.agentLevel >=4 || app.agentLevel2 >= 4) {
        return false
      } else {
        return true
      }
    },
    updateMenuItems() {
      this.$store.dispatch("setHiddenMenu",null);
      let updatedMenu = [];
      this.menu.forEach((menuItem,index)=>{
        let menu = this.searchMenuObject(menuItem.key,'');
        if(menu){
          if(menu.status){
            if(menuItem.child && menuItem.child.length){
              let childMenuItems = this.menu[index].child;
              childMenuItems.forEach((childItem,childIndex)=>{
                let childMenu = this.searchMenuObject(menuItem.key,childItem.key);
                if(childMenu){
                  if(!childMenu.status){
                    this.$store.dispatch("setHiddenMenu",childItem);
                    menuItem.child.splice(childIndex,1);
                  }
                }
              })
            }
            updatedMenu.push(menuItem);
          }else{
            //parent menu item
            this.$store.dispatch("setHiddenMenu",menuItem);
          }
        }else{
          updatedMenu.push(menuItem);
        }
      })
      this.sideBarMenu = updatedMenu;
    },

    searchMenuObject(parentKey, childKey = ''){
      if(this.menuList.length < 0){
        return '';
      }
      let parentMenu =  this.menuList.find((item)=>item.menu_key  == parentKey);
      if(!parentMenu){
        return '';
      }
      if(!childKey){
        return parentMenu;
      }
      if(parentMenu.sub_category && parentMenu.sub_category.length){
        return parentMenu.sub_category.find((item)=>item.menu_key  == childKey);
      }
      return '';
    },

    getUpLineInfo () {
      let path = "v2/get-upline-info";
      this.isLoading = true;
      Api.get(path)
          .then((res) => {
            console.log(res)
            this.$store.commit("setUpLineInfo", res.data.data);
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  },
  mounted() {
    window.addEventListener("resize", function () {
      if (
        (document.querySelectorAll(".vsm_expanded").length &&
          screen.width <= 992) ||
        (!document.querySelectorAll(".vsm_expanded").length &&
          screen.width > 992)
      ) {
        document
          .querySelector(".vsm--toggle-btn")
          .dispatchEvent(new Event("click"));
      }
    });

    if (
      (document.querySelectorAll(".vsm_expanded").length &&
        screen.width <= 992) ||
      (!document.querySelectorAll(".vsm_expanded").length && screen.width > 992)
    ) {
      document
        .querySelector(".vsm--toggle-btn")
        .dispatchEvent(new Event("click"));
    }

    this.getSidebarMenu();
  },
  created () {
    this.getUpLineInfo();
  }
};
</script>

<style src="@/assets/css/common.css"></style>
<style>
.benefit-store-side-menu {
  margin-top: 15px;
  background: transparent linear-gradient(103deg,#8360c3,#2ebf91) 0 0 no-repeat padding-box;
  border-radius: 8px;
  box-shadow: inset 0 3px 6px rgb(0 0 0 / 16%);
  color: #FFF!important;
  display: flex !important;
  align-items: center !important;
  padding: 0.9rem 20px !important;
}
.benefit-store-side-menu.vsm--link_active {
  margin-top: 15px;
  margin-left: 0px !important;
}
.benefit-store-side-menu img {
  width: 25px !important;
  filter: none !important;
  /* padding-top: 5px; */
}
.benefit-store-side-menu span {
  vertical-align: super !important;
  vertical-align: middle;
  font-weight: bold;
}
@media (max-width: 992px) {
  .benefit-store-side-menu {
    margin-left: 0px;
    background:none;
  }
}


</style>
<style scoped>
.no-sidebar-menu{
  position: fixed;
  color:#ccc;
  z-index:1000;
  font-size: 14px;
  max-width: 180px !important;
  padding: 0px 15px;
  margin-top:20px;
}
</style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "upload-excel",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    on: {
      "show": _vm.showExcelModal
    }
  }, [_c('div', {
    staticClass: "title-text text-center mb-3"
  }, [_c('h5', [_c('strong', [_vm._v("Import Group Quote Excel")])])]), _c('div', {
    staticClass: "text-end mb-2"
  }, [_c('span', {
    staticClass: "download-template",
    on: {
      "click": _vm.downloadSampleTemplate
    }
  }, [_vm._v("Download Template")])]), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "file-upload-container",
    on: {
      "click": _vm.triggerFileInput
    }
  }, [_c('i', {
    staticClass: "fas fa-file-excel"
  }), _c('span', [_vm._v("Click to select file .xls, .xlsx spreadsheets accepted.")]), _c('input', {
    ref: "fileInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": ".xlsx, .xls"
    },
    on: {
      "change": _vm.handleFileChange
    }
  })]), _vm.errorExcelError && !_vm.file ? _c('div', {
    staticClass: "form-error error-font-size mt-2 pl-2"
  }, [_vm._v("Excel file is required inorder to proceed.")]) : _vm._e(), _vm.fileName ? _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "mb-3 excel-file-title"
  }, [_vm._v("Imported Excel File:")]), _c('span', {
    staticClass: "file-view-template "
  }, [_vm._v(" " + _vm._s(_vm.fileName) + " "), _c('i', {
    staticClass: "fa fa-trash excel-trash-icon",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteExcelFile(_vm.fileName);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "group-univ-btn text-center mt-0",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "group-univ-btn text-center mt-0",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.uploadFile
    }
  }, [_vm._v(" Submit ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <router-link
    :to="routeLink"
    v-b-tooltip.hover
    :title="title"
    :class="className"
  >
    <i :class="iconClass" />
    {{ haveText ? title : "" }}
  </router-link>
</template>
<script>
export default {
  name: "CustomRouteButton",
  props: {
    title: {
      type: String,
      required: false,
      default: "Title",
    },
    iconClass: {
      type: String,
      required: false,
      default: "fas fa-user-check",
    },
    routeLink: {
      required: true,
      default: null,
    },
    className: {
      type: String,
      required: false,
    },
    haveText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toggle-switch switch-box"
  }, [_c('label', {
    staticClass: "switch",
    attrs: {
      "for": "checkbox"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.switchElement.apply(null, arguments);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.computedInputData,
      expression: "computedInputData"
    }],
    attrs: {
      "type": "checkbox",
      "id": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.computedInputData) ? _vm._i(_vm.computedInputData, null) > -1 : _vm.computedInputData
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.computedInputData,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.computedInputData = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.computedInputData = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.computedInputData = $$c;
        }
      }
    }
  }), _c('div', {
    staticClass: "slider round"
  })]), _c('p', {
    staticClass: "text-class"
  }, [_vm._v(" " + _vm._s(_vm.text ? _vm.text : "") + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :class="formGroupClass">
    <b-form-group>
      <validation-provider
        :name="labelName"
        :rules="rules"
        v-slot="validationContext"
      >
        <label :for="labelFor ? labelFor : ''" v-if="showLabel">
          <span v-html="computedLabel" />
        </label>
        <multiselect
          v-model="computedInputData"
          :options="options"
          :custom-label.sync="customLabel"
          :class="selectClassName(validationContext)"
          @focus.native="autoCompleteHandler"
          @input="inputHandler"
          :placeholder="placeholder"
          :disabled="isDisable"
          :internal-search="internalSearch"
          :clear-on-select="clearOnSelect"
          :close-on-select="closeOnSelect"
          @search-change="searchChangeHandler"
          :loading="isLoading"
        >
        </multiselect>
        <b-form-invalid-feedback
          :state="getValidationState(validationContext)"
      >
        {{ apiErrors ? errorField[0] : validationContext.errors[0] }}
      </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  props: {
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select Option",
    },
    vmodel: {
      required: false,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      required: true,
    },
    customLabel: {
      required: true,
    },
    isDisable: {
      required: false,
      default: false,
    },
    internalSearch: {
      required: false,
      default: true,
    },
    clearOnSelect: {
      required: false,
      default: false,
    },
    closeOnSelect: {
      required: false,
      default: true,
    },
    isLoading:{
      required:false,
      default:false
    },
      apiErrors: {
      required: false,
    },
    showLabel:{
      required:false,
      default:true,
    }
  },
  components: {
    ValidationProvider,
  },
  computed: {
     errorField() {
      return this.apiErrors ? this.apiErrors[this.labelFor] : null;
    },
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.labelName + "<span class='input-required'>*</span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function() {
        return this.vmodel;
      },
      set: function(value) {
        this.$emit("update:vmodel", value);
      },
    },
  },
  methods: {
    searchChangeHandler(search) {
      if (search && search.length >= 1) {
        this.$emit("onSearchChange", search);
      }else{
        this.$emit("onSearchChange", "");
      }
    },
    inputHandler() {
      this.$emit("onInput");
    },
    selectClassName(validationContext) {
      let className = "";
      if (this.rules) {
        if (validationContext.errors[0]) {
          className = "invalid-input-block";
        } else if (validationContext.valid) {
          className = "valid-input-block";
        } else {
          className = "";
        }
      }
      return `${className} ${this.inputClass}`;
    },
    autoCompleteHandler() {
      document
        .querySelector(".multiselect__input")
        .setAttribute("autocomplete", "off");
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style>
.input-required {
  color: red;
}

.invalid-input-block > .multiselect__tags {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.valid-input-block > .multiselect__tags {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>

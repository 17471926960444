var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', {
          attrs: {
            "for": _vm.labelFor ? _vm.labelFor : ''
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        })]), _c('multiselect', {
          key: _vm.trackBy,
          class: _vm.selectClassName(validationContext),
          attrs: {
            "options": _vm.options,
            "label": _vm.label,
            "track-by": _vm.trackBy,
            "placeholder": _vm.placeholder,
            "disabled": _vm.isDisabled
          },
          on: {
            "input": _vm.inputHandler
          },
          nativeOn: {
            "focus": function focus($event) {
              return _vm.autoCompleteHandler.apply(null, arguments);
            }
          },
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.getValidationState(validationContext),
            "id": "input-feedback"
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
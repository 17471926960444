<template>
  <div>
    <div>
      <registration-auth-header />
    </div>
    <div class="verify-container">
      <div class="verify-captcha-form">
        <div class="text-center">
          <div class="page-title mb-3">
            <h1 class="group-census-label">Group Quote - Submitter Info</h1>
          </div>
          <div class="dash-back-btn">
            <button type="submit"
                    @click="routeToDashboard"
                    class="group-univ-btn text-center mt-0">Dashboard
            </button>
          </div>
        </div>
        <b-row>
          <div class="col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3">
            <label class="label-title">Name</label>
            <span class="required-asterisk">*</span>
            <input
                type="text"
                v-model="submitterRep.name"
                class="input-field"
                :class="{'form-invalid': $v.submitterRep.name.$error}"
                placeholder="Name"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.submitterRep.name.required && $v.submitterRep.name.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3">
            <label class="label-title">Email</label>
            <span class="required-asterisk">*</span>
            <input
                type="text"
                v-model="submitterRep.email"
                class="input-field"
                @input="$v.submitterRep.email.$touch();"
                :class="{'form-invalid': $v.submitterRep.email.$error}"
                placeholder="Email"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.submitterRep.email.required && $v.submitterRep.email.$error">
              Field is required
            </div>
            <div class="form-error text-left ml-3 mt-2"  v-if="$v.submitterRep.email.required && $v.submitterRep.email.$invalid">
              Enter valid email address
            </div>
          </div>
          <div class="col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3">
            <label class="label-title">Phone Number</label>
            <span class="required-asterisk">*</span>
            <input
                type="number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="10"
                v-model="submitterRep.phoneNumber"
                @input="$v.submitterRep.phoneNumber.$touch()"
                class="input-field"
                :class="{'form-invalid': $v.submitterRep.phoneNumber.$error}"
                placeholder="Phone Number"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.submitterRep.phoneNumber.required && $v.submitterRep.phoneNumber.$error">
              Field is required
            </div>
            <div class="form-error text-left ml-3 mt-2" v-if="$v.submitterRep.phoneNumber.required && $v.submitterRep.phoneNumber.$invalid">
              The phone number field must be numeric and exactly contain 10 digits
            </div>
          </div>
          <div class="col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3" v-if="!isPlatformRedirection">
            <label class="label-title">Platform</label>
            <span class="required-asterisk">*</span>
            <select v-model="platform"
                    :class="{'form-invalid': $v.platform.$error}"
                    @change="setPlatform"
                    class="custom-select">
              <option value='' disabled>Choose Platform</option>
              <option  v-for="(platform, index) in platformList"
                       :value="platform.value"
                       :key="index">{{platform.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.platform.required && $v.platform.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3 w-100">
             <div>
              <vue-recaptcha @verify="markRecaptchaAsVerified" sitekey="6LeeIsIbAAAAADd9Uo6R_VCnruxwQykkZDiDjETU" :loadRecaptchaScript="true"  class="captcha-container"></vue-recaptcha>
              </div>
            <div class="form-error text-left ml-3 mt-2"  v-if="pleaseTickRecaptchaMessage">
              {{pleaseTickRecaptchaMessage}}
            </div>
          </div>
        </b-row>
        <div class="submit-btn-container d-flex justify-content-start">
          <div class="">
            <button type="submit" @click="submitVerifyForm"
                    class="group-univ-btn text-center">Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import {email, required} from "vuelidate/lib/validators";
import {checkValidPhoneNumber} from "../../utils/validator";
import RegistrationAuthHeader from "../../components/RegistrationAuthHeader";

export default {
  components: {VueRecaptcha, RegistrationAuthHeader},
  data() {
    return {
      submitterRep: {
        name: '',
        email: '',
        phoneNumber: ''
      },
      recaptchaVerified: false,
      pleaseTickRecaptchaMessage: '',
      platformName: '',
      isRouteFromRepEnrollmentSite: false,
      platform: '',
      platformList: [
        {text: 'Premier Enroll', value: 'premierenroll.com'},
        {text: 'Elite Enroll', value: 'eliteenroll.com'},
        {text: 'Broker Exchange', value: 'brokerexchanges.com'},
        {text: 'Goenroll123', value: 'goenroll123.com'},
        {text: 'Patroit Medical', value: 'patriotmedicalplan.com'}
      ]
    }
  },
  computed: {
    submitterRepInfo () {
      return this.$store.getters.rep
    },
    isPlatformRedirection () {
      return this.$store.getters.isPlatformRedirection
    }
  },
  validations () {
   if (this.isPlatformRedirection) {
     return {
       submitterRep: {
         name: {
           required
         },
         email: {
           required,
           email
         },
         phoneNumber: {
           required,
           checkValidPhoneNumber
         }
       },
       groupValidation: ['submitterRep']
     }
   } else {
     return {
       submitterRep: {
         name: {
           required
         },
         email: {
           required,
           email
         },
         phoneNumber: {
           required,
           checkValidPhoneNumber
         }
       },
       platform: {
         required
       },
       groupValidation: ['submitterRep', 'platform']
     }
   }
  },
  mounted() {
    let app = this
    app.checkSubmitterRepInfo();
  },
  methods: {
    markRecaptchaAsVerified() {
      this.recaptchaVerified = true;
      this.pleaseTickRecaptchaMessage = ''
    },
    routeToDashboard () {
      location.href = "/";
    },
    submitVerifyForm() {
      this.$v.groupValidation.$touch();
      if (this.$v.groupValidation.$invalid) {
        if (!this.recaptchaVerified) {
          this.pleaseTickRecaptchaMessage = 'Captcha is required';
        }
      } else if (!this.recaptchaVerified) {
        this.pleaseTickRecaptchaMessage = 'Captcha is required';
      } else {
        this.$store.dispatch('groupCensusSubmitterInfo', this.submitterRep)
        let tabIndex = 2
        this.$emit('renderGroupCensusView', tabIndex)
      }
    },
    checkSubmitterRepInfo () {
      let app = this
      app.submitterRep.name = app.submitterRepInfo?.name
      app.submitterRep.email = app.submitterRepInfo?.email
      app.submitterRep.phoneNumber =  app.submitterRepInfo?.phone.replace(/[^\d]/g, "");
    },
    checkPlatformCondition () {
      if (this.platformName) {
        this.$store.dispatch('isPlatformRedirection', true)
      } else {
        this.$store.dispatch('isPlatformRedirection', false)
      }
    },
    setPlatform () {
      this.$store.dispatch('redirectedPlatformName', this.platform)
    }
  },
  watch: {
    '$route.query.platform_name': {
      handler(newValue) {
        this.platformName = newValue || '';
        this.$store.dispatch('redirectedPlatformName', this.platformName)
        this.checkPlatformCondition()
      },
      immediate: true
    }
  },
}

</script>
<style src="../../assets/css/group-census.css">
</style>
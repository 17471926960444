var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName.toLowerCase(),
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.labelFor != '' ? [_c('label', {
          attrs: {
            "id": "label-".concat(_vm.labelFor),
            "for": _vm.labelFor
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        })])] : _vm._e(), _c('b-form-input', {
          class: _vm.computedInputClass,
          attrs: {
            "id": _vm.labelFor,
            "type": _vm.inputType,
            "state": _vm.getValidationState(validationContext),
            "aria-describedby": "input-feedback",
            "readonly": _vm.readonly,
            "placeholder": _vm.computedPlaceholder
          },
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _c('b-form-invalid-feedback', {
          class: "".concat(_vm.labelFor, "-feedback"),
          attrs: {
            "id": "".concat(_vm.labelFor, "-feedback")
          }
        }, [_vm._v(" " + _vm._s(_vm.errorField ? _vm.errorField[0] : validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
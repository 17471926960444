var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.validationLabel ? _vm.validationLabel : _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', {
          attrs: {
            "id": "label-".concat(_vm.labelFor),
            "for": _vm.labelFor
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        }), _vm._t("labelMessage")], 2), _c('b-form-file', {
          attrs: {
            "id": _vm.labelFor,
            "browse-text": "Choose File",
            "state": _vm.getValidationState(validationContext),
            "readonly": _vm.readonly,
            "placeholder": _vm.computedPlaceholder,
            "accept": _vm.computedMimeTypes,
            "multiple": ""
          },
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _vm._t("uploadedFiles"), _c('div', {
          staticClass: "error-text"
        }, [_c('span', [_vm._v(_vm._s(_vm.errorField ? _vm.errorField[0] : validationContext.errors[0]))])]), _vm._t("customErrors")];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import Helper from "@/resource/Helper"
import CommonValidator from "@/resource/CommonValidator";
let errorMessage;
/*eslint-disable*/
export default {
    'custom_password' : {
        message: field => {
         return  errorMessage;
        },
        validate: value => {
          errorMessage = 'At least 6 Characters: One Uppercase Letter, One Lowercase Letter, One Number and One Special Character';
          return Helper.validateCustomPassword(value)
         
        },
      },
      'clearout_phone' : {
        message: field => {
         return  'Phone is invalid - validated using ClearoutPhone.';
        },
        validate: async (value) => {
          let check =  await CommonValidator.validatePhoneNumberCustom(value);
          return check === true;
        },
      },
      'email_verify' : {
        message: field => {
         return  'Email is invalid - validated using Neverbounce.';
        },
        validate: async (value) => {
          let check =  await CommonValidator.validateEmail(value);
          return check === true;
         
        },
      }
}
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "defaultNav"
    }
  }, [_c('div', {
    staticClass: "logo rep-reg-logo"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/nuera-logo.svg"),
      "alt": "nuera-logo"
    }
  })])])]);
}]

export { render, staticRenderFns }
import Vue from 'vue'
import App from './App.vue'
import store from "./store"
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue } from 'bootstrap-vue'

import '@/utils/filter.js'
import '@/utils/global.js'
import '@/utils/validator.js'
import '@/utils/components.js'
import Vuelidate from 'vuelidate'

import UUID from "vue-uuid";
import vuescroll from 'vuescroll';
import DatePicker from "vue2-datepicker";
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import VueToast from 'vue-toast-notification';
import VueSignaturePad from 'vue-signature-pad';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import device from "vue-device-detector"

import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import "vue2-datepicker/index.css";
import "@/assets/css/toaster.css"
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'font-awesome/css/font-awesome.css';
import '@fortawesome/fontawesome-free/css/all.css';

import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor, /* { default global options } */)

import VueConfirmDialog from 'vue-confirm-dialog';
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

// Global emit bus
const EventBus = new Vue();
export default EventBus;

/*eslint-disable*/
Vue.use(VueToast);
Vue.use(VueSignaturePad);
Vue.use(UUID)
Vue.use(CKEditor);
Vue.use(vuescroll);
Vue.use(BootstrapVue)
Vue.use(VueApexCharts)
Vue.use(device)
Vue.use(Vuelidate)
Vue.component('date-picker', DatePicker);
Vue.component('apexchart', VueApexCharts);
Vue.component('multiselect', Multiselect);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

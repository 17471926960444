<template>
  <b-modal
      id="edit-dependent"
      centered
      size="xl"
      hide-footer
  >
    <div class="title-text text-center mb-4">
      <h5><strong>Edit Dependent</strong></h5>
    </div>
    <div>
      <div class="row add-dep-inner-container">
        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title">First Name</label>
          <span class="required-asterisk">*</span>
          <input type="text"
                 v-model="dep.first_name"
                 :class="{'form-invalid': $v.dep.first_name.$error}"
                 class="input-field" placeholder="First Name" />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.first_name.required && $v.dep.first_name.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title">Last Name</label>
          <span class="required-asterisk">*</span>
          <input type="text"
                 v-model="dep.last_name"
                 :class="{'form-invalid': $v.dep.last_name.$error}"
                 class="input-field" placeholder="Last Name" />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.last_name.required && $v.dep.last_name.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title mb-2">DOB</label>
          <span class="required-asterisk">*</span>
          <date-picker
              aria-describedby="input-feedback"
              placeholder="MM/DD/YYYY"
              class="date-picker-text"
              v-model="dep.dob"
              :class="{'date-invalid': $v.dep.dob.$error}"
              value-type="format"
              format="MM/DD/YYYY"
              :disabled-date="disableDates"
          >
          </date-picker>
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.dob.required && $v.dep.dob.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title">Gender</label>
          <span class="required-asterisk">*</span>
          <select v-model="dep.gender"
                  :class="{'form-invalid': $v.dep.gender.$error}"
                  class="custom-select mt-2">
            <option value="" disabled> Select Gender</option>
            <option v-for="(gender, genderIndex) in genderList"
                    :value="gender.value"
                    :key="genderIndex">{{gender.text}}</option>
          </select>
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.gender.required && $v.dep.gender.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title">Relationship</label>
          <span class="required-asterisk">*</span>
          <select v-model="dep.relationship" disabled
                  class="custom-select mt-2 custom-select-disabled">
            <option value="" disabled> Select Relationship</option>
            <option v-for="(relationship, relIndex) in relationShipList"
                    :value="relationship.value"
                    :key="relIndex">{{relationship.text}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="text-center me-4">
        <button class="add-dependent-btn" @click="updateDependent">Update Dependent</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import Helper from "../../../resource/Helper";
export default {
  name: "EditDepInfoModel",
  props: {
    editDepIndex: {
      type: Number
    },
    editDepInfo: {
      type: Object
    },
    empIndex: {
      type: Number
    }
  },
  data() {
    return {
      relationShipList: [
        { text: 'Spouse', value: 'S' },
        { text: 'Child', value: 'C' }
      ],
      genderList: [
        { text: 'Male', value: 'Male' },
        { text: 'Female', value: 'Female' },
      ],
      dep: {
        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        relationship: ''
      }
    };
  },
  validations: {
    dep: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      dob: {
        required
      },
      gender: {
        required
      },
    },
    depInfo: ['dep']
  },
  methods: {
    updateDependent() {
      let app = this;
      app.$v.depInfo.$touch();
      if (app.$v.depInfo.$invalid) {
        return false;
      } else {
        app.submitEditedDepInfo();
      }
    },
    disableDates (date) {
      return Helper.disableFutureDates(date)
    },
    clearDepObj() {
      let app = this
      app.dep = {
        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        relationship: ''
      }
    },
    submitEditedDepInfo () {
      let app = this
      let depInfoObj = {
        'depIndex': app.editDepIndex,
        'empIndex': app.empIndex,
        'editDepInfo': app.dep
      }
      app.$emit('editDepInfo', depInfoObj)
      app.$notify('Successfully Edited Dependent Info')
      app.$bvModal.hide('edit-dependent')
      app.$v.$reset();
    }
  },
  watch: {
    editDepInfo: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dep = {
            first_name: newVal.first_name,
            last_name: newVal.last_name,
            dob: newVal.dob,
            gender: newVal.gender,
            relationship: newVal.relationship,
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
<template>
  <div>
    <div>
      <registration-auth-header />
    </div>
    <div class="group-page-wrapper">
      <div class="container-fluid p-0">
        <div class="top-title">
          <div class="page-title">
            <h1 class="group-census-label">Group Quote</h1>
          </div>
          <div class="d-flex justify-content-end">
            <button type="submit"
                    @click="renderUploadExcel"
                    class="group-univ-btn text-center mt-0">
              Import Excel File
            </button>
            <button type="submit"
                    @click="routeToDashboard"
                    class="group-univ-btn text-center mt-0">Dashboard
            </button>
          </div>
          <div>
            <group-info-field @routeToVerification="routeToVerification"
                              :groupQuoteExcel="groupQuoteExcel"/>
          </div>
          <div>
           <upload-excel-file-modal @getGroupQuoteExcelList="getGroupQuoteExcelList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupInfoField from "@/views/GroupCensus/partials/GroupInfoField";
import RegistrationAuthHeader from "../../components/RegistrationAuthHeader";
import UploadExcelFileModal from "./modal/UploadExcelFileModal";
export default {
  name: "GroupCensusView",
  components: {
    'group-info-field': GroupInfoField,
    RegistrationAuthHeader,
    UploadExcelFileModal
  },
  data () {
    return {
     groupQuoteExcel: {}
    }
  },
  methods: {
    routeToDashboard () {
      location.href = "/";
    },
    routeToVerification (tab) {
      let app = this
      app.$emit('renderVerifyCaptcha', tab)
    },
    renderUploadExcel () {
      let app = this
      app.$bvModal.show('upload-excel')
    },
    getGroupQuoteExcelList (groupListData) {
      let app = this
      app.groupQuoteExcel = {}
      app.groupQuoteExcel = groupListData
    }
  }
}
</script>

<template>
  <div class="page-record" v-if="from && to && total">
    <span>Showing {{ from }} to {{ to }} of {{ total }} results</span>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      required: true,
    },
  },
  name: "PageResult",
  data: () => ({
    items: [],
  }),
  computed: {
    from() {
      return this.items.record_range ? this.items.record_range[0] : "";
    },
    to() {
      return this.items.record_range ? this.items.record_range[1] : "";
    },
    total() {
      return this.items.total_records ? this.items.total_records : "";
    },
  },
  watch: {
    rows: function (items) {
      if (items) {
        this.items = items;
      }
    },
  },
};
</script>

<style scoped>
.page-record {
  font-size: 14px;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "password_info"
  }, [_vm._v("At least 6 Characters: One Uppercase Letter, One Lowercase Letter, One Number and One Special Character")]), _c('div', {
    staticClass: "indicator"
  }, [_c('span', {
    staticStyle: {
      "display": "none"
    }
  }, [_vm._v(_vm._s(_vm.password))]), _c('span', {
    staticClass: "very weak",
    class: [_vm.passwordScore >= 1 && _vm.passwordScore <= 64 ? 'red-text' : _vm.passwordScore >= 65 ? 'green-text' : '']
  }), _c('span', {
    staticClass: "weak",
    class: [_vm.passwordScore >= 20 && _vm.passwordScore <= 64 ? 'red-text' : _vm.passwordScore >= 65 ? 'green-text' : '']
  }), _c('span', {
    staticClass: "medium",
    class: [_vm.passwordScore >= 40 && _vm.passwordScore <= 64 ? 'red-text' : _vm.passwordScore >= 65 ? 'green-text' : '']
  }), _c('span', {
    staticClass: "strong",
    class: [_vm.passwordScore >= 65 ? 'green-text' : '']
  }), _c('span', {
    staticClass: "very strong",
    class: [_vm.passwordScore >= 80 ? 'green-text' : '']
  })]), _c('p', {
    staticClass: "strength_message",
    class: [_vm.passwordScore >= 65 ? 'strong_password' : 'weak_password']
  }, [_vm._v(_vm._s(_vm.scoreStrengthText))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    class: _vm.className,
    attrs: {
      "to": _vm.routeLink,
      "title": _vm.title
    }
  }, [_c('i', {
    class: _vm.iconClass
  }), _vm._v(" " + _vm._s(_vm.haveText ? _vm.title : "") + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.employeeInfoList.length > 0 ? _c('div', {
    staticClass: "edit-group-container"
  }, [_vm.totalAddedInfo < 10 ? _c('div', {
    staticClass: "invalid-error-msg mb-2"
  }, [_vm._v(" At least 10 information is required as a total including employee and dependent inorder to proceed. ")]) : _c('div', {
    staticClass: "valid-msg"
  }, [_vm._v(" Total added information is " + _vm._s(_vm.totalAddedInfo) + " which is valid you can proceed inorder to submit information. ")]), _c('div', {
    staticClass: "text-start emp-dep-count-container"
  }, [_c('span', {
    staticClass: "m-3"
  }, [_vm._v("Total Added Employee: " + _vm._s(_vm.totalEmployees))]), _c('span', {
    staticClass: "m-3"
  }, [_vm._v("Total Added Dependents: " + _vm._s(_vm.totalDependents))]), _c('span', {
    staticClass: "m-3"
  }, [_vm._v("Total Added (Employee + Dependents): " + _vm._s(_vm.totalAddedInfo))])]), _c('div', {
    staticClass: "d-flex justify-content-end"
  }), _c('div', {
    staticClass: "row g-0"
  }, _vm._l(_vm.employeeInfoList, function (employee, empIndex) {
    return _c('div', {
      key: empIndex,
      staticClass: "col-xl-4"
    }, [_c('div', {
      staticClass: "edit-member-container"
    }, [_c('div', {
      staticClass: "employee-container"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', [_c('span', {}, [_vm._v("Employee " + _vm._s(empIndex + 1) + ": " + _vm._s(employee.first_name) + " " + _vm._s(employee.last_name))])]), _c('div', {
      staticClass: "mt-1 text-end"
    }, [_vm.checkEmployeeAddBtn(employee) ? _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "fas fa-users fa-icon-edit text-capitalize add-employee-icon fa-icon-container",
      attrs: {
        "title": "Add/Edit Dependent"
      },
      on: {
        "click": function click($event) {
          return _vm.openModal(empIndex);
        }
      }
    }) : _vm._e(), _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "fas fa-edit mr-1 fa-icon-container fa-icon-edit text-capitalize",
      attrs: {
        "title": "Edit Employee ".concat(empIndex + 1, ": ").concat(employee.first_name, " ").concat(employee.last_name)
      },
      on: {
        "click": function click($event) {
          return _vm.editEmployeeInfo(empIndex);
        }
      }
    }), _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "fas fa-trash-alt mr-1 fa-icon-container text-capitalize trash-icon-btn",
      attrs: {
        "title": "Delete Employee ".concat(empIndex + 1, ": ").concat(employee.first_name, " ").concat(employee.last_name)
      },
      on: {
        "click": function click($event) {
          return _vm.deleteEmployeeInfo(empIndex, employee);
        }
      }
    })])]), _c('div', {
      staticClass: "d-flex justify-content-start plan-type-info"
    }, [_vm._v(" Plan Type: " + _vm._s(_vm.checkPlanTypeInfo(employee.plan_type)) + " ")])]), employee.dependent_info && employee.dependent_info.length > 0 ? _c('div', [_c('div', {
      staticClass: "dep-info-overlay"
    }, _vm._l(employee.dependent_info, function (dep, depIndex) {
      return _c('div', {
        key: depIndex,
        staticClass: "d-flex justify-content-between align-items-center dep-info-container employee-container"
      }, [_c('div', [_c('span', {}, [_vm._v("Dependent " + _vm._s(depIndex + 1) + ": " + _vm._s(dep.first_name) + " " + _vm._s(dep.last_name) + " "), dep.relationship == 'S' ? _c('span', {
        staticClass: "edit-dep-relation"
      }, [_vm._v("(Spouse)")]) : dep.relationship == 'C' ? _c('span', {
        staticClass: "edit-dep-relation"
      }, [_vm._v("(Child)")]) : _vm._e()])]), _c('div', {
        staticClass: "mt-1 text-end"
      }, [_c('i', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.top",
          modifiers: {
            "hover": true,
            "top": true
          }
        }],
        staticClass: "fas fa-edit mr-1 fa-icon-container fa-icon-edit text-capitalize",
        attrs: {
          "title": "Edit Dependent:".concat(depIndex + 1)
        },
        on: {
          "click": function click($event) {
            return _vm.editDependent(dep, depIndex, empIndex);
          }
        }
      }), _c('i', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.top",
          modifiers: {
            "hover": true,
            "top": true
          }
        }],
        staticClass: "fas fa-trash-alt mr-1 fa-icon-container text-capitalize trash-icon-btn",
        attrs: {
          "title": "Delete Dependent: ".concat(depIndex + 1)
        },
        on: {
          "click": function click($event) {
            return _vm.deleteDependent(empIndex, depIndex, employee);
          }
        }
      })])]);
    }), 0)]) : _vm._e(), employee.plan_type !== 'IO' && _vm.validateDepInfo(employee) ? _c('div', {
      staticClass: "employee-container"
    }, [_c('div', {
      class: 'dep-valid-alert-msg'
    }, [_vm._v(" " + _vm._s(_vm.validateDepInfo(employee)))])]) : _vm._e(), employee.plan == null || employee.plan == '' ? _c('div', [_c('div', {
      class: 'dep-valid-alert-msg'
    }, [_vm._v("The Selected group plan has been removed. Please choose a different available plan to edit employee information.")])]) : _vm._e()])]);
  }), 0), _c('div', [_c('add-dependent', {
    attrs: {
      "show": _vm.showModal,
      "index": _vm.employeeIndex,
      "employeeInfoList": _vm.employeeInfoList
    },
    on: {
      "addDependentInfo": _vm.addDependentInfo,
      "checkAddDepBtn": _vm.checkAddDepBtn,
      "closeModal": _vm.closeModal
    }
  })], 1), _c('div', [_c('edit-dep-info-modal', {
    attrs: {
      "editDepIndex": _vm.editDependentIndex,
      "empIndex": _vm.employeeIndex,
      "editDepInfo": _vm.editDependentInfo
    },
    on: {
      "editDepInfo": _vm.editDepInfo
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-form-group :class="formGroupClass">
    <div class="upload_section">
      <validation-provider
        :name="labelName"
        :rules="rules"
        v-slot="validationContext"
      >
        <label :id="`label-${labelFor}`" :for="labelFor">
          <span v-html="computedLabel" />
        </label>
        <b-form-file
          :ref="labelFor"
          id="upload_image"
          browse-text="Choose File"
          accept="image/*"
          :state="getValidationState(validationContext)"
          @change="fileHandler"
          v-model="computedInputData"
          :readonly="readonly"
          :placeholder="computedPlaceholder"
        >
        </b-form-file>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
        <div v-if="showApiError">
          <span class="error-text">{{ apiErrors[fieldName][0] }}</span>
        </div>
      </validation-provider>
    </div>
    <div class="uploaded" :class="formGroupClass">
      <img :src="imageSrc" :class="imgClass" />

      <img
        v-if="imageSrc.includes('https') && deleteIcon"
        src="../../assets/images/close-icon.svg"
        class="upload-close-icon"
        @click="deleteImage"
      />
    </div>
  </b-form-group>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import dummyImgSrc from "@/assets/images/download.png";
export default {
  props: {
    imgSrc: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: false,
    },
    vmodel: {
      required: true,
      default: null,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    imgClass: {
      required: false,
      default: "input-image",
    },
    apiErrors: {
      //object container for server side validation error Message
      required: false,
      default:null
    },
    fieldName: {
      //to be used along with apiErrors ,server side key for error field
      required: false,
      default:null
    },
    deleteIcon:{
      type:Boolean,
      required:false,
      default:true
    }
  },
  components: {
    ValidationProvider,
  },
  data: () => {
    return {
      imageSrc: "",
      file: null,
      dummyImgSrc,
    };
  },
  computed: {
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `No File Choosen`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function () {
        return this.vmodel;
      },
      set: function () {
        this.$emit("update:vmodel", this.file ? this.file : null);
      },
    },
    showApiError(){
      if(this.apiErrors != null  && this.fieldName != null){
        return true
      }
      return false
    }
  },
  methods: {
    reset() {
      this.file = null;
       this.$emit("update:vmodel", this.file ? this.file : null);
      this.imageSrc = URL.createObjectURL(this.file);
    },
    removeImage() {
      this.imageSrc = this.imgSrc ? this.imgSrc : dummyImgSrc;
      this.file = null;
      this.vmodel = null;
    },
    deleteImage() {
        this.$emit("deleteImage");
    },
    fileHandler(e) {
      const file = e.target.files[0];
      this.file = file;
      this.imageSrc = URL.createObjectURL(file);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  watch: {
    imgSrc: function (value) {
      if (value) {
        this.imageSrc = value;
      } else {
        this.imageSrc = dummyImgSrc;
      }
    },
  },
};
</script>
<style>
.input-required {
  color: red !important;
}
.input-image {
  height: 150px;
  width: auto;
}

.upload-close-icon {
 cursor: pointer;
  max-width: 24px!important;
  max-height: 24px!important;
  position: absolute;
  cursor: pointer;
  margin-left: -14px;
  
}
.error-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>

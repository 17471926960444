var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "site-header"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "site-logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../assets/images/light-nuera.png"),
      "alt": "Corenroll"
    }
  })])], 1), _c('div', {
    staticClass: "head-title"
  }, [_vm._v("Broker-Rep Back Office")])])]), _c('div', {
    staticClass: "col-xxl-9 col-xl-9 col-lg-8 v-headnav"
  }, [_c('div', {
    staticClass: "header-nav"
  }, [_c('ul', [_c('li', {
    staticClass: "header-event"
  }), _c('li', {
    staticClass: "header-date"
  }, [_vm._v(" " + _vm._s(_vm.current_date) + " ")]), _c('li', {
    staticClass: "header-login"
  }, [_c('span', {
    staticClass: "headerTitle"
  }, [_vm._v("Last Login:")]), _vm._v(" " + _vm._s(_vm.lastLogin) + " ")]), _c('li', [_c('b-dropdown', {
    staticClass: "header-user",
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "header-image-div"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": _vm.headerImage,
            "alt": "User Picture"
          }
        })]), _c('span', {
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(_vm.rep.name) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "to": "/change-password"
    }
  }, [_vm._v("Change Password")]), _c('b-dropdown-item', {
    attrs: {
      "to": "/account-information"
    }
  }, [_vm._v("Account Information")]), _c('b-dropdown-item', [_c('router-link', {
    staticStyle: {
      "color": "black"
    },
    attrs: {
      "to": {
        name: 'Settings',
        params: {
          tab: 'display'
        }
      }
    }
  }, [_vm._v(" Display Setting ")])], 1), _c('b-dropdown-item', [_c('router-link', {
    staticStyle: {
      "color": "black"
    },
    attrs: {
      "to": {
        name: 'Settings',
        params: {
          tab: 'display'
        }
      }
    }
  }, [_vm._v(" Personalize Account ")])], 1), _c('b-dropdown-item', [_c('router-link', {
    staticStyle: {
      "color": "black"
    },
    attrs: {
      "to": {
        name: 'DefaultOtp'
      }
    }
  }, [_vm._v(" Default Otp ")])], 1), _c('b-dropdown-item', {
    attrs: {
      "to": "/select-theme"
    }
  }, [_vm._v("Rep Registration Theme")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_c('router-link', {
    staticStyle: {
      "color": "black"
    },
    attrs: {
      "to": {
        name: 'RepAdminList'
      }
    }
  }, [_vm._v(" Rep Administrators ")])], 1), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('rep-downline-notification');
      }
    }
  }, [_vm._v(" Downline Notification ")])], 1)], 1)])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="footerPage-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
          <div class="foot-logo">
            <router-link to="/">
              <img
                src="@/assets/images/corenroll-logo-pb.png"
                alt="Corenroll"
                class="img-fluid"
              />
            </router-link>
          </div>
          <p class="technical-text">
            If you have some technical difficulties Go to:
            <a href="http://tickets.purenroll.com/"   target="_blank">Issues Dashboard</a>
          </p>
          <p class="copyright-p">&copy; Copyright {{this.getCurrentYear()}}, All Rights Reserved</p>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
          <div class="app-details">
            <h4>Download The Rep app</h4>
            <div class="app-icon">
              <a
                href="https://apps.apple.com/us/app/corenroll-reps/id1527267392"
                target="_blank"
              >
                <img
                  src="@/assets/images/ios_icon.png"
                  alt="App Store Icon"
                  class="img-fluid"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.neura.corenroll"
                target="_blank"
              >
                <img
                  src="@/assets/images/google_icon.png"
                  alt="Play Store Icon"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SiteFooter",
  methods: {
    getCurrentYear () {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      return currentYear
    }
  }
};
</script>
<style src="@/assets/css/common.css"></style>

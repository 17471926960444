<template>
  <header class="site-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xxl-3 col-xl-3 col-lg-4">
          <div class="d-flex align-items-center justify-content-between">
            <div class="site-logo">
              <router-link to="/">
                <img
                  src="../assets/images/light-nuera.png"
                  alt="Corenroll"
                  class="img-fluid"
                />
              </router-link>
            </div>
            <div class="head-title">Broker-Rep Back Office</div>
          </div>
        </div>
        <div class="col-xxl-9 col-xl-9 col-lg-8 v-headnav">
          <div class="header-nav">
            <ul>
              <li class="header-event">
                <!-- <b-dropdown class="header-eventTab">
                  <template #button-content class="user-dropdown">
                    <span class="headerCalendar-icon"
                      ><img
                        src="../assets/images/calendar.svg"
                        alt="Calendar"
                        class="img-fluid"
                    /></span>
                    <span class="name">Today's Events</span>
                    <span class="eventBadge">12</span>
                  </template>

                  <b-card no-body>
                    <b-tabs card>
                      <vue-scroll :ops="eventScroll">
                        <b-tab title="All Events" active>
                          <ul>
                            <li>Event List 1</li>
                            <li>Event List 2</li>
                            <li>Event List 3</li>
                            <li>Event List 4</li>
                            <li>Event List 5</li>
                            <li>Event List 6</li>
                            <li>Event List 7</li>
                          </ul>
                        </b-tab>
                        <b-tab title="Birthday">
                          <ul>
                            <li>Birthday Event List 1</li>
                            <li>Birthday Event List 2</li>
                            <li>Birthday Event List 3</li>
                            <li>Birthday Event List 4</li>
                            <li>Birthday Event List 5</li>
                            <li>Birthday Event List 6</li>
                            <li>Birthday Event List 7</li>
                          </ul>
                        </b-tab>
                      </vue-scroll>
                    </b-tabs>
                  </b-card>
                </b-dropdown> -->
                <!-- <span class="headerCalendar-icon"><img src="../assets/images/calendar.svg" alt="Calendar"></span>
                                Today's Events
                                <span class="eventBadge">12</span> -->
              </li>
              <li class="header-date">
                <!-- <span class="headerTitle">Date:</span> -->
                {{ current_date }}
              </li>
              <li class="header-login">
                <span class="headerTitle">Last Login:</span>
                {{ lastLogin }}
              </li>
              <!-- <li>
                <router-link to="" class="navAction-link">
                  <div class="navAction-icon">
                    <img src="../assets/images/bell.svg" alt="" />
                    <span class="navAction-badge">2</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link to="" class="navAction-link">
                  <div class="navAction-icon">
                    <img src="../assets/images/message.svg" alt="" />
                    <span class="navAction-badge">2</span>
                  </div>
                </router-link>
              </li> -->
              <li>
                <b-dropdown class="header-user">
                  <template #button-content class="user-dropdown">
                    <div class="header-image-div">
                      <img
                        :src="headerImage"
                        alt="User Picture"
                        class="img-fluid"
                      />
                    </div>
                    <span class="name">
                      {{ rep.name }}
                    </span>
                  </template>
                  <b-dropdown-item to="/change-password"
                    >Change Password</b-dropdown-item
                  >
                  <b-dropdown-item to="/account-information"
                    >Account Information</b-dropdown-item
                  >
                  <b-dropdown-item>
                    <router-link
                      :to="{
                        name: 'Settings',
                        params: { tab: 'display' },
                      }"
                      style="color: black"
                    >
                      Display Setting
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <router-link
                      :to="{
                        name: 'Settings',
                        params: { tab: 'display' },
                      }"
                      style="color: black"
                    >
                      Personalize Account
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <router-link
                      :to="{
                        name: 'DefaultOtp',
                      }"
                      style="color: black"
                    >
                      Default Otp
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item to="/select-theme"
                    >Rep Registration Theme</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <!-- <b-dropdown-item to="/alt-email"
                    >Alternate Email Address</b-dropdown-item
                  > -->
                  <b-dropdown-item>
                    <router-link
                      :to="{
                        name: 'RepAdminList',
                      }"
                      style="color: black"
                    >
                      Rep Administrators
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item @click="$bvModal.show('rep-downline-notification')">
                    Downline Notification
                  </b-dropdown-item>
                  
                </b-dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      eventScroll: {
        vuescroll: {
          mode: "native",
          wheelScrollDuration: "700",
        },
        scrollPanel: {
          maxHeight: 200,
        },
        bar: {
          background: "#ddd",
        },
      },
      current_date: moment().format("MMMM DD, YYYY"),
    };
  },
  computed: {
    ...mapGetters({ rep: "rep", lastLogin: "lastLogin" }),
    headerImage() {
      return this.rep.image
        ? this.rep.image
        : require("@/assets/images/pic_one.png");
    },
  },
};
</script>
<style src="@/assets/css/common.css"></style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper downlinerep-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_vm._t("breadcrumb"), _vm._t("pageItem"), _c('div', {
    staticClass: "page-title"
  }, [_vm._t("pageTitle")], 2), this.$slots.filter ? _c('div', {
    staticClass: "page-search-card"
  }, [_vm._t("filter")], 2) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "page-card downlinedata-card"
  }, [_vm._t("topPagination"), _vm._t("table")], 2)])])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.tabIndex == 1 ? _c('div', [_c('verify-captcha', {
    on: {
      "renderGroupCensusView": _vm.renderGroupTab
    }
  })], 1) : _vm.tabIndex == 2 ? _c('div', [_c('group-census-view', {
    on: {
      "renderVerifyCaptcha": _vm.renderGroupTab
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative",
    class: _vm.formGroupClass,
    attrs: {
      "id": "app"
    }
  }, [_c('file-pond', {
    ref: _vm.ref,
    class: _vm.inputClass,
    attrs: {
      "label-idle": "Drop files here or <span class='filepond--label-action'>Browse</span>",
      "allow-multiple": _vm.allowedMultiple,
      "accepted-file-types": _vm.computedMimeTypes,
      "files": _vm.inputFile,
      "credits": "false",
      "max-file-size": "10MB",
      "label-file-type-not-allowed": "File of invalid type",
      "file-validate-type-label-expected-types": "Image or document only allowed",
      "image-preview-transparency-indicator": "grid",
      "max-parallel-uploads": _vm.maxParallelUpload
    },
    on: {
      "updatefiles": _vm.handleFile
    }
  }), _vm.showInfo ? _c('span', {
    staticClass: "d-flex justify-content-center",
    staticStyle: {
      "position": "absolute",
      "left": "50%",
      "top": "40%"
    },
    attrs: {
      "tabindex": "0",
      "data-toggle": "tooltip",
      "title": _vm.computedMimeTypeMessage
    }
  }, [_c('img', {
    staticClass: "mt-3 play-icon",
    attrs: {
      "src": require("../../assets/images/info-circle.svg")
    }
  })]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal
      id="upload-excel"
      centered
      size="lg"
      @show="showExcelModal"
      hide-footer
  >
    <div class="title-text text-center mb-3">
      <h5><strong>Import Group Quote Excel</strong></h5>
    </div>
    <div class="text-end mb-2"><span class="download-template" @click="downloadSampleTemplate">Download Template</span></div>
    <div class="mt-4">
      <div class="file-upload-container" @click="triggerFileInput">
        <i class="fas fa-file-excel"></i>
        <span>Click to select file .xls, .xlsx spreadsheets accepted.</span>
        <input
            type="file"
            ref="fileInput"
            @change="handleFileChange"
            style="display: none"
            accept=".xlsx, .xls"
        />
      </div>
      <div v-if="errorExcelError && !file" class="form-error error-font-size mt-2 pl-2">Excel file is required inorder to proceed.</div>
      <div v-if="fileName" class="mt-4">
        <div class="mb-3 excel-file-title">Imported Excel File:</div>
        <span class="file-view-template ">
          {{fileName}}
        <i class="fa fa-trash excel-trash-icon"
           @click="deleteExcelFile(fileName)"
           aria-hidden="true"></i>
        </span>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button type="submit"
                @click="closeModal"
                class="group-univ-btn text-center mt-0">
          Cancel
        </button>
        <button type="submit"
                @click="uploadFile"
                class="group-univ-btn text-center mt-0">
          Submit
        </button>
      </div>
    </div>
  </b-modal>
</template>


<script>
import AuthLessApi from "../../../resource/AuthLessApi";

export default {
  data() {
    return {
      file: null,
      fileName: '',
      errorExcelError: ''
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    showExcelModal () {
      let app = this
      app.file = null
      app.fileName = ''
      app.errorExcelError = ''
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.file = selectedFile;
        this.fileName = selectedFile.name;
      }
    },
    closeModal () {
      let app = this
      app.$bvModal.hide('upload-excel')
    },
    deleteExcelFile (fileName) {
      let app = this
      app.$confirm({
        title: "Confirm",
        message: `Are you sure to delete ${fileName} ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            app.file = null;
            app.fileName = '';
            app.errorExcelError = '';
            app.$refs.fileInput.value = '';
          }
        }
      })
    },
    uploadFile() {
      let app = this
      if (this.file) {
        const formData = new FormData();
        formData.append('file', app.file);
        app.errorExcelError = ''
        const url = `v2/group-census/upload-file`
        let loader = app.$loading.show();
        AuthLessApi.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          let groupQuoteList = {}
          groupQuoteList = response.data.data
          app.$emit('getGroupQuoteExcelList', groupQuoteList)
          app.$notify(response.data.message);
          app.$bvModal.hide('upload-excel')
        }).catch((err) => {
          app.$notify(err.response.data.message, "error");
        }).finally( () => {
          loader.hide();
       })
      } else {
        app.errorExcelError = 'Excel File is required inorder to proceed.'
      }
    },
    downloadSampleTemplate () {
      let fileUrl = 'https://uploaded-files-dashboard.s3.us-east-2.amazonaws.com/group-census/GroupCensusSampleData.xlsx'
      window.open(fileUrl, "_blank");
    }
  }
};
</script>
<style></style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.showLabel ? _c('label', {
          attrs: {
            "for": _vm.labelFor ? _vm.labelFor : ''
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        })]) : _vm._e(), _c('multiselect', {
          class: _vm.selectClassName(validationContext),
          attrs: {
            "options": _vm.options,
            "custom-label": _vm.customLabel,
            "placeholder": _vm.placeholder,
            "disabled": _vm.isDisable,
            "internal-search": _vm.internalSearch,
            "clear-on-select": _vm.clearOnSelect,
            "close-on-select": _vm.closeOnSelect,
            "loading": _vm.isLoading
          },
          on: {
            "update:customLabel": function updateCustomLabel($event) {
              _vm.customLabel = $event;
            },
            "update:custom-label": function updateCustomLabel($event) {
              _vm.customLabel = $event;
            },
            "input": _vm.inputHandler,
            "search-change": _vm.searchChangeHandler
          },
          nativeOn: {
            "focus": function focus($event) {
              return _vm.autoCompleteHandler.apply(null, arguments);
            }
          },
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.getValidationState(validationContext)
          }
        }, [_vm._v(" " + _vm._s(_vm.apiErrors ? _vm.errorField[0] : validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span :class="className" @click="toggleIcon">
    <span :id="'eyeIconSlash_' + this.id" style="display: none">
      <i class="far fa-eye-slash"></i>
    </span>
    <span :id="'eyeIcon_' + this.id"> <i class="far fa-eye"></i> </span>
  </span>
</template>

<script>
/* eslint-disable */
export default {
  name: "EyeButton",
  props: {
    id: {
      required: true,
    },
    hideOnDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: true,
      default:"text"
    },
    className: {
      type: String,
      required: false,
    },
    nonInput: {
      type: Number,
      required: false,
      default:0
    },
    value: {
      type: String,
      required: false,
    },
    initialValue: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      eyeButton: true,
      currentType: "",
    };
  },
  mounted() {
    if (this.hideOnDefault) {
      this.toggleIcon();
    }
  },
  methods: {
    toggleIcon() {
      this.eyeButton = !this.eyeButton;
      let value = this.initialValue
      if (!this.eyeButton) {
        document.getElementById("eyeIcon_" + this.id).style.display = "none";
        document.getElementById("eyeIconSlash_" + this.id).style.display =
          "inline-block";

        this.currentType = this.type;
        if(this.nonInput) {
          value  = this.initialValue
        }
      } else {
        document.getElementById("eyeIcon_" + this.id).style.display = "inline-block";
        document.getElementById("eyeIconSlash_" + this.id).style.display =
          "none";
        this.currentType = "text";
        if(this.nonInput) {
          value  = this.value
        }
      }
      if (document.getElementById(this.id)) {
        if(this.nonInput) {
          document.getElementById(this.id).innerHTML  = value
        }
        document.getElementById(this.id).type = this.currentType;
      }
    },
  },
};
</script>
<style scoped>
</style>

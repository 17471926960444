
const state = {
    memberListFilter: [],
}
const getters = {
    memberListFilter: state => state.memberListFilter,
}

const actions = {}

const mutations = {
    setMemberListFilter(state, filter) {
        state.memberListFilter = filter;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
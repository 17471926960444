<template>
  <div>
    <div v-if="tabIndex == 1">
      <verify-captcha @renderGroupCensusView="renderGroupTab"/>
    </div>
    <div v-else-if="tabIndex == 2">
      <group-census-view @renderVerifyCaptcha="renderGroupTab"/>
    </div>
  </div>
</template>

<script>
import GroupCensusView from "./GroupCensusView";
import VerifyCaptcha from "./VerifyCaptcha";
export default {
  name: "GroupCensusTab",
  components: {GroupCensusView, VerifyCaptcha},
  component: {
    GroupCensusView,
    VerifyCaptcha
  },
  data () {
    return  {
      tabIndex: 1
    }
  },
  methods: {
    renderGroupTab (tab) {
      let app = this
      app.tabIndex = tab
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/group-census.css";
</style>
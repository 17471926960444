var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group mb-3"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        })]), _c('multiselect', {
          class: _vm.selectClassName(validationContext),
          attrs: {
            "track-by": _vm.trackBy,
            "placeholder": _vm.computedPlaceholder,
            "options": _vm.options,
            "multiple": true,
            "searchable": true,
            "internal-search": false,
            "clear-on-select": false,
            "close-on-select": false,
            "preserve-search": true,
            "options-limit": _vm.optionsLimit,
            "show-no-results": false,
            "hide-selected": false,
            "label": _vm.displayLabel,
            "loading": _vm.isLoading
          },
          on: {
            "search-change": _vm.searchHandler,
            "input": _vm.toggleHandler
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref) {
              var values = _ref.values;
              return [values.length ? _c('span', {
                staticClass: "multiselect__single"
              }, [_vm._v(" " + _vm._s(values.includes("All") ? "All " : values.length) + " Options Selected ")]) : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _c('b-form-invalid-feedback', {
          attrs: {
            "id": "input-feedback "
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
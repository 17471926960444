import Vue from "vue"
import Vuex from "vuex"
import Dashboard from "./modules/dashboard"
import Auth from "./modules/auth"
import PrimaryInfo from "./modules/RepEdit24Hrs/primaryInfo"
import Filters from "./modules/filters"
import createPersistedState from "vuex-persistedstate";
import axios from "axios"
import router from "@/router/index"
import Registration from "./modules/registration"
import * as Cookies from "js-cookie";
import App from "./modules/app"
import Helper from "@/resource/Helper"
Vue.use(Vuex)
let expiryHour = val =>new Date(new Date().getTime() + val * 3600 * 1000);
const vuexStore = new Vuex.Store({
    plugins: [
        createPersistedState({
        paths: ["Auth","Dashboard","PrimaryInfo","Filters", "Registration"],
        }),
        /**
         * will be reset after 12 hours
         */
        createPersistedState({
            paths: ["Registration"],
            key:"registration",
            storage: {
                getItem: (key) => Cookies.get(key),
                setItem: (key, value) =>
                    Cookies.set(key, value, { expires: expiryHour(1), secure: false }),
                removeItem: (key) => Cookies.remove(key),
            }
        }),
        createPersistedState({
            paths: ["App"],
            key:"app",
            storage: {
                getItem: (key) => Cookies.get(key),
                setItem: (key, value) =>
                    Cookies.set(key, value, { expires: expiryHour(1), secure: false }),
                removeItem: (key) => Cookies.remove(key),
            }
        })
    ],
    modules: {
        Auth,
        Filters,
        Dashboard,
        PrimaryInfo,
        Registration,
        App
    },
    state: {
        accountInfoType: '',
        addressInfoStatus: false
    },
    mutations: {
        setCurrentAccountInfoType(state, payload) {
            state.accountInfoType = payload;
        },
        setAddressInfoStatus(state, payload) {
            state.addressInfoStatus = payload;
        }
    },
    getters: {
        getCurrentAccountInfoType(state) {
            return state.accountInfoType;
        },
        getAddressListStatus(state) {
            return state.addressInfoStatus;
        }
    }
})
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use((config) => {
    config.headers['Authorization'] = config.headers['Authorization'] ?? (vuexStore.getters.accessToken != "" ? "Bearer " + vuexStore.getters.accessToken : "");
    return config;
});

axios.interceptors.response.use(response => response, function (error) {

    if (error?.response?.data?.message == 'Unauthorized' || error?.response?.status == 418) {
        Helper.clearToken()
        router.push({ name: "Login" });
    }
    return Promise.reject(error);
});

export default vuexStore;

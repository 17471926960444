import axios from "axios";
import Helper from "@/resource/Helper"
const state = {
    authenticated: false,
    accessToken: "",
    OTPCode: "",
    profilePic: "",
    deviceId: "",
    repEmail: "",
    rep: "",
    repId: "",
    rootUser: false,
    lastLogin: "",
    vipUser: false,
    fromMobileApp: false,
    upLineInfo: {},
    viewMessageUserInfo: {},
    isViewPlatformList: false,
    groupCensusSubmitterInfo: {},
    redirectedPlatformName: '',
    isPlatformRedirection: false
}

const getters = {
    deviceId: state => state.deviceId,
    repEmail: state => state.repEmail,
    authenticated: state => state.authenticated,
    accessToken: state => state.accessToken,
    OTPCode: state => state.OTPCode,
    rep: state => state.rep,
    repId: state => state.repId,
    rootUser: state => state.rootUser,
    lastLogin: state => state.lastLogin,
    vipUser: state => state.vipUser,
    fromMobileApp: state => state.fromMobileApp,
    upLineInfo: state => state.upLineInfo,
    viewMessageUserInfo: state => state.viewMessageUserInfo,
    isViewPlatformList:  state => state.isViewPlatformList,
    groupCensusSubmitterInfo: state => state.groupCensusSubmitterInfo,
    redirectedPlatformName: state => state.redirectedPlatformName,
    isPlatformRedirection: state => state.isPlatformRedirection
}

const mutations = {
    setAuthentication(state, status) {
        state.authenticated = status;
    },
    setRepEmail(state, email) {
        state.repEmail = email;
    },
    setAccessToken(state, token) {
        state.accessToken = token;
        axios.defaults.headers["Authorization"] =
            token != "" ? "Bearer " + token : "";
    },
    setRepId(state, repId) {
        state.repId = repId;
    },
    setOtpCode(state, otpCode) {
        state.OTPCode = otpCode;
    },
    setProfilePic(state, profilePic) {
        state.profilePic = profilePic;
    },
    setDeviceId(state, deviceId) {
        state.deviceId = deviceId;
    },
    setRepData(state, data) {
        state.rep = data;
    },
    setRootUser(state, data) {
        state.rootUser = data
    },
    setLastLogin(state, loginData) {
        state.lastLogin = loginData;
    },
    setIsVip(state, data) {
        state.vipUser = data
    },
    setFromMobileApp(state, data) {
        state.fromMobileApp = data
    },
    setUpLineInfo(state, data) {
      state.upLineInfo = data
    },
    setViewMessageUserInfo(state, data) {
      state.viewMessageUserInfo = data
    },
    setPlatFormStatus(state, data) {
      state.isViewPlatformList = data;
   },
    setGroupCensusSubmitterInfo(state, data) {
        state.groupCensusSubmitterInfo = data
    },
    setPlatformName(state, data) {
        state.redirectedPlatformName = data
    },
    setPlatformRedirection(state, data) {
        state.isPlatformRedirection = data
    }
}

const actions = {
    async getRepInfo({ commit }) {
        await axios.get('/v2/get-rep-info')
            .then((res) => {
                const data = res.data.data
                 Helper.setRepData(data)
                 Helper.checkPlatformStatus(data)
            })
            .catch(() => {
                commit('setRepData', null);
            })
    },
    groupCensusSubmitterInfo: ({commit}, payload) => {
        commit('setGroupCensusSubmitterInfo', payload)
    },
    redirectedPlatformName: ({commit}, payload) => {
        commit('setPlatformName', payload)
    },
    isPlatformRedirection: ({commit}, payload) => {
        commit('setPlatformRedirection', payload)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

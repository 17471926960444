var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.from && _vm.to && _vm.total ? _c('div', {
    staticClass: "page-record"
  }, [_c('span', [_vm._v("Showing " + _vm._s(_vm.from) + " to " + _vm._s(_vm.to) + " of " + _vm._s(_vm.total) + " results")])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
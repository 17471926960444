<template>
  <b-button variant="close-btn" @click="closeModal">
    <i class="fas fa-times" />
  </b-button>
</template>
<script>
export default {
  name: "CloseModalIcon",
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

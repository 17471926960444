const state = {
    regFormUrl: "v2/registration/representative-info",
    regTitle: "Broker Information",
    regTab: "broker-info",
    regTempId: null,
    isReferred: false,
    referredId: "",
    referredUserType: '',
    isRepCodeRedirection: false,
    repCode: '',
    tabCount: null,
    regType:null
}

const getters = {
    regTempId: state => state.regTempId,
    regFormUrl: state => state.regFormUrl,
    regTab: state => state.regTab,
    regTitle: state => state.regTitle,
    regTabNumber:state=>state.regTabNumber,
    isReferred: state => state.isReferred,
    referredId: state => state.referredId,
    referredUserType: state => state.referredUserType,
    isRepCodeRedirection: state => state.isRepCodeRedirection,
    repCode:  state => state.repCode,
    tabCount: state => state.tabCount,
    regType: state=>state.regType
}

const actions = {}

const mutations = {
    setRegFormUrl(state, url) {
        state.regFormUrl = url;
    },
    setRegTitle(state, regTitle) {
        state.regTitle = regTitle;
    },
    setRegTab(state, tab) {
        state.regTab = tab;
    },
    setRegTempId(state, regTempId) {
        state.regTempId = regTempId;
    },
    setReferralFlag(state, data) {
      state.isReferred = data
    },
    setReferralId(state, data) {
      state.referredId = data
    },
    setReferralUserType(state, data) {
      state.referredUserType = data
    },
    setRepCodeState(state, data) {
      state.isRepCodeRedirection = data
    },
    setRepCode(state, data) {
      state.repCode = data
    },
    setTabCount(state, data) {
      state.tabCount = data
    },
    setRegType(state,data){
      state.regType= data
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

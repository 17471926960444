<template>
  <span class="spin-wrap">
    <div class="spinner-grow me-1" :style="styles" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow me-1" :style="styles" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow" :style="styles" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </span>
</template>
<script>
export default {
  props: {
    styles: {
      required: false,
      default:()=>({
        width: "4px",
        height: "4px",
      }),
    },
  }
};
</script>
<style scoped>
.spinner-grow{
    padding:1px;
}
.spin-wrap{
    margin-left:4px;
}
</style>
export default {
    API_LOGIN_URL: "v1/auth/login",
    API_SEND_LOGIN_OTP: "v1/auth/authenticate",
    API_VALIDATE_LOGIN_OTP: "v1/auth/authenticate-code",
    API_LOGOUT: "v1/api/v1/logout",
    API_SEND_FORGOT_PASSWORD_OTP: "v1/auth/reset-password",
    API_CHECK_FORGOT_PASSWORD_OTP: "v1/auth/reset-password-check",
    API_FORGOT_PASSWORD: "v1/auth/reset-password-code",

    GET_MEMBERS: "v2/get-members",

    VALIDATE_SSN: process.env.VUE_APP_VALIDATE_SSN,
    VALIDATE_PHONE_NUMBER: process.env.VUE_APP_VALIDATE_PHONE_NUMBER,
    VALIDATE_ADDRESS: process.env.VUE_APP_VALIDATE_ADDRESS,
    VALIDATE_ROUTING_NUMBER: process.env.VUE_APP_VALIDATE_ROUTING_NUMBER,
    VALIDATE_ROUTING_NUMBER_NEW: process.env.VUE_APP_VALIDATE_ROUTING_NUMBER_NEW,
    VALIDATE_EMAIL: process.env.VUE_APP_VALIDATE_EMAIL,
    BENEFIT_STORE_URL : process.env.VUE_APP_BENEFIT_STORE_URL,
    KNOWLEDGE_BASE_API_URL:process.env.VUE_APP_API_KNOWLEDGE_BASE,
    KNOWLEDGE_BASE_DOWNLOAD_URL:process.env.VUE_APP_KNOWLEDGE_BASE_DOWNLOAD_URL,
    PURENROLL_API_URL:process.env.VUE_APP_PURENROLL_API_URL,
    API_URL:process.env.VUE_APP_API_URL,
    PURENROLL_BASE_URL:process.env.VUE_APP_PURENROLL_BASE_URL,

    GET_24HRS_ENROLLMENT: "v2/get-enrollments-24hrs",
    VIEW_UPDATE_RATE: "v2/get-new-rate-details/",
    REP_24HRS_EDIT: "v2/rep-24hrs-edit",
    GROUP_APP_URL : process.env.VUE_APP_GROUP_APP_URL,
    ANDROID_APP_URL : process.env.VUE_APP_ANDROID_URL,
    IOS_APP_URL : process.env.VUE_APP_IOS_URL,

    EXPORT_MEMBER_LIST: "v2/export/member-report",
    EXPORT_REP_LIST: "v2/export/rep-report",
    EXPORT_GROUP_LIST: "v2/export/group-report",
    EXPORT_REP_LIST_FORMATTED: "v2/export/new-rep-report",
    EXPORT_GROUP_LIST_FORMATTED: "v2/export/new-group-report",
    EXPORT_MEMBER_LIST_FORMATTED: "v2/export/member-report-new",
    OAUTH_AUTHORIZE: "v1/oauth/authorize",
    CALENDAR_API_URL: process.env.VUE_APP_API_CALENDAR_API_URL,
    GET_REP_INFO:"v2/data-validation/get",
    UPDATE_REP_INFO:"v2/data-validation/update",
    GET_LEADS_QUE: 'v2/lead-storage/get',
    CRM_URL: process.env.VUE_APP_CRM_URL,
    ACM_URL: process.env.VUE_APP_ACM_URL
}
export const apiUrl =  process.env.VUE_APP_API_URL
export const baseApiUrl =  process.env.VUE_APP_BASE_API_URL
export const adminApiUrl = process.env.VUE_APP_API_ADMIN
export const authLessApiUrl = process.env.VUE_APP_API_AUTH_LESS_URL

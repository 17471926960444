import Vue from 'vue'
import customRules from "@/validation/rules"
import Endpoint from "@/resource/EndPoints/endpoints"
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
    validate
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
Object.keys(customRules).forEach(rule => {
    extend(rule, customRules[rule])
});
localize("en", en);
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);



import axios from 'axios'
const otherUrl = axios.create({
    baseURL: "",
});

const neverBounceEmail = (value) => {
    let data;
    return validate(value, 'email').then(result => {
        if (result.valid) {
            return otherUrl.post(Endpoint.VALIDATE_EMAIL, { email: value })
                .then((res) => {
                    if (res.data.validationCode == 'valid') {
                        data = { valid: true }
                        return data
                    } else {
                        return data = {
                            valid: false,
                            data: {
                                message: "The email field must be a valid email."
                            }
                        }
                    }
                })
                .catch((err) => {
                    return data = {
                        valid: false,
                        data: {
                            message: err.response.data.data[0].error_message
                        }
                    }
                })

        } else {
            return data = {
                valid: false,
                data: {
                    message: "The email field must be a valid email."
                }
            }
        }
    });
};

extend("neverbounce", {
    //@todo displaying dynamic message sent from server
    message : "Email is invalid - Validated using Neverbounce API",
    validate: neverBounceEmail,
});


const clearoutPhone = (value) => {
    let data
    if (value.length == 10) {
        let url = Endpoint.VALIDATE_PHONE_NUMBER + value
        return otherUrl.get(url)
            .then((res) => {
                if (res.data.status == 'success') {
                    data = { valid: true }
                    return data
                } else {
                    return data = {
                        valid: false,
                        data: {
                            message: "Phone is invalid - validated using ClearoutPhone"
                        }
                    }
                }
            })
            .catch((err) => {
                return data = {
                    valid: false,
                    data: {
                        message: err.response.data.data[0].error_message
                    }
                }
            })

    } else {
        return data = {
            valid: false,
            data: {
                message: "Phone is invalid - validated using ClearoutPhone"
            }
        }
    }
};

extend("clearoutPhone", {
     //@todo displaying dynamic message sent from server
    message: "Phone is invalid - validated using ClearoutPhone",
    validate: clearoutPhone,
});

export function checkValidZip (str) {
    let regexp = /^[0-9]{5}$/
    if (regexp.test(str)) {
        return true
    } else {
        return false
    }
}

export function checkValidPhoneNumber (str) {
    let regexp = /^[0-9]{10}$/
    if (regexp.test(str)) {
        return true
    } else {
        return false
    }
}

export function isOver18 (dateOfBirth) {
    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    if (age >= 18) {
        return true;
    } else {
        return false;
    }
}
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', {
          attrs: {
            "id": "label-".concat(_vm.labelFor),
            "for": _vm.labelFor
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        })]), _c('b-form-file', {
          attrs: {
            "id": _vm.labelFor,
            "browse-text": "Choose File",
            "state": _vm.getValidationState(validationContext),
            "readonly": _vm.readonly,
            "placeholder": _vm.computedPlaceholder,
            "accept": _vm.computedMimeTypes
          },
          on: {
            "change": _vm.fileHandler
          },
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), validationContext.errors[0] || _vm.apiErrors ? _c('error-text', {
          attrs: {
            "errors": validationContext.errors && validationContext.errors.length > 0 ? validationContext.errors : _vm.apiErrors ? _vm.apiErrors[_vm.fieldName] : '',
            "field": _vm.fieldName
          }
        }) : _vm._e()];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="app">
    <template v-if="register">
      <vue-confirm-dialog class="rep-app-confirm-dialog"></vue-confirm-dialog>
          <div class="registration">
               <registration-auth-header/>
               <router-view />
          </div>
      <div>

      </div>
    </template>
    <template v-else-if="verifyGroupCensusUser">
      <div>
        <group-census-tab />
      </div>
    </template>
    <template v-else>
 <!-- @todo add warning class when delete/waring  -->
 <vue-confirm-dialog class="rep-app-confirm-dialog"></vue-confirm-dialog>
    <div v-if="authHeader">
      <auth-header />
    </div>
    <div v-else-if="defaultHeader" class="stickyHeader">
      <site-header />
    </div>
    <div v-if="sidebarNav">
      <sidebar />
    </div>
    <router-view />
    <div v-if="defaultFooter">
      <site-footer />
    </div>
    <app-download-modal :appUrl="appDownloadUrl" />
    </template>
    <div>
      <down-line-notification />
    </div>
  </div>
</template>
<script>

// import Api from './resource/Api'
import SiteHeader from "@/components/Header.vue";
import SiteFooter from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import AuthHeader from "@/components/AuthHeader.vue";
import RegistrationAuthHeader from '@/components/RegistrationAuthHeader';
import moment from "moment";
import AppDownloadModal from "@/components/AppDownloadModal.vue";
import endpoint from "@/resource/EndPoints/endpoints";
import DeviceHelper from "@/resource/DeviceHelper";
import DownLineNotification from "./components/DownLineNotification";
import GroupCensusTab from "./views/GroupCensus/GroupCensusTab";
// import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    SiteHeader,
    Sidebar,
    SiteFooter,
    AuthHeader,
    RegistrationAuthHeader,
    AppDownloadModal,
    DownLineNotification,
    GroupCensusTab
  },
  data: function() {
    return {
      defaultHeader: true,
      defaultFooter: true,
      sidebarNav: true,
      authHeader: false,
      registerAuthHeader:false,
      appDownloadUrl: "",
      register:false,
      verifyGroupCensusUser: false,
      verifyCaptcha: false,
      groupCensus: false
    };
  },
  methods: {
    checkUserDevice() {
      let device = new DeviceHelper();
      if (device.isAndroid()) {
        this.appDownloadUrl = endpoint.ANDROID_APP_URL;
        this.appDownloadModal();
      } else if (device.isIos()) {
        this.appDownloadUrl = endpoint.IOS_APP_URL;
        this.appDownloadModal();
      }
    },

 
    appDownloadModal() {
      if(this.$store.getters.fromMobileApp) return;
      if (localStorage.use_web_status && localStorage.status_expiry) {
        let expiryDate = moment(localStorage.status_expiry);
        let currentDate = moment(new Date());
        let duration = moment.duration(expiryDate.diff(currentDate));
        if (duration.asHours() < 0) {
          localStorage.use_web_status = "";
          localStorage.status_expiry = "";
          this.$bvModal.show("app-download-modal");
        }
      } else {
        this.$bvModal.show("app-download-modal");
      }
    },
  },
  // computed: {
  //   ...mapGetters({ dialogClass: "dialogClass" }),
  // },
  watch: {
    "$route.params": {
      handler() {
        
        if (
          this.$route.name == "Login" ||
          this.$route.name == "SignUp" ||
          this.$route.name == "LoginSave" ||
          this.$route.name == "ForgotPassword" ||
          this.$route.name == "OtpVerification" ||
          this.$route.name == "ResetPassword" ||
          this.$route.name == "PasswordOtpVerification" ||
          this.$route.name == null
         

        ) {
          this.defaultHeader = false;
          this.defaultFooter = false;
          this.sidebarNav = false;
          this.authHeader = true;
          this.register=false;
        } else if( 
          // Registration Procss
          this.$route.name == "Registration" ||
          this.$route.name == "HomeRegistration" ||

          this.$route.name == "BrokerInfo" ||
          this.$route.name == "PersonalInfo" ||
          this.$route.name == "LoginInfo" ||
          this.$route.name == "ContactInfo" ||
          this.$route.name == "AddressInfo" ||
          this.$route.name == "RegistrationType" ||
          this.$route.name == "BusinessInfo" ||
          this.$route.name == "PaymentInfo" ||
          this.$route.name == "SignatureVerification" ||
          this.$route.name == "RegisterSuccess" ||
          this.$route.name == "LoginSave" ||
          this.$route.name == "OAuthVerify" ||
          this.$route.name == "UploadFileShare" ||
          this.$route.name == "DownloadFileShare" ||
          this.$route.name == "TicketSubmission") {
          this.defaultHeader = false;
          this.defaultFooter = false;
          this.sidebarNav = false;
          this.authHeader = true;
          this.register = true;
        } else if ( this.$route.name == "VerifyCaptcha") {
          this.defaultHeader = false;
          this.defaultFooter = false;
          this.sidebarNav = false;
          this.authHeader = true;
          this.register = false;
          this.verifyGroupCensusUser = true
          this.verifyCaptcha = true
        }else{
          this.defaultHeader = true;
          this.defaultFooter = true;
          this.sidebarNav = true;
          this.authHeader = false;
          this.register=false;
        }
      },
      immediate: true,
    },
  },

  created() {    
    setTimeout(() => {
      this.checkUserDevice();      
    }, 2000);
  },
};
</script>
<style>
@import url("./assets/fonts/stylesheet.css");
@import "./assets/css/vue-multiselect.css";
@import "./assets/css/vue-confirm-dialog.css";

#app {
  font-family: "SF Pro Display";
}
body {
  background-color: #f3f7fa !important;
}
a,
a:hover {
  color: inherit;
  text-decoration: none !important;
}

#defaultNav {
  padding: 25px 0;
  /* background: rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  align-items: center;
}
#defaultNav .logo {
  margin: 0;
}
.default_footer #footer {
  display: none;
}

.authWrapper {
  padding-top: 4em;
}
.table > tbody,
.premiums-table tr:nth-child(2) {
  border-top: 0px !important;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Helper from "@/resource/Helper"
import store from "@/store"
import axios from "axios";


Vue.use(VueRouter)

const routes = [

  // Rep Dashboard
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      Helper.logout()
      next({
        path: "/"
      })
    }
  },

  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard/Dashboard'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Dashboard'
    }
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: requiredAuthentication,
    component: () => import('../views/Login/Login.vue'),
    meta: {
      title: 'Login'
  }
  },
  {

    name: "LoginSave",
    path: "/login-save",
    component: () => import('@/views/Login/LoginSave.vue'),
    meta: {
      title: 'Login Save'
  }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue'),
    meta: {
      title: 'Sign Up'
  }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword/ForgotPassword.vue'),
    meta: {
      title: 'Forgot Password'
  }
  },
  {
    path: '/otp-verifcation',
    name: 'OtpVerification',
    component: () => import('../views/Login/OtpVerification.vue'),
    meta: {
      title: 'Otp Verification'
  }
  },
  {
    path: '/password-otp-verifcation',
    name: 'PasswordOtpVerification',
    component: () => import('../views/ForgotPassword/ForgotOtpVerification.vue'),
    meta: {
      title: 'Password Otp Verification'
  }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ForgotPassword/ResetPassword.vue'),
    meta: {
      title: 'Reset Password'
  }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Change Password'
    }
  },
  {
    path: '/account-information',
    name: 'AccountInformation',
    component: () => import('../views/AccountInformation.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Account Information'
    }
  },
  {
    path: '/select-theme',
    name: 'SelectTheme',
    component: () => import('../views/SelectTheme/SelectTheme.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Select Theme'
    }
  },
  {
    path: '/directory',
    name: 'Directory',
    component: () => import('../views/Directory.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Directory'
    }
  },

  {
    path: '/commissions',
    name: 'Commissions',
    component: () => import('@/views/Commission/CommissionList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Commissions'
    }
    
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Setting/Setting.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Settings'
    }
  },
  {
    path: '/alt-email',
    name: 'AlternativeEmail',
    component: () => import('../views/AlternativeEmail.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Alternative Email'
    }
  },
  {
    path: '/rep-admins',
    name: 'RepAdminList',
    component: () => import('@/views/RepAdmin/RepAdminList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Rep Admins'
    }
  },
  {
    path: '/view-members',
    name: 'ViewMembers',
    component: () => import('../views/MemberList/ViewMembers.vue'),
    beforeEnter: authenticationRequired,
        meta: {
            title: 'View Members'
        }
  },
  {
    path: '/leads',
    name: 'LeadsQuestionsList',
    component: () => import('../views/Leads/LeadsQuestionsList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Lead Questions'
    }
  },
  {
    path: '/licenses/:downlineRepId?',
    name: 'Licenses',
    component: () => import('@/views/License/LicenseList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Licenses'
    }
  },
  {
    path: '/configured-plans',
    name: 'HomepagePlans',
    component: () => import('@/views/HomepagePlan/HomepagePlans.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Configured Plans'
    }
  },
  {
    path: '/admin-message',
    name: 'AdminMessage',
    component: () => import('@/views/Message/AdminMessage.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Admin message'
    }
  },
  {
    path: '/knowledge-center',
    name: 'KnowledgeCenter',
    component: () => import('@/views/KnowledgeCenter/KnowledgeCenter.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Knowledge Center'
    }
  },

  {
    path: '/my-files',
    name: 'MyFiles',
    component: () => import('@/views/File/FileList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'My files'
    }
  },
  {
    path: '/rep-referral',
    name: 'RepReferralView',
    component: () => import('@/views/RepRefferal/RepReferralView.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Rep Referral'
    }
  },
  {
    path: '/view-employers',
    name: 'EmployerGroupList',
    component: () => import('@/views/EmployerGroup/EmployerGroupList'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'View Employers'
    }
  },
  {
    path: '/view-downline-reps',
    name: 'DownlineRepList',
    component: () => import('@/views/DownlineRep/DownlineRepList'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'View Downline Reps'
    }
  },
  // {
  //   path: '/group-rep-display-setting',
  //   name: 'GrouprepDisplaysetting',
  //   component: () => import('../views/GrouprepDisplaysetting.vue')
  // },
  {
    path: '/employer/:groupId/info',
    name: 'EmployerGroupDetail',
    component: () => import('@/views/EmployerGroup/EmployerGroupDetail.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Employer Group Detail'
    }
  },
  {
    path: '/downline-rep/:downlineRepId/info',
    name: 'DownlineRepDetail',
    component: () => import('@/views/DownlineRep/DownlineRepDetail.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Downline Rep Detail'
    }
  },
  {
    path: '/downline-rep/:downlineRepId/plans',
    name: 'DownlineRepPlans',
    component: () => import('@/views/DownlineRep/DownlineRepPlans.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Downline Rep Plans'
    }
  },

  // Rep Registration
  {
    path: '/registration/success',
    name: 'RegisterSuccess',
    component: () => import('@/views/Registration/RegisterSuccess.vue'),
    meta: {
      title: 'Register Success'
  }
  },
  {
    path: '/employer/:groupId/plans',
    name: 'EmployerGroupPlans',
    component: () => import('@/views/EmployerGroup/EmployerGroupPlans.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Employer Group Plans'
    }
  },
  {
    path: '/pending-items',
    name: 'PendingItem',
    component: () => import('@/views/PendingItemsPage.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Items'
    }
  },
  {
    path: '/rep-analytics',
    name: 'RepAnalytics',
    component: () => import('@/views/RepAnalytics/RepAnalytics.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Rep Analytics'
    }
  },
  {
    path: '/rep-analytics/downline',
    name: 'RepAnalyticsDownline',
    component: () => import('@/views/RepAnalytics/RepAnalyticsDownline.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Rep Analytics Downline'
    }
  },
  {
    path: '/document-view-details/:userId',
    name: 'DocumentViewDetails',
    component: () => import('../views/DocumentViewDetails.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Document Details'
    }
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('@/views/Analytics/Analytics.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Analytics'
    }
  },
  {
    path: '/admin-analytics',
    name: 'AdminAnalytics',
    component: () => import('@/views/Admin/AdminAnalytics.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Admin Analytics'
    }
  },
  {
    path: '/rep-logins',
    name: 'RepLogins',
    component: () => import('@/views/Admin/RepLogins.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Rep Logins'
    }
  },
  {
    path: '/not-logged-in-reps',
    name: 'NotloggedInReps',
    component: () => import('@/views/Admin/NotloggedInReps.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Not logged In Reps'
    }
  },
  {
    path: '/member-logins',
    name: 'MemberLogins',
    component: () => import('@/views/Admin/MemberLogins.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Member Logins'
    }
  },
  {
    path: '/pending/member65',
    name: 'PendingMember65List',
    component: () => import('@/views/PendingItem/PendingMember65List.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Member65'
    }
  },
  {
    path: '/pending/dependents-child',
    name: 'PendingDependentsChildList',
    component: () => import('@/views/PendingItem/PendingDependentsChildList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Dependents Child'
    }
  },
  {
    path: '/pending/dependents-spouse',
    name: 'PendingDependentsSpouseList',
    component: () => import('@/views/PendingItem/PendingDependentsSpouseList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Dependents Spouse'
    }
  },
  {
    path: '/pending/signatures',
    name: 'PendingSignatureList',
    component: () => import('@/views/PendingItem/PendingSignatureList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Signature'
    }
  },
  {
    path: '/pending/enrollments',
    name: 'PendingEnrollmentList',
    component: () => import('@/views/PendingItem/PendingEnrollmentList.vue'), 
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Enrollment'
    }
  },
  {
    path: '/pending/view-screen-shot/:id',
    name: 'ViewScreenShots',
    component: () => import('@/views/PendingItem/ViewScreenShots.vue'),
    props: true,
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Screenshots'
    }
  },
  {
    path: '/pending/rep-registrations',
    name: 'PendingRepRegistrationList',
    component: () => import('@/views/PendingItem/PendingRepRegistrationList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Rep Registration'
    }
  },
  
  {
    path: '/pending/group-registrations',
    name: 'PendingGroupRegistrationList',
    component: () => import('@/views/PendingItem/PendingGroupRegistrationList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Group Registration'
    }
  },
  {
    path: '/member/:policyId/policy-details',
    name: 'MemberPolicyDetail',
    component: () => import('@/views/MemberList/MemberPolicyDetail.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Policy Details'
    }
  },
  {
    path: '/recent-actvities',
    name: 'Notification',
    component: () => import('@/views/Notification/Notification.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Recent Activities'
    }
  },
  {
    path: '/group/:groupId/view-members',
    name: 'MemberListByGroup',
    component: () => import('@/views/MemberList/MemberListByGroup.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'View Memebers'
    }
  },
  {
    path: '/rep/:repId/view-members/:type',
    name: 'MemberListByRep',
    component: () => import('@/views/MemberList/MemberListByRep.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Member Rep List'
    }
  },
  {
    path: '/pending/downline-reps',
    name: 'PendingDowlineRepList',
    component: () => import('@/views/PendingItem/PendingDowlineRepList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Pending Downline Rep List'
    }
  },
  {
    path: '/pending/rep-edit-24',
    name: 'Enrollments24hr',
    component: () => import('@/views/PendingItem/Enrollments24hr.vue'),beforeEnter: authenticationRequired,
    meta: {
        title: 'Enrollment 24hr'
    }
  },
  {
    path: '/pending/rep-edit-products/:enrollmentId',
    name: 'EnrollmentsEdit',
    component: () => import('@/views/PendingItem/EnrollmentsEdit.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Edit Enrollment'
    }
  },
  {
    path: '/view-quotes',
    name: 'ViewQuotes',
    component: () => import('@/views/ViewQuotes/ViewQuotes.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'View Quotes'
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('@/views/Registration/Registration.vue'),
    beforeEnter: registerRoute,
    meta: {
      title: 'Registration'
  }
  },
  {
    path: '/business',
    name: 'BusinessStat',
    component: () => import('@/views/BusinessStat.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Business Stat'
    }
  },
  {
    path: '/home-registration',
    name: 'HomeRegistration',
    component: () => import('@/views/Registration/HomeRegistration.vue'),
    beforeEnter: registerRoute,
    meta: {
      title: 'Home Registration'
  }
  },
  {
    path: '/continue-registration',
    name: 'Continue Registration',
    beforeEnter: continueRegistration,
    meta: {
      title: 'Continue Registration'
  }
  },
  {
    path: '/downline-tree/:agent_id?',
    name: 'DownlineTree',
    component: () => import('@/views/DownlineTree/DownlineTree.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Downline Tree'
    }
  },
  {
    path: '/manage-video',
    name: 'ManageVideo',
    component: () => import('@/views/ManageVideo/ManageVideo.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Manage Video'
    }
  },
  {
    path: '/knowledge-feeds',
    name: 'KnowledgeFeed',
    component: () => import('@/views/KnowledgeFeed/KnowledgeFeed.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Knowledge Feeds'
    }
  },
  {
    path: '/tickets',
    name: 'ManageTicket',
    component: () => import('@/views/ManageTicket/ManageTicket.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Manage Tickets'
    }
  },
  {
    path: '/default-otp',
    name: 'DefaultOtp',
    component: () => import('@/views/DefaultOtp/OtpList.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Default otp'
    }
  },
  {
    path: '/update-requests',
    name: 'RepInfoRequest',
    component: () => import('@/views/RepInfoRequest/RepInfoRequest.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Update Requests'
    }
  },
  {
    path: '/file-download',
    name: 'DownloadFileShare',
    component: () => import('@/views/FileShare/DownloadFileShare.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Download File '
    }
  },
  {
    path: '/file-upload',
    name: 'UploadFileShare',
    component: () => import('@/views/FileShare/UploadFileShare.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Upload File'
    }
  },
  {

    path: '/oauth/verify',
    name: 'OAuthVerify',
    component: () => import('../views/OAuth/OAuth.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Verify Auth'
    }
  },
  {
    path: '/calendar-events',
    name: 'Event',
    component: () => import('@/views/CalendarEvent/CalendarEvent.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Event'
    }
  },
  {
    path: '/calendar-bookings',
    name: 'Booking',
    component: () => import('@/views/CalendarBooking/CalendarBooking.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Bookings'
    }
  },

  {
    path: '/report-an-issue',
    name: 'TicketSubmission',
    component: () => import('@/views/TicketSubmission.vue'),
    beforeEnter: requiredAuthentication,
    meta: {
      title: 'Report Issue'
  }
  },
  
  {
    path: '/calendar-login',
    name: 'CalendarLogin',
    beforeEnter: redirectToCalendar,
    meta: {
      title: 'Calender Login'
  }
  },
  {
    path: '/neura-blogs',
    name: 'NeuraBlogs',
    component: () => import('@/views/NeuraBlogs/NeuraBlogs.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Neura Blogs'
    }
  },
  {
    path: '/view-msg',
    name: 'ViewMsg',
    component: () => import('@/views/ViewMsg/ViewMsg'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'View Message'
    }
  },
  {
    path: '/campaign-stats',
    name: 'CorContract',
    component: () => import('@/views/CorContract/CorContractView'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Campaign Stats'
    }
  },
  {
    path: '/view-group-quote',
    name: 'GroupCensusList',
    component: () => import('../views/GroupCensus/partials/GroupCensusList'),
    beforeEnter: authenticationRequired,
    meta: {
      title: 'Group Census List'
    }
  },
  {
    path: '/commission-config',
    name: 'CommissionConfig',
    component: () => import('@/views/Dashboard/Commission/CommissionConfig.vue'),
    beforeEnter: authenticationRequired,
    meta: {
        title: 'Comission Config'
    }
  },
/*  {
    path: '/group-census',
    name: 'GroupCensus',
    component: () => import('@/views/GroupCensus/GroupCensusView.vue'),
  },*/
  {
    path: '/group-verify',
    name: 'VerifyCaptcha',
    component: () => import('@/views/GroupCensus/GroupCensusTab.vue'),
  }
]

function requiredAuthentication(to, from, next) {
  /*eslint-disable*/
  // debugger;
  // console.log(store)
  // console.log(store.getters.accessToken)
  if(store.getters.accessToken && to.name == 'Login')
  {
    return next({
    name: "Dashboard"
  })
}
  return next()
}


function registerRoute(to, from, next) {
  /*  let repCode = to.query.repCode
    let rid = to.query.rid
     if (repCode) {
      console.log('2')
      return next({
        name: "Registration",
        params: {repCode: repCode}
      })
     } else if (rid) {
      console.log('3')
      return next({
        name: "Registration",
        params: {rid: rid}
      })
    } else {
      console.log('4')
      return next()
    }*/
  return next()
}



async function continueRegistration(to, from, next) {
  let [registrationId, savedTab, tabTitle, tab, tabCount] = [to.query.registrationId, to.query.savedTab];

  console.log('saveTabInfo', to.query.savedTab);
  let regUrl = 'v2/registration/registration-type';
  let regTempData = {
    registration_id: registrationId,
  };

  try {
    const res = await axios({
      method: 'get',
      url: regUrl,
      params: regTempData,
      headers: {
        Authorization: 'Basic ' + process.env.VUE_APP_QA_BS_API_AUTH_KEY
      }
    });
    const data = res.data.data;
    const regType = data[0].value;

    switch (savedTab) {
      case "representative_info":
        [tabTitle, tab, tabCount] = ['Home Registration', 'Home-info', '1'];
        break;
      case "personal_info_v3":
        [tabTitle, tab, tabCount] = ['Contact Information', 'personal-info', regType==='individual'?'2':'3'];
        break;
        case "login_info":
          [tabTitle, tab, tabCount] = ['Login Information', 'login-info', regType==='individual'?'5':'6'];
          break;
      case "contact_info":
        [tabTitle, tab, tabCount] = ['Broker Back Office Login Information', 'contact-info', regType==='individual'?'6':'7'];
        break;
      case "address_info":
        [tabTitle, tab, tabCount] = ['Address Information', 'address-info', regType==='individual'?'4':'5'];
        break;
      case "registration_type":
        [tabTitle, tab, tabCount] = ['Registration Type', 'registration-type', '6'];
        break;
      case "business_info":
        [tabTitle, tab, tabCount] = ['Business Information', 'business-info', '2'];
        break;
      case "payment_info":
        [tabTitle, tab, tabCount] = ['Payment Information', 'payment-info', regType==='individual'?'7':'8'];
        break;
      case "verification_info":
        [tabTitle, tab, tabCount] = [
          'Signature & Verification', 
          'verification-info', 
          regType==='individual'?'8':'9'
        ];
        break;
      default:
        [tabTitle, tab, tabCount] = ['Home Registration', 'Home-info', '1'];
    }

    let url;
    if (tab === 'Home-info') {
      url = `v2/registration/representative-info`;
    } else {
      url = `v2/registration/${tab}`;
    }

    store.commit("setRegFormUrl", url);
    store.commit("setRegTitle", tabTitle);
    store.commit("setTabCount", tabCount);
    store.commit("setRepCode", to.query.rep_code);
    store.commit("setRegTempId", registrationId);
    if (tab === 'representative-info') {
      store.commit("setRegTab", 'Home-info');
    } else {
      store.commit("setRegTab", tab);
    }

    return next({
      path: "/registration",
      query: { rep_code: to.query.rep_code }
    });
    
  } catch (error) {
    console.log('err');
  }
}


function hideSideBar(to, from, next) {
  let hideSideBar = Helper.hideSideBar();
  if (hideSideBar) {
    Helper.calendarAppLink()
    return next({
      path: "/",
    })
  }
  return next()
}
/*eslint-disable */
function redirectToCalendar(to, from, next) {

  let accesstoken = to.query.accessToken
  let fromMobileApp = to.query.from_mobile_app
  if (accesstoken) {

    store.commit("setFromMobileApp", fromMobileApp);
    store.commit("setAccessToken", accesstoken);
    store.commit("authenticated", true);
    window.location = Helper.calendarAppLink();
  }
}

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  routes
})


function authenticationRequired(to, from, next) {
  if (store.getters.authenticated == true) {
      return next()
  }
  return next({ path: "/login" })

}

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? `Rep Dashboard | ${toRoute.meta.title}` : 'Rep Dashboard';
  next();
})

export default router


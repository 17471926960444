<template>
  <div class="toggle-switch switch-box">
    <label @click.prevent="switchElement" class="switch" for="checkbox">
      <input type="checkbox" id="checkbox" v-model="computedInputData" />
      <div class="slider round"></div>
    </label>
      <p class="text-class">
      {{ text ? text : "" }}
      </p>
  </div>
</template>
<script>
export default {
  props: {
    vmodel: {
      required: true,
    },
    text: {
      required: false,
    },
  },
  computed: {
    computedInputData: {
      get: function () {
        let val = this.vmodel;
        return val == 1 || val == true ? true : false;
      },
      set: function (value) {
        value = value == 1 || value == true ? true : false;
        this.$emit("update:vmodel", value ? value : "");
      },
    },
  },
  methods: {
    switchElement() {
      this.$emit("onClicked");
    },
  },
};
</script>
<style scoped>
.toggle-switch .switch {
  display: inline-block;
  height: 26px;
  position: relative;
  width: 45px;
}
.toggle-switch .switch input {
  display: none;
}
.toggle-switch .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.toggle-switch .slider:before {
  background-color: #fff;
  top: 50%;
  content: "";
  height: 22px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 22px;
  transform: translate(0, -50%);
}
.toggle-switch input:checked + .slider {
  background-color: #03c3ec;
}
.toggle-switch input:checked + .slider:before {
  transform: translate(16px, -11px);
}
.toggle-switch .slider.round {
  border-radius: 34px;
}
.toggle-switch .slider.round:before {
  border-radius: 50%;
}

.switch-box{
  display: flex;
}

.text-class{
  margin-left : 5px
}
</style>
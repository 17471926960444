var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "site-sidebar sidebar-section"
  }, [_c('sidebar-menu', {
    attrs: {
      "menu": _vm.sideBarMenu,
      "width": "225px"
    },
    on: {
      "item-click": _vm.onItemClick
    }
  }), _vm.isLoading ? _c('span', {
    staticClass: "no-sidebar-menu d-lg-inline d-none"
  }, [_vm._v(" Initializing Menu "), _c('spinner-grow')], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
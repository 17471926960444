<template>
  <b-form-group :class="formGroupClass">
    <validation-provider
      :name="validationLabel ? validationLabel : labelName"
      :rules="rules"
      v-slot="validationContext"
    >
      <label :id="`label-${labelFor}`" :for="labelFor">
        <span v-html="computedLabel" />
        <slot name="labelMessage" />
      </label>
      <b-form-file
        :id="labelFor"
        browse-text="Choose File"
        :state="getValidationState(validationContext)"
        v-model="computedInputData"
        :readonly="readonly"
        :placeholder="computedPlaceholder"
        :accept="computedMimeTypes"
        multiple
      >
      </b-form-file>
      <slot name="uploadedFiles"></slot>
      <div class="error-text">
        <span>{{
          errorField ? errorField[0] : validationContext.errors[0]
        }}</span>
      </div>
      <slot name="customErrors"></slot>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: false,
    },
    vmodel: {
      required: true,
      default: null,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    fileClass: {
      type: String,
      required: false,
    },
    acceptMimeTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    validationLabel:{
      required:false
    },
    apiErrors: {
      // Object with server side error flags
      required: false,
      default: () => {},
    },
    apiErrorKey: {
      // key pointing to particular entry in apiError Object
      required: false,
      default: "",
    },
  },
  components: {
    ValidationProvider,
  },
  data: () => {
    return {
      file: [],
    };
  },
  computed: {
    computedMimeTypes() {
      return this.acceptMimeTypes.toString();
    },
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `No File Choosen`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function () {
        return this.vmodel;
      },
      set: function (files) {
        // let file = this.file;
        this.$emit("update:vmodel", files ? files : []);
      },
    },
    errorField() {
      return this.apiErrors
        ? this.apiErrors[this.apiErrorKey]
          ? this.apiErrors[this.apiErrorKey]
          : null
        : null;
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style>
.input-required {
  color: red !important;
}

.custom-file-label {
  display: none !important;
}
</style>

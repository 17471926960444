<template>
    <img :src="icon" v-b-tooltip.hover :title="newTitle" :class="className"/>
</template>
<script>
export default {
  name: "ValidIcon",
  props: {
    title: {
      required: false,
    },
    isValid: {
      required: true,
      default: true,
    },
    className:{
      required:false,
    }
  },
  computed: {
    newTitle() {
      if (!this.title) {
        return this.isValid ? "Valid" : "Invalid";
      } else {
        return this.title;
      }
    },
    icon() {
      if (this.isValid) {
        return require("@/assets/images/approved.png");
      } else {
        return require("@/assets/images/exclam.png");
      }
    },
  },
};
</script>
<style scoped>
img {
  width: 5%;
  padding-bottom: 2px;
}
</style>
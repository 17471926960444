<template>
  <div class="downline-container">
    <b-modal
        v-bind:hide-footer="true"
        id="rep-downline-notification"
        centered
        @shown="getNotificationStatus()"
        size="sm"
    >
      <template #modal-header >
        <div
            class="popUp-header text-center w-100"
            style="border-bottom: 0px !important"
        >
          <h4 class="heading-size"> {{notificationStatus ? 'Disable' : 'Allow'}} Downline Notification</h4>
          <b-button
              variant="close-btn"
              @click="$bvModal.hide('rep-downline-notification')"
          >
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <div class="d-flex justify-content-center">
        <div class="form-check form-switch">
          <div>
            <input class="form-check-input toggle-custom-size"
                   type="checkbox"
                   v-model="notificationStatus"
                   @change="enableDownlineNotification"
                   role="switch"
                   id="flexSwitchCheckDefault">
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DownLineNotification",
  data () {
    return {
      notificationStatus: false
    }
  },
  methods: {
    getNotificationStatus () {
      let app = this
      let loader= this.$loading.show();
      axios.get('v2/get-cc-email-notification')
          .then(response => {
            let flagStatus = response.data.data[0].is_cc_email
            if (flagStatus == 1) {
              app.notificationStatus = true
            }
            if (flagStatus == 0) {
              app.notificationStatus = false
            }
          })
          .catch(err => {
            app.$notify(err.response.data.message, "error")
            app.checkErrorFlagStatus();
          }).finally(()=> {
        loader.hide()
      })
    },
    checkErrorFlagStatus () {
      let app = this;
      console.log(app.notificationStatus)
      app.notificationStatus ? app.notificationStatus = false : app.notificationStatus = true
    },
    enableDownlineNotification () {
      let app = this
      this.$confirm({
        title:"Rep Downline Notification",
        message:`Do you want to ${app.notificationStatus ? 'allow' : 'disable'} rep downline notification ?`,
        button: {
          yes:"Yes",
          no:"No"
        },
        callback:(confirm)=>{
          if(confirm){
            let loader= this.$loading.show();
            let data = {
              flag: app.notificationStatus
            }
            axios.post('v2/set-cc-email-notification', data)
                .then(response => {
                  let message=response.data.message;
                  app.$notify(message);
                  app.getNotificationStatus();
                  app.$bvModal.hide('rep-downline-notification')
                })
                .catch(err => {
                  app.$notify(err.response.data.message, "error")
                  app.notificationStatus = false
                }).finally(()=>{
              loader.hide()
            });
          } else {
            app.checkErrorFlagStatus();
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.heading-size {
  position: relative;
  top: 8px;
  font-size: 18px;
  margin-bottom: 0;
}
.form-switch .form-check-input {
  width: 4em;
  margin-left: -2.5em;
  padding: 14px;
}
</style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "spin-wrap"
  }, [_c('div', {
    staticClass: "spinner-grow me-1",
    style: _vm.styles,
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Loading...")])]), _c('div', {
    staticClass: "spinner-grow me-1",
    style: _vm.styles,
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Loading...")])]), _c('div', {
    staticClass: "spinner-grow",
    style: _vm.styles,
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Loading...")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import Api from "@/resource/Api"
const state = {
    pendingStats: null,
}

const getters = {
    pendingStats: state => state.pendingStats,
}

const actions = {
    async getPendingStats({ commit }) {
        let url = 'v2/get-pending-manage-menu-count-data';
        await Api.get(url)
            .then((res) => {
                let response = res.data.data
                commit('pendingStats', response)
            }).catch((err) => {
                console.log('err', err)
            })
    },
}

const mutations = {
    pendingStats(state, data) {
        state.pendingStats = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
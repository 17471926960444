<template>
  <div id="app" :class="formGroupClass" class="position-relative">
    <file-pond
      :ref="ref"
      :class="inputClass"
      label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
      :allow-multiple="allowedMultiple"
      :accepted-file-types="computedMimeTypes"
      v-bind:files="inputFile"
      v-on:updatefiles="handleFile"
      credits="false"
      max-file-size="10MB"
      label-file-type-not-allowed="File of invalid type"
      file-validate-type-label-expected-types="Image or document only allowed"
      image-preview-transparency-indicator="grid"
      :max-parallel-uploads="maxParallelUpload"
    />
    <span
      class="d-flex justify-content-center"
      tabindex="0"
      data-toggle="tooltip"
      :title="computedMimeTypeMessage"
      v-if="showInfo"
      style="position: absolute; left: 50%; top: 40%;"
    >
      <img src="../../assets/images/info-circle.svg" class="mt-3 play-icon" />
    </span>
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

export default {
  props: {
    vmodel: {
      required: false,
      default: null,
    },
    error: {
      required: false,
      default: null,
    },
    labelFor: {
      required: false,
    },
    label: {
      required: false,
      type: String,
    },
    allowedMultiple: {
      required: false,
      type: Boolean,
      default: true,
    },
    remove: {
      required: false,
      type: Boolean,
      default: false,
    },
    maxParallelUpload: {
      required: false,
      type: Number,
      default: 4,
    },
    formGroupClass: {
      required: false,
      type: String,
    },
    inputClass: {
      required: false,
      type: String,
      default: "form-control",
    },
    type: {
      type: String,
      required: false,
    },
    info: {
      type: String,
      required: false,
    },
    ref: {
      type: String,
      required: false,
    },
    components: {
      FilePond,
    },
  },
  data: () => {
    return {
      inputFile: [],
      uploadFile: [],
      isError: false,
      showInfo: true,
    };
  },
  computed: {
    computedMimeTypes() {
      return this.type
        ? [this.type]
        : [
            "image/*",
            "application/pdf",
            "application/xlsx",
            "application/xls",
            "application/doc",
            "application/docx",
            "application/csv",
            "text/csv",
            "application/msword",
            "application/vnd.ms-excel",
            "application/txt",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ];
    },
    computedMimeTypeMessage() {
      return this.info ? this.info : "Only jpeg, png, jpg, gif, svg, pdf, xls, xlsx, doc, docx, csv, txt files are allowed";
    },
  },

  methods: {
    handleFile(files) {
      this.$emit("update:error", false);
      this.uploadFile = [];
      this.showInfo = true;
      if (files.length !== 0) {
        this.showInfo = false;
        files.forEach((doc) => {
          if (doc?.fileSize > 10000000) {
            this.$emit("update:error", true);
          } else {
            this.uploadFile.push(doc?.file);
          }
        });
      }
      this.$emit("update:vmodel", this.uploadFile);
    },
    watch: {
      remove() {
        this.uploadFile = [];
      },
    },
  },
};
</script>

<style>
.filepond--credits {
  display: none !important;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "group-info-container form-wrap"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title text-left"
  }, [_vm._v("Group Name")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.name,
      expression: "groupInfo.name"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.groupInfo.name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Group Name"
    },
    domProps: {
      "value": _vm.groupInfo.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.groupInfo, "name", $event.target.value);
      }
    }
  }), !_vm.$v.groupInfo.name.required && _vm.$v.groupInfo.name.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Group Address")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.address,
      expression: "groupInfo.address"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.groupInfo.address.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Group Address"
    },
    domProps: {
      "value": _vm.groupInfo.address
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.groupInfo, "address", $event.target.value);
      }
    }
  }), !_vm.$v.groupInfo.address.required && _vm.$v.groupInfo.address.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Group Requested Start Date")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('date-picker', {
    staticClass: "date-picker-text mt-1",
    class: {
      'date-invalid': _vm.$v.groupInfo.requestedStartDate.$error
    },
    attrs: {
      "aria-describedby": "input-feedback",
      "placeholder": "MM/DD/YYYY",
      "value-type": "format",
      "format": "MM/DD/YYYY"
    },
    model: {
      value: _vm.groupInfo.requestedStartDate,
      callback: function callback($$v) {
        _vm.$set(_vm.groupInfo, "requestedStartDate", $$v);
      },
      expression: "groupInfo.requestedStartDate"
    }
  }), !_vm.$v.groupInfo.address.required && _vm.$v.groupInfo.address.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()], 1), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Group Industry ")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.selectedIndustry,
      expression: "groupInfo.selectedIndustry"
    }],
    staticClass: "custom-select",
    class: {
      'form-invalid': _vm.$v.groupInfo.selectedIndustry.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.groupInfo, "selectedIndustry", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select Group Industry")]), _vm._l(_vm.groupIndustryList, function (industry, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": industry.value
      }
    }, [_vm._v(_vm._s(industry.text))]);
  })], 2), !_vm.$v.groupInfo.selectedIndustry.required && _vm.$v.groupInfo.selectedIndustry.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Group Contact Name")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.contractName,
      expression: "groupInfo.contractName"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.groupInfo.contractName.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Group Contact Name"
    },
    domProps: {
      "value": _vm.groupInfo.contractName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.groupInfo, "contractName", $event.target.value);
      }
    }
  }), !_vm.$v.groupInfo.contractName.required && _vm.$v.groupInfo.contractName.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Group Contact Email")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.contractEmail,
      expression: "groupInfo.contractEmail"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.groupInfo.contractEmail.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Group Contact Email"
    },
    domProps: {
      "value": _vm.groupInfo.contractEmail
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.groupInfo, "contractEmail", $event.target.value);
      }, function ($event) {
        return _vm.$v.groupInfo.contractEmail.$touch();
      }]
    }
  }), !_vm.$v.groupInfo.contractEmail.required && _vm.$v.groupInfo.contractEmail.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e(), _vm.$v.groupInfo.contractEmail.required && _vm.$v.groupInfo.contractEmail.$invalid ? _c('div', {
    staticClass: "form-error  text-left ml-3 mt-2"
  }, [_vm._v(" Enter valid email address ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Group Contact Phone Number")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.contractPhoneNumber,
      expression: "groupInfo.contractPhoneNumber"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.groupInfo.contractPhoneNumber.$error
    },
    attrs: {
      "type": "number",
      "maxlength": "10",
      "oninput": "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
      "placeholder": "Group Contact Phone Number"
    },
    domProps: {
      "value": _vm.groupInfo.contractPhoneNumber
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.groupInfo, "contractPhoneNumber", $event.target.value);
      }, function ($event) {
        return _vm.$v.groupInfo.contractPhoneNumber.$touch();
      }]
    }
  }), !_vm.$v.groupInfo.contractPhoneNumber.required && _vm.$v.groupInfo.contractPhoneNumber.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e(), _vm.$v.groupInfo.contractPhoneNumber.required && _vm.$v.groupInfo.contractPhoneNumber.$invalid ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" The phone number field must be numeric and exactly contain 10 digits ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Group Plans")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('multiselect', {
    staticStyle: {
      "width": "100% !important",
      "margin-top": "6px !important",
      "margin-left": "0 !important"
    },
    attrs: {
      "placeholder": "Select Group Plans",
      "options": _vm.filteredPlanLists.length > 0 ? _vm.filteredPlanLists : _vm.selectedPlans,
      "label": "text",
      "track-by": "value",
      "multiple": true,
      "taggable": true
    },
    on: {
      "change": _vm.updateSelectedGroupPlans,
      "remove": _vm.notifyRemovePlans
    },
    model: {
      value: _vm.selectedPlans,
      callback: function callback($$v) {
        _vm.selectedPlans = $$v;
      },
      expression: "selectedPlans"
    }
  })], 1)])]), _c('div', {
    staticClass: "employee-info-container form-wrap"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('edit-group-member', {
    attrs: {
      "employeeInfoList": _vm.employeeInfoList,
      "show": _vm.showModal,
      "totalEmployees": _vm.totalEmployees,
      "totalDependents": _vm.totalDependents,
      "totalAddedInfo": _vm.totalAddedInfo
    },
    on: {
      "editEmployeeInfo": _vm.editEmployeeInfo,
      "addDependentInfo": _vm.addDependentInfo,
      "editDepInfo": _vm.editDepInfo,
      "deleteDependent": _vm.deleteDependent,
      "deleteEmployeeInfo": _vm.deleteEmployeeInfo
    }
  })], 1), _vm.checkAddEmployeeBtn ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "group-univ-btn text-center add-employee-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.showEmployeeInfo
    }
  }, [_vm._v("+ Add Employee")])]) : _vm._e(), _vm.showAddEmployeeInfo ? _c('div', [_c('div', {
    staticClass: "row employee-field-container"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Primary Full Name")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.primaryFullName,
      expression: "primaryFullName"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.primaryFullName.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Primary Full Name"
    },
    domProps: {
      "value": _vm.primaryFullName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.primaryFullName = $event.target.value;
      }
    }
  }), !_vm.$v.primaryFullName.required && _vm.$v.primaryFullName.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("First Name")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.firstName,
      expression: "firstName"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.firstName.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "First Name"
    },
    domProps: {
      "value": _vm.firstName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.firstName = $event.target.value;
      }
    }
  }), !_vm.$v.firstName.required && _vm.$v.firstName.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Last Name")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.lastName,
      expression: "lastName"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.lastName.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Last Name"
    },
    domProps: {
      "value": _vm.lastName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.lastName = $event.target.value;
      }
    }
  }), !_vm.$v.lastName.required && _vm.$v.lastName.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Plans ")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _vm.selectedPlans.length < 1 ? _c('span', {
    staticClass: "group-plan-msg"
  }, [_vm._v("(Please select the group plan first.)")]) : _vm.editGroupInfo && !_vm.selectedPlanId ? _c('div', {
    staticClass: "group-plan-msg"
  }, [_vm._v("(Please select the plan.)")]) : _vm._e(), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedPlanId,
      expression: "selectedPlanId"
    }],
    staticClass: "custom-select  mt-2",
    class: {
      'form-invalid': _vm.$v.selectedPlanId.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedPlanId = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" Select Plans")]), _vm._l(_vm.selectedPlans, function (plans, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": plans.value
      }
    }, [_vm._v(_vm._s(plans.text))]);
  })], 2), !_vm.$v.selectedPlanId.required && _vm.$v.selectedPlanId.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Plans Type")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedPlanType,
      expression: "selectedPlanType"
    }],
    staticClass: "custom-select  mt-2",
    class: {
      'form-invalid': _vm.$v.selectedPlanType.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedPlanType = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select Plans Type")]), _vm._l(_vm.planTypeList, function (plansType, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": plansType.value
      }
    }, [_vm._v(_vm._s(plansType.text))]);
  })], 2), !_vm.$v.selectedPlanType.required && _vm.$v.selectedPlanType.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Relationship")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedRelationShip,
      expression: "selectedRelationShip"
    }],
    staticClass: "custom-select  mt-2 custom-select-disabled",
    class: {
      'form-invalid': _vm.$v.selectedRelationShip.$error
    },
    attrs: {
      "disabled": ""
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedRelationShip = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select Relationship")]), _vm._l(_vm.relationShipList, function (relationship, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": relationship.value
      }
    }, [_vm._v(_vm._s(relationship.text))]);
  })], 2), !_vm.$v.selectedRelationShip.required && _vm.$v.selectedRelationShip.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title mb-2"
  }, [_vm._v("DOB")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('date-picker', {
    staticClass: "date-picker-text",
    class: {
      'date-invalid': _vm.$v.dob.$error
    },
    attrs: {
      "aria-describedby": "input-feedback",
      "placeholder": "MM/DD/YYYY",
      "value-type": "format",
      "format": "MM/DD/YYYY",
      "disabled-date": _vm.disableDates
    },
    model: {
      value: _vm.dob,
      callback: function callback($$v) {
        _vm.dob = $$v;
      },
      expression: "dob"
    }
  }), !_vm.$v.dob.required && _vm.$v.dob.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()], 1), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Gender")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedGender,
      expression: "selectedGender"
    }],
    staticClass: "custom-select  mt-2",
    class: {
      'form-invalid': _vm.$v.selectedGender.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedGender = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select Gender")]), _vm._l(_vm.genderList, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.value
      }
    }, [_vm._v(_vm._s(gender.text))]);
  })], 2), !_vm.$v.selectedGender.required && _vm.$v.selectedGender.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("State")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.state,
      expression: "state"
    }],
    staticClass: "custom-select  mt-2",
    class: {
      'form-invalid': _vm.$v.state.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.state = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select State")]), _vm._l(_vm.stateLists, function (state, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": state.value
      }
    }, [_vm._v(_vm._s(state.text))]);
  })], 2), !_vm.$v.state.required && _vm.$v.state.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Zip")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.zip,
      expression: "zip"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.zip.$error
    },
    attrs: {
      "type": "number",
      "oninput": "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
      "maxlength": "5",
      "placeholder": "Zip"
    },
    domProps: {
      "value": _vm.zip
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.zip = $event.target.value;
      }, function ($event) {
        return _vm.$v.zip.$touch();
      }]
    }
  }), !_vm.$v.zip.required && _vm.$v.zip.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e(), _vm.$v.zip.required && _vm.$v.zip.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Enter 5 digit zip code. ")]) : _vm._e()])]), _c('div', {
    staticClass: " row  mt-2"
  }, [_c('div', {
    staticClass: "col-xl-6 col-md-6 col-sm-12 "
  }, [_c('div', {
    staticClass: "back-btn"
  }, [_c('button', {
    staticClass: "group-univ-btn text-center grp-quote-btn mt-0",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.routeToVerification
    }
  }, [_vm._v("Back ")])])]), _c('div', {
    staticClass: "col-xl-6 col-md-6 col-sm-12  "
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.checkAddEmployeeBtn ? _c('button', {
    staticClass: "group-univ-btn text-center grp-quote-btn mt-0 ",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.cancelEmployeeInfo
    }
  }, [_vm._v("Cancel ")]) : _vm._e()]), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "group-univ-btn text-center mt-0 grp-quote-btn mb-2",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.addUpdateEmployeeInfo
    }
  }, [_vm._v(_vm._s(_vm.editGroupInfo ? 'Update' : 'Add') + " Employee Info ")])])])])]) : _vm._e(), _c('div', [_vm.totalAddedInfo > 9 ? _c('div', {
    staticClass: "submit-btn-container d-flex justify-content-end"
  }, [_c('div', {}, [_c('button', {
    staticClass: "group-univ-btn text-center",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.submitAllEmployee
    }
  }, [_vm._v(" Submit All Employee Info ")])])]) : _vm._e()]), _c('div', [_c('vue-confirm-dialog')], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
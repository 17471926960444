var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "tableContainer",
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', _vm._l(_vm.columns, function (column) {
    return _c('th', {
      key: column.label
    }, [_vm._v(" " + _vm._s(column.label) + " ")]);
  }), 0)]), _vm._t("tableBody")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="page-wrapper downlinerep-wrapper">
    <div class="container-fluid">
        <slot name="breadcrumb"/>
      <slot name="pageItem"/>
      <div class="page-title">
        <slot name="pageTitle"/>
      </div>
      <div class="page-search-card" v-if="this.$slots.filter">
        <slot name="filter"/>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="page-card downlinedata-card">
            <slot name="topPagination"/>
            <slot name="table"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SimplePage",
};
</script>
<style src="@/assets/css/simple-page.css"></style>
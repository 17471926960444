<template>
  <div :class="formGroupClass">
    <validation-provider
      :name="label.toLowerCase()"
      :rules="rules"
      v-slot="validationContext"
    >
      <label>
        {{ label }}
        <span class="required-indc" v-if="field && field.required"> * </span>
      </label>
      <multiselect
        v-if="field && field.field_name"
        v-model="form[field.field_name]"
        :options="options"
        :custom-label.sync="customLabel"
        :class="
          (apiErrors && apiErrors[field.field_name]) ||
          validationContext.errors[0]
            ? 'invalid-input-block'
            : ''
        "
        @focus.native="autoCompleteHandler"
        @input="inputHandler"
        :placeholder="placeholder"
        :disabled="field && field.readOnly"
        :state="getValidationState(validationContext)"
      >
      </multiselect>
      <error-text
        v-if="
          (apiErrors && apiErrors[field.field_name]) ||
          validationContext.errors[0]
        "
        :errors="
          apiErrors
            ? apiErrors
            : validationContext.errors
            ? validationContext.errors
            : ''
        "
        :field="field.field_name"
      />
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: "RegInputSelect",
  props: {
    apiErrors: {
      required: false,
    },
    form: {
      required: true,
    },
    field: {
      required: true,
    },
    label: {
      required: true,
    },
    placeholder: {
      required: false,
    },
    formGroupClass: {
      required: false,
      default: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
    },
    options: {
      required: true,
    },
    customLabel: {
      required: true,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rules) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    inputHandler() {
      this.$emit("onInput");
    },
    autoCompleteHandler() {
      document
        .querySelector(".multiselect__input")
        .setAttribute("autocomplete", "nope");
      // document
      // .querySelector(".multiselect__input")
      // .setAttribute("autocomplete", "off");
    },
  },
};
</script>
<style>
.invalid-input-block > .multiselect__tags {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.valid-input-block > .multiselect__tags {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.multiselect__tags,
.multiselect__option,
.multiselect__input,
.multiselect__single {
  font-size: 14px !important;
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 0;
  padding-top: 0;
  padding-left: 5px;
}
</style>
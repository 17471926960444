<template>
    <div class="breadcrumb-main">
    <b-breadcrumb>
      <b-breadcrumb-item :items="breadcrumbItems">

        <router-link :to="{ name: 'Dashboard' }"><i class="fas fa-home"></i> Home <b-icon
          icon="house"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"

        ></b-icon></router-link>
      </b-breadcrumb-item>
      <template v-for="item in items">
        <b-breadcrumb-item :key="item.title" :active="item.active">
          <template v-if="item.path && item.path.name && item.path.params">
            <router-link
              :to="{
                name: item.path.name,
                params: item.path.params,
              }"
            >
              {{ item.title }}
            </router-link>
          </template>
          <template v-else-if="item.path && item.path.name">
            <router-link
              :to="{
                name: item.path.name,
              }"
            >
              <span style="color:  #03C3EC !important"> {{ item.title }} </span>
            </router-link>
          </template>
          <template v-else> {{ item.title }} </template>
        </b-breadcrumb-item>
      </template>
    </b-breadcrumb>
  </div>
</template>
<script>
export default {
  name: "Breadcrumb",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
.breadcrumb-main{
    font-size: 14px;
    margin-left: 1rem;
}
.breadcrumb-item .router-link-active{
    color: #03C3EC !important;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_vm.register ? [_c('vue-confirm-dialog', {
    staticClass: "rep-app-confirm-dialog"
  }), _c('div', {
    staticClass: "registration"
  }, [_c('registration-auth-header'), _c('router-view')], 1), _c('div')] : _vm.verifyGroupCensusUser ? [_c('div', [_c('group-census-tab')], 1)] : [_c('vue-confirm-dialog', {
    staticClass: "rep-app-confirm-dialog"
  }), _vm.authHeader ? _c('div', [_c('auth-header')], 1) : _vm.defaultHeader ? _c('div', {
    staticClass: "stickyHeader"
  }, [_c('site-header')], 1) : _vm._e(), _vm.sidebarNav ? _c('div', [_c('sidebar')], 1) : _vm._e(), _c('router-view'), _vm.defaultFooter ? _c('div', [_c('site-footer')], 1) : _vm._e(), _c('app-download-modal', {
    attrs: {
      "appUrl": _vm.appDownloadUrl
    }
  })], _c('div', [_c('down-line-notification')], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "delete",
    attrs: {
      "href": "javascript:void(0);",
      "title": "Delete"
    },
    on: {
      "click": _vm.clicked
    }
  }, [_c('i', {
    staticClass: "fas fa-trash"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
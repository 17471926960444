var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footerPage-wrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-6"
  }, [_c('div', {
    staticClass: "foot-logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/images/corenroll-logo-pb.png"),
      "alt": "Corenroll"
    }
  })])], 1), _vm._m(0), _c('p', {
    staticClass: "copyright-p"
  }, [_vm._v("© Copyright " + _vm._s(this.getCurrentYear()) + ", All Rights Reserved")])]), _vm._m(1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "technical-text"
  }, [_vm._v(" If you have some technical difficulties Go to: "), _c('a', {
    attrs: {
      "href": "http://tickets.purenroll.com/",
      "target": "_blank"
    }
  }, [_vm._v("Issues Dashboard")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-6"
  }, [_c('div', {
    staticClass: "app-details"
  }, [_c('h4', [_vm._v("Download The Rep app")]), _c('div', {
    staticClass: "app-icon"
  }, [_c('a', {
    attrs: {
      "href": "https://apps.apple.com/us/app/corenroll-reps/id1527267392",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/images/ios_icon.png"),
      "alt": "App Store Icon"
    }
  })]), _c('a', {
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.neura.corenroll",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/images/google_icon.png"),
      "alt": "Play Store Icon"
    }
  })])])])]);
}]

export { render, staticRenderFns }
<template>
  <div :class="mainClass">
    <router-link :to="routeLink ? routeLink : ''">
      <div
        :class="
          routeLink.name == this.$route.name
            ? `pending-blocks active-pending-item`
            : 'pending-blocks'
        "
      >
        <div class="pending-icon">
          <img :src="image" alt="" class="img-fluid" />
        </div>
        <div class="pending-content">
          <h3>{{ title }}</h3>
        </div>
        <div :class="!badgefill ? 'pending-badge fill-plain': 'pending-badge'">
          <span>{{ value ? value : 0 }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    imageName: {
      type: String,
      required: true,
    },
    routeLink: {
      required: false,
      default: null,
    },
    mainClass: {
      type: String,
      required: false,
      default: "col pending-home-card mb-4",
    },
    badgefill: {
      type: Number,
      required: false,
      default:1,
    },
  },
  computed: {
    image() {
      return require(`@/assets/images/${this.imageName}`);
    },
  },
};
</script>
<style >
.active-pending-item {
  background: rgb(2 188 119/9%) !important;
}
.pending-badge.fill-plain span {
    background: #3f3f3f;
    color: #fff;
    font-size: 10px;
}
</style>
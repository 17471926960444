<template>
  <div id="defaultNav">
    <div class="logo rep-reg-logo">
      <a href="#"> <img src="@/assets/images/nuera-logo.svg" alt="nuera-logo"></a>
    </div>
  </div>
</template>
<script>
export default {
      name: "AuthHeader",
    }
</script>
<style src="../assets/css/icon.css"></style>

<template>
  <div :class="formGroupClass">
    <validation-provider
      v-if="field && field.field_name"
      :name="labelName ? labelName.toLowerCase() : label.toLowerCase()"
      :rules="rules"
      v-slot="validationContext"
    >
      <label>
        {{ label }}
        <span class="required-indc" v-if="field && field.required"> * </span>
      </label>
      <div class="input-icon">
        <input
          :id="field.field_name"
          :type="type"
          class="reg-field"
          :class="
            (apiErrors && apiErrors[field.field_name]) ||
            validationContext.errors[0]
              ? 'error-input'
              : ''
          "
          v-if="field && field.field_name"
          :placeholder="placeholder"
          :disabled="field && field.readOnly"
          v-model="form[field.field_name]"
          @input="inputHandler($event)"
          :state="getValidationState(validationContext)"
        />
        <slot name="appendIcon" />
      </div>
      <slot name="shortMessage" />
      <error-text
        v-if="
          (apiErrors && apiErrors[field.field_name]) ||
          validationContext.errors[0]
        "
        :errors="
          apiErrors
            ? apiErrors
            : validationContext.errors
            ? validationContext.errors
            : ''
        "
        :field="field.field_name"
      />
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: "RegInputText",
  props: {
    apiErrors: {
      required: false,
    },
    form: {
      required: true,
    },
    field: {
      required: true,
    },
    label: {
      required: true,
    },
    placeholder: {
      required: false,
    },
    formGroupClass: {
      required: false,
      default: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
    },
    type: {
      required: false,
      default: "text",
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      required: false,
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rules) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    inputHandler(event) {
      this.$emit("inputHandler", event);
    },
  },
};
</script>
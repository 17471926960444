var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('registration-auth-header')], 1), _c('div', {
    staticClass: "verify-container"
  }, [_c('div', {
    staticClass: "verify-captcha-form"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "dash-back-btn"
  }, [_c('button', {
    staticClass: "group-univ-btn text-center mt-0",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.routeToDashboard
    }
  }, [_vm._v("Dashboard ")])])]), _c('b-row', [_c('div', {
    staticClass: "col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Name")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.submitterRep.name,
      expression: "submitterRep.name"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.submitterRep.name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.submitterRep.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.submitterRep, "name", $event.target.value);
      }
    }
  }), !_vm.$v.submitterRep.name.required && _vm.$v.submitterRep.name.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Email")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.submitterRep.email,
      expression: "submitterRep.email"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.submitterRep.email.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Email"
    },
    domProps: {
      "value": _vm.submitterRep.email
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.submitterRep, "email", $event.target.value);
      }, function ($event) {
        return _vm.$v.submitterRep.email.$touch();
      }]
    }
  }), !_vm.$v.submitterRep.email.required && _vm.$v.submitterRep.email.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e(), _vm.$v.submitterRep.email.required && _vm.$v.submitterRep.email.$invalid ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Enter valid email address ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Phone Number")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.submitterRep.phoneNumber,
      expression: "submitterRep.phoneNumber"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.submitterRep.phoneNumber.$error
    },
    attrs: {
      "type": "number",
      "oninput": "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
      "maxlength": "10",
      "placeholder": "Phone Number"
    },
    domProps: {
      "value": _vm.submitterRep.phoneNumber
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.submitterRep, "phoneNumber", $event.target.value);
      }, function ($event) {
        return _vm.$v.submitterRep.phoneNumber.$touch();
      }]
    }
  }), !_vm.$v.submitterRep.phoneNumber.required && _vm.$v.submitterRep.phoneNumber.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e(), _vm.$v.submitterRep.phoneNumber.required && _vm.$v.submitterRep.phoneNumber.$invalid ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" The phone number field must be numeric and exactly contain 10 digits ")]) : _vm._e()]), !_vm.isPlatformRedirection ? _c('div', {
    staticClass: "col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Platform")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.platform,
      expression: "platform"
    }],
    staticClass: "custom-select",
    class: {
      'form-invalid': _vm.$v.platform.$error
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.platform = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.setPlatform]
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v("Choose Platform")]), _vm._l(_vm.platformList, function (platform, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": platform.value
      }
    }, [_vm._v(_vm._s(platform.text))]);
  })], 2), !_vm.$v.platform.required && _vm.$v.platform.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "col-xxl-8 col-lg-8 col-md-8  col-sm-12 mb-3 w-100"
  }, [_c('div', [_c('vue-recaptcha', {
    staticClass: "captcha-container",
    attrs: {
      "sitekey": "6LeeIsIbAAAAADd9Uo6R_VCnruxwQykkZDiDjETU",
      "loadRecaptchaScript": true
    },
    on: {
      "verify": _vm.markRecaptchaAsVerified
    }
  })], 1), _vm.pleaseTickRecaptchaMessage ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" " + _vm._s(_vm.pleaseTickRecaptchaMessage) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "submit-btn-container d-flex justify-content-start"
  }, [_c('div', {}, [_c('button', {
    staticClass: "group-univ-btn text-center",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.submitVerifyForm
    }
  }, [_vm._v("Submit ")])])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title mb-3"
  }, [_c('h1', {
    staticClass: "group-census-label"
  }, [_vm._v("Group Quote - Submitter Info")])]);
}]

export { render, staticRenderFns }
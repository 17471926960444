<template>
    <div id="defaultNav">
      <div class="logo rep-reg-logo">
        <a href="#">
          <img src="../assets/images/nuera-logo.svg" id="registration_logo" alt="neura-logo"/>
        </a>
        <!-- <div class="appIcons">
          <div class="iconApps-list">
            <b-dropdown dropdown class="dashboard-icons">
              <template #button-content>
                <div class="aaplistIcon">
                  <img
                    src="../assets/images/app-icon.svg"
                    id="dash-icon"
                    class=""
                  />
                </div>
              </template>
              <b-dropdown-item
                class="dropdown-icon-item"
                href="javascript:void(0)"
              >
                <h3 class="mt-2">Dashboard Login</h3>
                <div class="row">
                  <a
                    href="https://qa-reps.purenroll.com"
                    class="col-4 col-md-4 col-sm-4 dashIcon-block"
                    target="_blank"
                  >
                    <img
                      src="../assets/images/dashboard-rep.svg"
                      alt=""
                      class="RepLogin"
                    />
                    <span class="d-block text-center">Rep Login</span>
                  </a>
  
                  <a
                    href="https://qa-dashboard.purenroll.com/member-dashboard"
                    class="col-4 col-md-4 col-sm-4 dashIcon-block"
                    target="_blank"
                  >
                    <img
                      src="../assets/images/dashboard-member.svg"
                      alt=""
                      class="memberLogin"
                    />
                    <span class="d-block text-center">Member login</span>
                  </a>
                  <a
                    href="https://qa-group.purenroll.com "
                    class="col-4 col-md-4 col-sm-4 dashIcon-block"
                    target="_blank"
                  >
                    <img
                      src="../assets/images/dashboard-group.svg"
                      alt=""
                      class="GroupLogin"
                    />
                    <span class="d-block text-center">Group Login</span>
                  </a>
                </div>
                <hr />
                <h3>Registrations</h3>
                <div class="row">
                  <a
                    href="https://qa-reps.purenroll.com/registration"
                    class="col-4 col-md-4 col-sm-4 dashIcon-block"
                    target="_blank"
                  >
                    <img src="../assets/images/rep-registration.svg" alt="" />
                    <span class="d-block text-center"
                      >Rep<br />
                      Registration</span
                    >
                  </a>
  
                  <a
                    href="https://qa-dashboard.purenroll.com/sign-up"
                    class="col-4 col-md-4 col-sm-4 dashIcon-block"
                    target="_blank"
                  >
                    <img src="../assets/images/member-registration.svg" alt="" />
                    <span class="d-block text-center"
                      >Member<br />Registration</span
                    >
                  </a>
                  <a
                    href="https://qa-group.purenroll.com/registration"
                    class="col-4 col-md-4 col-sm-4 dashIcon-block"
                    target="_blank"
                  >
                    <img src="../assets/images/group-registration.svg" alt="" />
                    <span class="d-block text-center"
                      >Group<br />Registration</span
                    >
                  </a>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div> -->
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "RegistrationAuthHeader",
  };
  </script>
  <style src="../assets/css/icon.css"></style>
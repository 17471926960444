var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "downline-container"
  }, [_c('b-modal', {
    attrs: {
      "hide-footer": true,
      "id": "rep-downline-notification",
      "centered": "",
      "size": "sm"
    },
    on: {
      "shown": function shown($event) {
        return _vm.getNotificationStatus();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "popUp-header text-center w-100",
          staticStyle: {
            "border-bottom": "0px !important"
          }
        }, [_c('h4', {
          staticClass: "heading-size"
        }, [_vm._v(" " + _vm._s(_vm.notificationStatus ? 'Disable' : 'Allow') + " Downline Notification")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.hide('rep-downline-notification');
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "form-check form-switch"
  }, [_c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.notificationStatus,
      expression: "notificationStatus"
    }],
    staticClass: "form-check-input toggle-custom-size",
    attrs: {
      "type": "checkbox",
      "role": "switch",
      "id": "flexSwitchCheckDefault"
    },
    domProps: {
      "checked": Array.isArray(_vm.notificationStatus) ? _vm._i(_vm.notificationStatus, null) > -1 : _vm.notificationStatus
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.notificationStatus,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.notificationStatus = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.notificationStatus = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.notificationStatus = $$c;
        }
      }, _vm.enableDownlineNotification]
    }
  })])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
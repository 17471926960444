<template>
  <div class="col-xxl-12 mb-2">
    <div class="dashpending-wrap">
      <div class="dashpending-card-title mt-3" v-if="title">
        <h2>Pending Items</h2>
      </div>
      <!-- <carousel :dots="false"  :nav="false"  :margin="20" :responsive="{320:{items:2},576:{items:3},764:{items:3},990:{items:3},1140:{items:4}, 1920:{items:7}}" >
        <template slot="prev"><span class="pendingArrow prevBtn"><i class="fas fa-arrow-left"></i></span></template>
        <template slot="next"><span class="pendingArrow nextBtn"><i class="fas fa-arrow-right"></i></span></template> -->
        <div class="row">
          <div class="col-md col-card col-xxl mb-3 ">  
        <pending-block
          image-name="rep-reg.svg"
          title="Edit Enrollments within 24hrs"
          :value="pendingStats.enrollments_24hrs_count"
          :routeLink="{ name: 'Enrollments24hr' }"
        />
        </div>
          <div class="col-md col-card col-xxl mb-3 ">
          <pending-block
            image-name="signature.svg"
            title="Pending Customer Signature"
            :value="pendingStats.pending_document_count"
            :routeLink="{ name: 'PendingSignatureList' }"
          />
        </div>
          <div class="col-md col-card col-xxl mb-3 ">
          <pending-block
            image-name="reps.svg"
            :badgefill="0"
            title="Broker Registration in Progress "
            :value="pendingStats.pending_downline_count"
            :routeLink="{
              name: 'PendingDowlineRepList',
            }"
          />
        </div>
        

          <div class="col-md col-card col-xxl mb-3 ">
          <pending-block
            image-name="old.svg"
            :badgefill="0"
            title="Members turning 65+ "
            :value="pendingStats.pending_member_turing_65_count"
            :routeLink="{ name: 'PendingMember65List' }"
          />
        </div>
        <div class="col-md col-card col-xxl mb-3 ">
          <pending-block
            image-name="child.svg"
            :badgefill="0"
            title="Dependents turning 26 "
            :value="pendingStats.pending_spouse_aging_off_count"
            :routeLink="{ name: 'PendingDependentsChildList' }"
          />
        </div>
          <div class="col-md col-card col-xxl mb-3 ">
          <pending-block
            image-name="enrollments.svg"
            title="Pending Enrollment in Process "
            :value="pendingStats.pending_enrollment_count"
            :routeLink="{ name: 'PendingEnrollmentList' }"
          />
        </div>
        <div class="col-md col-card col-xxl mb-3 ">
          <pending-block
            image-name="group.svg"
            :badgefill="0"
            title="Group Registration in progress "
            :value="pendingStats.pending_group_registration_count"
            :routeLink="{ name: 'PendingGroupRegistrationList' }"
          />
        </div>
          <div class="col-md col-card col-xxl mb-3 ">
          <pending-block
            image-name="rep-reg.svg"
            title="Downline Pending Activation"
            :value="pendingStats.pending_rep_administrator_count"
            :routeLink="{ name: 'PendingRepRegistrationList' }"
          />
        </div>
        <div class="col-md col-card col-xxl mb-3 ">
          <pending-block
            image-name="spouse.svg"
            :badgefill="0"
            title="Deps turning 65"
            :value="pendingStats.pending_spouse_aging_off_count"
            :routeLink="{ name: 'PendingDependentsSpouseList' }"
          />
        </div> 
        <div class="col-md col-card col-xxl mb-3 last-block"></div>
      </div>
      <!-- </carousel> -->
    </div>
  </div>
</template>
<script>
// import Api from "@/resource/Api";
// import carousel from "vue-owl-carousel";
import { mapGetters } from "vuex";
import PendingBlock from "@/components/ui/PendingBlock.vue";
export default {
  components: { PendingBlock, 
    // carousel 
  },
  name: "PendingItems",
  props: {
    title: {
      required: false,
      default: true,
    },
  },
  data: () => ({
    pendingScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "600",
      },
      scrollPanel: {
        maxHeight: 400,
      },
      bar: {
        background: "#ddd",
      },
    },
    // stats: null,
  }),
  computed: {
    ...mapGetters({
      pendingStats: "pendingStats",
    }),
    class() {
      return true;
    },
  },
};
</script>
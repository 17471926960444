<template>
  <a
    href="javascript:void(0);"
    class="delete"
    v-b-tooltip.hover
    title="Delete"
    @click="clicked"
  >
    <i class="fas fa-trash" />
  </a>
</template>
<script>
export default {
  name: "DeleteButton",
  methods: {
    clicked() {
      this.$emit("onClicked");
    },
  },
};
</script>
<style scoped>
a,
a:hover {
  text-decoration: none;
  color: #000;
}

.delete {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.action_icon {
  background: transparent
    linear-gradient(180deg, #00ccff 0%, #1597b8 49%, #0567bc 100%);
  border-radius: 27px;
  padding: 7px;
  width: 20px;
  height: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
</style>

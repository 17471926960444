
import Vue from 'vue'
import EyeButton from "@/components/ui/EyeButton"
import PasswordStrengthBar from "@/components/ui/PasswordStrengthBar";
import InputText from "@/components/form/InputText";
import InputImage from "@/components/form/InputImage";
import InputTextarea from "@/components/form/InputTextarea";
import InputSelect from "@/components/form/InputSelect";
import ToggleElement from "@/components/ui/ToggleElement"
import InputFile from "@/components/form/InputFile";
import InputFileMultiple from "@/components/form/InputFileMultiple";
import MultiSelectableInput from "@/components/form/MultiSelectable";
import OtpInput from "@bachdgvn/vue-otp-input";
import Breadcrumb from "@/components/ui/Breadcrumb.vue"

/* common components */
Vue.component('input-text', InputText)
Vue.component('input-textarea', InputTextarea)
Vue.component('input-select', InputSelect)
Vue.component('input-file', InputFile)
Vue.component('input-file-multiple', InputFileMultiple)
Vue.component('input-image', InputImage)
Vue.component('eye-button', EyeButton)
Vue.component('password-strength-bar', PasswordStrengthBar)
Vue.component('breadcrumb', Breadcrumb)



import Pagination from "@/components/ui/Pagination";
import SimplePage from '@/components/ui/SimplePage.vue';
import SimpleTable from '@/components/ui/SimpleTable.vue';
Vue.component("v-otp-input", OtpInput);

Vue.component('pagination', Pagination)
Vue.component('toggle-element', ToggleElement)
Vue.component('simple-page', SimplePage)
Vue.component('simple-table', SimpleTable)


import NoContent from "@/components/ui/NoContent"
Vue.component('no-content', NoContent)
import CustomButton from "@/components/ui/CustomButton"
Vue.component('custom-button', CustomButton)

import VueConfirmDialog from 'vue-confirm-dialog';
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

import CloseModalIcon from "@/components/ui/CloseModalIcon"
Vue.component('close-modal-icon', CloseModalIcon)

import DeleteButton from "@/components/ui/DeleteButton"
Vue.component('delete-button', DeleteButton)

import CustomRouteButton from "@/components/ui/CustomRouteButton"
Vue.component('custom-route-button', CustomRouteButton)

import InputMultiSelectMultiple from "@/components/form/InputMultiSelectMultiple";
import InputMultiSelect from "@/components/form/InputMultiSelect";
import InputMultiSelectSimple from "@/components/form/InputMultiSelectSimple";
import FilePond from "@/components/form/FilePond";
Vue.component('input-multi-select-multiple', InputMultiSelectMultiple)
Vue.component('input-multi-select', InputMultiSelect)
Vue.component('input-multi-select-simple', InputMultiSelectSimple)
Vue.component('multi-selectable-input', MultiSelectableInput)
Vue.component('file-upload', FilePond)


import DownloadButton from "@/components/ui/DownloadButton"
Vue.component('download-button', DownloadButton)

import ValidIcon from "@/components/ui/ValidIcon"
Vue.component('valid-icon', ValidIcon)


import PendingItems from "@/views/Dashboard/partials/PendingItems.vue";
Vue.component('pending-items', PendingItems)

import ErrorText from "@/components/ui/ErrorText"
Vue.component('error-text', ErrorText);

import RegInputText from '@/views/Registration/utils/RegInputText.vue';
Vue.component('reg-input-text',RegInputText)

import RegInputSelect from '@/views/Registration/utils/RegInputSelect.vue';
Vue.component('reg-input-select',RegInputSelect)

import PageResult from '@/components/ui/PageResult'
Vue.component('page-result',PageResult)

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Quill from'quill'
import { quillEditor } from "vue-quill-editor";
Vue.component('quill-editor',quillEditor)
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize)//Add

// BEGIN allow image alignment styles
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
  ];
  
  const BaseImageFormat = Quill.import('formats/image');
  class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
      return ImageFormatAttributesList.reduce(function(formats, attribute) {
        if (domNode.hasAttribute(attribute)) {
          formats[attribute] = domNode.getAttribute(attribute);
        }
        return formats;
      }, {});
    }
    format(name, value) {
      if (ImageFormatAttributesList.indexOf(name) > -1) {
        if (value) {
          this.domNode.setAttribute(name, value);
        } else {
          this.domNode.removeAttribute(name);
        }
      } else {
        super.format(name, value);
      }
    }
  }
  
  Quill.register(ImageFormat, true);

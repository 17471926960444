var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.errors && _vm.errorField ? _c('div', {
    staticClass: "text-danger"
  }, [_c('small', [_vm._v(_vm._s(_vm.errorField[0]))])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_vm.field && _vm.field.field_name ? _c('validation-provider', {
    attrs: {
      "name": _vm.labelName ? _vm.labelName.toLowerCase() : _vm.label.toLowerCase(),
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.field && _vm.field.required ? _c('span', {
          staticClass: "required-indc"
        }, [_vm._v(" * ")]) : _vm._e()]), _c('div', {
          staticClass: "input-icon"
        }, [_vm.type === 'checkbox' && _vm.field && _vm.field.field_name ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form[_vm.field.field_name],
            expression: "form[field.field_name]"
          }],
          staticClass: "reg-field",
          class: _vm.apiErrors && _vm.apiErrors[_vm.field.field_name] || validationContext.errors[0] ? 'error-input' : '',
          attrs: {
            "id": _vm.field.field_name,
            "placeholder": _vm.placeholder,
            "disabled": _vm.field && _vm.field.readOnly,
            "state": _vm.getValidationState(validationContext),
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.form[_vm.field.field_name]) ? _vm._i(_vm.form[_vm.field.field_name], null) > -1 : _vm.form[_vm.field.field_name]
          },
          on: {
            "input": function input($event) {
              return _vm.inputHandler($event);
            },
            "change": function change($event) {
              var $$a = _vm.form[_vm.field.field_name],
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = null,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.form, _vm.field.field_name, $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.form, _vm.field.field_name, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.form, _vm.field.field_name, $$c);
              }
            }
          }
        }) : _vm.type === 'radio' && _vm.field && _vm.field.field_name ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form[_vm.field.field_name],
            expression: "form[field.field_name]"
          }],
          staticClass: "reg-field",
          class: _vm.apiErrors && _vm.apiErrors[_vm.field.field_name] || validationContext.errors[0] ? 'error-input' : '',
          attrs: {
            "id": _vm.field.field_name,
            "placeholder": _vm.placeholder,
            "disabled": _vm.field && _vm.field.readOnly,
            "state": _vm.getValidationState(validationContext),
            "type": "radio"
          },
          domProps: {
            "checked": _vm._q(_vm.form[_vm.field.field_name], null)
          },
          on: {
            "input": function input($event) {
              return _vm.inputHandler($event);
            },
            "change": function change($event) {
              return _vm.$set(_vm.form, _vm.field.field_name, null);
            }
          }
        }) : _vm.field && _vm.field.field_name ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form[_vm.field.field_name],
            expression: "form[field.field_name]"
          }],
          staticClass: "reg-field",
          class: _vm.apiErrors && _vm.apiErrors[_vm.field.field_name] || validationContext.errors[0] ? 'error-input' : '',
          attrs: {
            "id": _vm.field.field_name,
            "placeholder": _vm.placeholder,
            "disabled": _vm.field && _vm.field.readOnly,
            "state": _vm.getValidationState(validationContext),
            "type": _vm.type
          },
          domProps: {
            "value": _vm.form[_vm.field.field_name]
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }

              _vm.$set(_vm.form, _vm.field.field_name, $event.target.value);
            }, function ($event) {
              return _vm.inputHandler($event);
            }]
          }
        }) : _vm._e(), _vm._t("appendIcon")], 2), _vm._t("shortMessage"), _vm.apiErrors && _vm.apiErrors[_vm.field.field_name] || validationContext.errors[0] ? _c('error-text', {
          attrs: {
            "errors": _vm.apiErrors ? _vm.apiErrors : validationContext.errors ? validationContext.errors : '',
            "field": _vm.field.field_name
          }
        }) : _vm._e()];
      }
    }], null, true)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
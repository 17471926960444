var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.label.toLowerCase(),
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.field && _vm.field.required ? _c('span', {
          staticClass: "required-indc"
        }, [_vm._v(" * ")]) : _vm._e()]), _vm.field && _vm.field.field_name ? _c('multiselect', {
          class: _vm.apiErrors && _vm.apiErrors[_vm.field.field_name] || validationContext.errors[0] ? 'invalid-input-block' : '',
          attrs: {
            "options": _vm.options,
            "custom-label": _vm.customLabel,
            "placeholder": _vm.placeholder,
            "disabled": _vm.field && _vm.field.readOnly,
            "state": _vm.getValidationState(validationContext)
          },
          on: {
            "update:customLabel": function updateCustomLabel($event) {
              _vm.customLabel = $event;
            },
            "update:custom-label": function updateCustomLabel($event) {
              _vm.customLabel = $event;
            },
            "input": _vm.inputHandler
          },
          nativeOn: {
            "focus": function focus($event) {
              return _vm.autoCompleteHandler.apply(null, arguments);
            }
          },
          model: {
            value: _vm.form[_vm.field.field_name],
            callback: function callback($$v) {
              _vm.$set(_vm.form, _vm.field.field_name, $$v);
            },
            expression: "form[field.field_name]"
          }
        }) : _vm._e(), _vm.apiErrors && _vm.apiErrors[_vm.field.field_name] || validationContext.errors[0] ? _c('error-text', {
          attrs: {
            "errors": _vm.apiErrors ? _vm.apiErrors : validationContext.errors ? validationContext.errors : '',
            "field": _vm.field.field_name
          }
        }) : _vm._e()];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="edit-group-container" v-if="employeeInfoList.length > 0">
    <div class="invalid-error-msg mb-2" v-if="totalAddedInfo < 10">
     At least 10 information is required as a total including employee and dependent inorder to proceed.
    </div>
    <div class="valid-msg" v-else>
     Total added information is {{totalAddedInfo}} which is valid you can proceed inorder to submit information.
    </div>
    <div class="text-start emp-dep-count-container">
      <span class="m-3">Total Added Employee: {{totalEmployees}}</span>
      <span class="m-3">Total Added Dependents: {{totalDependents}}</span>
      <span class="m-3">Total Added (Employee + Dependents): {{totalAddedInfo}}</span>
    </div>
    <div class="d-flex justify-content-end"></div>
    <div class="row g-0">
      <div
          class="col-xl-4"
          v-for="(employee, empIndex) in employeeInfoList"
          :key="empIndex"
      >
        <div
            class="edit-member-container"
        >
        <div class="employee-container">
          <div class="d-flex justify-content-between align-items-center">
            <div>
            <span class=""
            >Employee {{ empIndex + 1 }}: {{ employee.first_name }}
              {{ employee.last_name }}</span
            >
            </div>
            <div class="mt-1 text-end">
              <i
                  class="fas fa-users fa-icon-edit text-capitalize add-employee-icon fa-icon-container"
                  @click="openModal(empIndex)"
                  v-b-tooltip.hover.top
                  v-if="checkEmployeeAddBtn(employee)"
                  :title="`Add/Edit Dependent`"
              >
              </i>
              <i
                  class="fas fa-edit mr-1 fa-icon-container fa-icon-edit text-capitalize"
                  v-b-tooltip.hover.top
                  :title="`Edit Employee ${empIndex + 1}: ${employee.first_name} ${
                employee.last_name
              }`"
                  @click="editEmployeeInfo(empIndex)"
              ></i>
              <i
                  class="fas fa-trash-alt mr-1 fa-icon-container text-capitalize trash-icon-btn"
                  v-b-tooltip.hover.top
                  :title="`Delete Employee ${empIndex + 1}: ${employee.first_name} ${
                employee.last_name
              }`"
                  @click="deleteEmployeeInfo(empIndex, employee)"
              ></i>
            </div>
          </div>
          <div class="d-flex justify-content-start plan-type-info">
            Plan Type: {{checkPlanTypeInfo(employee.plan_type)}}
          </div>
        </div>
          <div v-if="employee.dependent_info && employee.dependent_info.length > 0">
            <div class="dep-info-overlay">
              <div class="d-flex justify-content-between align-items-center dep-info-container employee-container" v-for="(dep, depIndex) in employee.dependent_info" :key="depIndex">
                <div>
                  <span class="">Dependent {{depIndex + 1}}: {{dep.first_name}} {{dep.last_name}}
                    <span v-if="dep.relationship == 'S'" class="edit-dep-relation">(Spouse)</span>
                    <span v-else-if="dep.relationship == 'C'" class="edit-dep-relation">(Child)</span>
                  </span>
                </div>
                <div class="mt-1 text-end">
                  <i
                      class="fas fa-edit mr-1 fa-icon-container fa-icon-edit text-capitalize"
                      v-b-tooltip.hover.top
                      @click="editDependent(dep, depIndex, empIndex)"
                      :title="`Edit Dependent:${depIndex + 1}`"
                  ></i>
                  <i
                      class="fas fa-trash-alt mr-1 fa-icon-container text-capitalize trash-icon-btn"
                      v-b-tooltip.hover.top
                      @click="deleteDependent(empIndex, depIndex, employee)"
                      :title="`Delete Dependent: ${depIndex + 1}`"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div v-if="(employee.plan_type !== 'IO' && validateDepInfo(employee))" class="employee-container">
            <div :class="'dep-valid-alert-msg'"> {{validateDepInfo(employee)}}</div>
          </div>
          <div v-if="(employee.plan == null || employee.plan == '')">
            <div :class="'dep-valid-alert-msg'">The Selected group plan has been removed. Please choose a different available plan to edit employee information.</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <add-dependent :show="showModal"
                     :index="employeeIndex"
                     @addDependentInfo="addDependentInfo"
                     @checkAddDepBtn="checkAddDepBtn"
                     :employeeInfoList="employeeInfoList"
                     @closeModal="closeModal" />
    </div>
    <div>
      <edit-dep-info-modal  :editDepIndex="editDependentIndex"
                            :empIndex="employeeIndex"
                            @editDepInfo="editDepInfo"
                            :editDepInfo="editDependentInfo"/>
    </div>
  </div>

</template>

<script>
import AddDependent from "../modal/AddDependentModal.vue";
import EditDepInfoModal from "../modal/EditDepInfoModal";
export default {
  name: "EditGroupMember",
  components: { AddDependent, EditDepInfoModal },
  props: {
    employeeInfoList: {
      type: Array
    },
    show: {
      type: Boolean
    },
    totalEmployees: {
      type: Number
    },
    totalDependents: {
      type: Number
    },
    totalAddedInfo: {
      type: Number
    }
  },
  data() {
    return {
      showModal: false,
      employeeIndex: null,
      editDependentIndex: null,
      editDependentInfo: {},
      empGroupInfo: [],
      isEmployeeDepInvalid: true
    }
  },
  methods: {
    openModal(index) {
      let app = this;
      app.showModal = true;
      app.employeeIndex = index;
    },
    editDependent (depObj, depIndex, empIndex) {
      let app = this
      app.editDependentInfo = depObj
      app.editDependentIndex = depIndex
      app.employeeIndex  = empIndex
      app.$bvModal.show('edit-dependent')
    },
    checkEmployeeAddBtn (employee) {
      if (employee.plan_type == 'IO') {
        return false
      } else if (employee.plan_type == 'IS' && employee.dependent_info.length > 0) {
        return  false
      } else {
        return  true
      }
    },
    checkAddDepBtn (empIndex) {
     let app = this
     let employee = {}
     employee = app.editDependentInfo[empIndex]
     app.checkEmployeeAddBtn(employee)
    },
    validateDepInfo(employee) {
      let app = this
      const { plan_type, dependent_info } = employee;
      if (plan_type === 'IF') {
        const hasSpouse = dependent_info.some(dep => dep.relationship === 'S')
        const hasChild = dependent_info.some(dep => dep.relationship === 'C')
        if (!hasSpouse || !hasChild) {
          app.isEmployeeDepInvalid = true
          return `At least 1 spouse and 1 child information is required.`
        }
      }
      if (plan_type === 'IC') {
        const hasChild = dependent_info.some(dep => dep.relationship === 'C')
        if (!hasChild) {
          app.isEmployeeDepInvalid = true
          return `At least 1 child information is required.`
        }
      }
      if (plan_type === 'IS') {
        const hasSpouse = dependent_info.some(dep => dep.relationship === 'S')
        if (!hasSpouse) {
          app.isEmployeeDepInvalid = true
          return `Spouse information is required.`
        }
      }
      app.isEmployeeDepInvalid = false
    },
    checkPlanTypeInfo (planType) {
      if (planType == 'IF') {
        return 'Family'
      } else if (planType == 'IC') {
        return 'Member + Children'
      } else if (planType == 'IS') {
        return 'Member + Spouse'
      } else {
        return 'Single'
      }
    },
    addDependentInfo (depObj) {
      let app = this
      app.$emit('addDependentInfo', depObj)
      app.showModal = false;
    },
    editDepInfo (depObj) {
      let app = this
      app.$emit('editDepInfo', depObj)
    },
    closeModal() {
      let app = this
      app.showModal = false;
    },
    editEmployeeInfo(index) {
      let app = this;
      app.$emit("editEmployeeInfo", index);
    },
    deleteEmployeeInfo(index) {
      let app = this;
      app.$emit("deleteEmployeeInfo", index);
    },
    deleteDependent (empIndex, depIndex, employee) {
      let app = this
      let Obj = {
        'depIndex': depIndex,
        'employeeIndex': empIndex
      }
      app.validateDepInfo(employee);
      app.$emit("deleteDependent", Obj);
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        let app = this
        let lastEmpIndex = app.employeeInfoList.length - 1
        app.showModal = newVal;
        app.employeeIndex = lastEmpIndex;
      }
    }
  },
};
</script>

<style scoped>
</style>
<template>
  <div>
    <div class="group-info-container form-wrap">
      <div class="row">
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title text-left">Group Name</label>
          <span class="required-asterisk">*</span>
          <input
              type="text"
              v-model="groupInfo.name"
              class="input-field"
              :class="{'form-invalid': $v.groupInfo.name.$error}"
              placeholder="Group Name"
          />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.groupInfo.name.required && $v.groupInfo.name.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Group Address</label>
          <span class="required-asterisk">*</span>
          <input
              type="text"
              v-model="groupInfo.address"
              class="input-field"
              :class="{'form-invalid': $v.groupInfo.address.$error}"
              placeholder="Group Address"
          />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.groupInfo.address.required && $v.groupInfo.address.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Group Requested Start Date</label>
          <span class="required-asterisk">*</span>
          <date-picker
              aria-describedby="input-feedback"
              placeholder="MM/DD/YYYY"
              class="date-picker-text mt-1"
              :class="{'date-invalid': $v.groupInfo.requestedStartDate.$error}"
              v-model="groupInfo.requestedStartDate"
              value-type="format"
              format="MM/DD/YYYY"
          >
          </date-picker>
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.groupInfo.address.required && $v.groupInfo.address.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Group Industry </label>
          <span class="required-asterisk">*</span>
          <select v-model="groupInfo.selectedIndustry"
                  :class="{'form-invalid': $v.groupInfo.selectedIndustry.$error}"
                  class="custom-select">
            <option value='' disabled> Select Group Industry</option>
            <option  v-for="(industry, index) in groupIndustryList"
                     :value="industry.value"
                     :key="index">{{industry.text}}</option>
          </select>
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.groupInfo.selectedIndustry.required && $v.groupInfo.selectedIndustry.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Group Contact Name</label>
          <span class="required-asterisk">*</span>
          <input
              type="text"
              v-model="groupInfo.contractName"
              class="input-field"
              :class="{'form-invalid': $v.groupInfo.contractName.$error}"
              placeholder="Group Contact Name"
          />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.groupInfo.contractName.required && $v.groupInfo.contractName.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Group Contact Email</label>
          <span class="required-asterisk">*</span>
          <input
              type="text"
              v-model="groupInfo.contractEmail"
              @input="$v.groupInfo.contractEmail.$touch();"
              class="input-field"
              :class="{'form-invalid': $v.groupInfo.contractEmail.$error}"
              placeholder="Group Contact Email"
          />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.groupInfo.contractEmail.required && $v.groupInfo.contractEmail.$error">
            Field is required
          </div>
          <div class="form-error  text-left ml-3 mt-2" v-if="$v.groupInfo.contractEmail.required && $v.groupInfo.contractEmail.$invalid">
            Enter valid email address
          </div>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Group Contact Phone Number</label>
          <span class="required-asterisk">*</span>
          <input
              type="number"
              maxlength="10"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              v-model="groupInfo.contractPhoneNumber"
              @input="$v.groupInfo.contractPhoneNumber.$touch()"
              class="input-field"
              :class="{'form-invalid': $v.groupInfo.contractPhoneNumber.$error}"
              placeholder="Group Contact Phone Number"
          />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.groupInfo.contractPhoneNumber.required && $v.groupInfo.contractPhoneNumber.$error">
            Field is required
          </div>
          <div class="form-error text-left ml-3 mt-2" v-if="$v.groupInfo.contractPhoneNumber.required && $v.groupInfo.contractPhoneNumber.$invalid">
            The phone number field must be numeric and exactly contain 10 digits
          </div>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Group Plans</label>
          <span class="required-asterisk">*</span>
          <multiselect
              style="width: 100% !important; margin-top: 6px !important; margin-left: 0 !important;"
              v-model="selectedPlans"
              placeholder="Select Group Plans"
              :options="filteredPlanLists.length > 0 ? filteredPlanLists: selectedPlans"
              label="text"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @change="updateSelectedGroupPlans"
              @remove="notifyRemovePlans"
          ></multiselect>
        </div>
      </div>
    </div>
    <div class="employee-info-container form-wrap">
      <div class="text-center">
        <edit-group-member :employeeInfoList="employeeInfoList"
                           :show="showModal"
                           :totalEmployees="totalEmployees"
                           :totalDependents="totalDependents"
                           :totalAddedInfo="totalAddedInfo"
                           @editEmployeeInfo="editEmployeeInfo"
                           @addDependentInfo="addDependentInfo"
                           @editDepInfo="editDepInfo"
                           @deleteDependent="deleteDependent"
                           @deleteEmployeeInfo="deleteEmployeeInfo"/>
      </div>
      <div v-if="checkAddEmployeeBtn" class="d-flex justify-content-end">
        <button type="submit" @click="showEmployeeInfo"
                class="group-univ-btn text-center add-employee-btn">+ Add Employee</button>
      </div>
      <div v-if="showAddEmployeeInfo">
        <div class="row employee-field-container">
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">Primary Full Name</label>
            <span class="required-asterisk">*</span>
            <input
                type="text"
                v-model="primaryFullName"
                :class="{'form-invalid': $v.primaryFullName.$error}"
                class="input-field"
                placeholder="Primary Full Name"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.primaryFullName.required && $v.primaryFullName.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">First Name</label>
            <span class="required-asterisk">*</span>
            <input
                type="text"
                v-model="firstName"
                :class="{'form-invalid': $v.firstName.$error}"
                class="input-field"
                placeholder="First Name"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.firstName.required && $v.firstName.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">Last Name</label>
            <span class="required-asterisk">*</span>
            <input
                type="text"
                v-model="lastName"
                :class="{'form-invalid': $v.lastName.$error}"
                class="input-field"
                placeholder="Last Name"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.lastName.required && $v.lastName.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">Plans </label>
            <span class="required-asterisk">*</span>
            <span class="group-plan-msg" v-if="selectedPlans.length < 1">(Please select the group plan first.)</span>
            <div class="group-plan-msg" v-else-if="editGroupInfo && !selectedPlanId">(Please select the plan.)</div>
            <select v-model="selectedPlanId"
                    :class="{'form-invalid': $v.selectedPlanId.$error}"
                    class="custom-select  mt-2">
              <option :value=null disabled> Select Plans</option>
              <option  v-for="(plans, index) in selectedPlans"
                       :value="plans.value"
                       :key="index">{{plans.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.selectedPlanId.required && $v.selectedPlanId.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">Plans Type</label>
            <span class="required-asterisk">*</span>
            <select v-model="selectedPlanType"
                    :class="{'form-invalid': $v.selectedPlanType.$error}"
                    class="custom-select  mt-2">
              <option value="" disabled> Select Plans Type</option>
              <option   v-for="(plansType, index) in planTypeList"
                        :value="plansType.value"
                        :key="index">{{plansType.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.selectedPlanType.required && $v.selectedPlanType.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">Relationship</label>
            <span class="required-asterisk">*</span>
            <select v-model="selectedRelationShip" disabled
                    :class="{'form-invalid': $v.selectedRelationShip.$error}"
                    class="custom-select  mt-2 custom-select-disabled">
              <option value="" disabled> Select Relationship</option>
              <option  v-for="(relationship, index) in relationShipList"
                       :value="relationship.value"
                       :key="index">{{relationship.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.selectedRelationShip.required && $v.selectedRelationShip.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title mb-2">DOB</label>
            <span class="required-asterisk">*</span>
            <date-picker
                aria-describedby="input-feedback"
                placeholder="MM/DD/YYYY"
                class="date-picker-text"
                v-model="dob"
                :class="{'date-invalid': $v.dob.$error}"
                value-type="format"
                format="MM/DD/YYYY"
                :disabled-date="disableDates"
            >
            </date-picker>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dob.required && $v.dob.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">Gender</label>
            <span class="required-asterisk">*</span>
            <select v-model="selectedGender"
                    :class="{'form-invalid': $v.selectedGender.$error}"
                    class="custom-select  mt-2">
              <option value="" disabled> Select Gender</option>
              <option  v-for="(gender, index) in genderList"
                       :value="gender.value"
                       :key="index">{{gender.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.selectedGender.required && $v.selectedGender.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">State</label>
            <span class="required-asterisk">*</span>
            <select v-model="state"
                    :class="{'form-invalid': $v.state.$error}"
                    class="custom-select  mt-2">
              <option value="" disabled> Select State</option>
              <option  v-for="(state, index) in stateLists"
                       :value="state.value"
                       :key="index">{{state.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.state.required && $v.state.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
            <label class="label-title">Zip</label>
            <span class="required-asterisk">*</span>
            <input
                type="number"
                :class="{'form-invalid': $v.zip.$error}"
                @input="$v.zip.$touch();"
                v-model="zip"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="5"
                class="input-field"
                placeholder="Zip"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.zip.required && $v.zip.$error">
              Field is required
            </div>
            <div class="form-error text-left ml-3 mt-2" v-if="$v.zip.required && $v.zip.$error">
              Enter 5 digit zip code.
            </div>
          </div>
        </div>
        <div class=" row  mt-2">
          <div class="col-xl-6 col-md-6 col-sm-12 ">
            <div class="back-btn">
              <button type="submit"
                      @click="routeToVerification"
                      class="group-univ-btn text-center grp-quote-btn mt-0">Back
              </button>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12  ">
            <div class="d-flex justify-content-end">
              <button type="submit"
                      v-if="checkAddEmployeeBtn"
                      @click="cancelEmployeeInfo"
                      class="group-univ-btn text-center grp-quote-btn mt-0 ">Cancel
              </button>
            </div>
            <div class="d-flex justify-content-end">
              <button type="submit"
                      @click="addUpdateEmployeeInfo"
                      class="group-univ-btn text-center mt-0 grp-quote-btn mb-2">{{editGroupInfo ? 'Update' : 'Add' }} Employee Info
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="submit-btn-container d-flex justify-content-end" v-if="totalAddedInfo > 9">
          <div class="">
            <button type="submit" @click="submitAllEmployee"
                    class="group-univ-btn text-center"> Submit All Employee Info
            </button>
          </div>
        </div>
      </div>
      <div>
        <vue-confirm-dialog />
      </div>
    </div>
  </div>
</template>

<script>
import EditGroupMember from "./EditGroupMember";
import AuthLessApi from "../../../resource/AuthLessApi";
import Helper from "../../../resource/Helper";
import {required, email} from "vuelidate/lib/validators";
import {checkValidZip, checkValidPhoneNumber} from "../../../utils/validator";

export default {
  name: "GroupInfoField",
  components: {
    EditGroupMember
  },
  props: {
    groupQuoteExcel: {
      type: Object
    }
  },
  data () {
    return {
      groupInfo: {
        name: '',
        address: '',
        requestedStartDate: '',
        contractName: '',
        contractEmail: '',
        contractPhoneNumber: '',
        selectedIndustry: '',
        selectedGroupPlans: []
      },
      planLists: [],
      selectedPlanId: null,
      planTypeList: [
        {text: 'Single', value: 'IO'},
        {text: 'Single + Spouse', value: 'IS'},
        {text: 'Single + Children', value: 'IC'},
        {text: 'Family', value: 'IF'},
      ],
      selectedPlanType: '',
      primaryFullName: '',
      firstName: '',
      lastName: '',
      relationShipList: [
        {text: 'Primary', value: 'P'},
        {text: 'Spouse', value: 'S'},
        {text: 'Dependent', value: 'D'}
      ],
      selectedRelationShip: 'P',
      dob: null,
      genderList: [
        {text: 'Male', value: 'Male'},
        {text: 'Female', value: 'Female'},
      ],
      groupIndustryList: [],
      selectedGender: '',
      state: '',
      zip: null,
      employeeInfoList: [],
      editGroupInfo: false,
      editIndex: null,
      stateLists: [],
      showAddEmployeeInfo: true,
      selectedPlans: [],
      showModal: false,
      totalEmployees: 0,
      totalDependents: 0,
      totalAddedInfo: 0
    }
  },
  validations: {
    groupInfo: {
      name: {
        required
      },
      address: {
        required
      },
      requestedStartDate: {
        required
      },
      contractName: {
        required
      },
      contractEmail: {
        required,
        email
      },
      contractPhoneNumber: {
        required,
        checkValidPhoneNumber
      },
      selectedIndustry: {
        required
      }
    },
    primaryFullName: {
      required
    },
    firstName: {
      required
    },
    lastName:{
      required
    },
    selectedPlanId:{
      required
    },
    selectedPlanType: {
      required
    },
    selectedRelationShip: {
      required
    },
    dob: {
      required
    },
    selectedGender: {
      required
    },
    state: {
      required
    },
    zip:{
      required,
      checkValidZip
    },
    groupValidation: ['groupInfo'],
    employeeValidation: ['primaryFullName', 'firstName', 'lastName', 'selectedPlanId', 'selectedPlanType', 'selectedRelationShip', 'dob', 'selectedGender', 'state', 'zip']
  },
  computed: {
    checkAddEmployeeBtn () {
      if (this.employeeInfoList.length > 4 && !this.editGroupInfo) {
        return true
      } else {
        return false
      }
    },
    filteredPlanLists() {
      if (this.selectedPlans.length >= 2) {
        return this.planLists.filter(plan => this.selectedPlans.includes(plan));
      }
      return this.planLists;
    },
    submitterInfo () {
      return this.$store.getters.groupCensusSubmitterInfo
    },
    isRepAuthenticated () {
      return this.$store.getters.authenticated
    },
    platformName () {
      return this.$store.getters.redirectedPlatformName
    }
  },
  mounted() {
    let app = this
    app.getPlanList();
    app.getStateList();
    app.getIndustryList();
  },
  methods: {
    updateSelectedGroupPlans(selectedOptions) {
      if (selectedOptions.length <= 2) {
        let app = this
        app.selectedPlans = selectedOptions;
        app.groupInfo.selectedGroupPlans = selectedOptions.map(option => option.value);
      }
    },
    routeToVerification () {
      let app = this
      let tabIndex = 1
      app.$emit('routeToVerification', tabIndex)
    },
    notifyRemovePlans(selectedOptions) {
      let app = this
      app.$confirm({
        title: "Confirm",
        message: `Are you sure to delete group plan ${selectedOptions.text}?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            app.updateSelectedGroupPlans(selectedOptions);
            app.$notify(`Plan ${selectedOptions.text} has been deleted.`)
            app.employeeInfoList.forEach(employee => Number(employee.plan) === selectedOptions.value && (employee.plan = ''));
          } else {
            app.selectedPlans.push(selectedOptions)
          }
        },
      });
    },
    disableDates (date) {
      return Helper.disableFutureDates(date)
    },
    addUpdateEmployeeInfo () {
      let app = this
      app.$v.employeeValidation.$touch();
      if (app.$v.employeeValidation.$invalid) {
        return false;
      } else {
        let url;
        let payload = {}
        app.showModal = false
        payload['plan'] = app.selectedPlanId.toString()
        payload['plan_type'] = app.selectedPlanType
        payload['primary_fullname'] = app.primaryFullName
        payload['first_name'] = app.firstName
        payload['last_name'] = app.lastName
        payload['relationship'] = app.selectedRelationShip
        payload['dob'] = app.dob
        payload['gender'] = app.selectedGender
        payload['state'] = app.state
        payload['zip'] = app.zip
        payload['dependent_info'] = []
        url = `v2/group-census/validate-employer`
        let loader = this.$loading.show();
        AuthLessApi.post(url, payload)
            .then((res) => {
              console.log(res)
              app.checkAndRenderGroupInfo();
              app.$v.$reset();
              app.clearAddEmployeeInfo();
              app.editGroupInfo = false
              app.$v.$reset();
              app.employeeInfoList.length > 4 ? app.showAddEmployeeInfo = false : app.showAddEmployeeInfo = true
            })
            .catch((err) => {
              app.$notify(err.response.data.message, "error");
            })
            .finally(() => {
              loader.hide();
            });
      }
    },
    addDependentInfo (depObj) {
      let app = this
      let index = depObj?.index
      app.employeeInfoList[index].dependent_info = depObj?.depInfo
    },
    editDepInfo (depObj) {
      let app = this
      let depIndex = depObj?.depIndex
      let empIndex = depObj?.empIndex
      let empInfoList =  app.employeeInfoList[empIndex]
      empInfoList.dependent_info[depIndex].first_name = depObj?.editDepInfo.first_name
      empInfoList.dependent_info[depIndex].last_name = depObj?.editDepInfo.last_name
      empInfoList.dependent_info[depIndex].dob = depObj?.editDepInfo.dob
      empInfoList.dependent_info[depIndex].gender = depObj?.editDepInfo.gender
      empInfoList.dependent_info[depIndex].relationship = depObj?.editDepInfo.relationship
    },
    checkAndRenderGroupInfo () {
      let app = this
      let employeeInfoObj = {}
      if (app.editGroupInfo) {
        let index = app.editIndex
        let employee = app.employeeInfoList[index];
        if (app.selectedPlanType !== employee.plan_type) {
          employee.dependent_info = []
        }
        employee.plan = app.selectedPlanId.toString()
        employee.plan_type = app.selectedPlanType
        employee.primary_fullname = app.primaryFullName
        employee.first_name = app.firstName
        employee.last_name = app.lastName
        employee.relationship = app.selectedRelationShip
        employee.dob = app.dob
        employee.gender = app.selectedGender
        employee.state = app.state
        employee.zip = app.zip
      } else {
        employeeInfoObj['plan'] = app.selectedPlanId.toString()
        employeeInfoObj['plan_type'] = app.selectedPlanType
        employeeInfoObj['primary_fullname'] = app.primaryFullName
        employeeInfoObj['first_name'] = app.firstName
        employeeInfoObj['last_name'] = app.lastName
        employeeInfoObj['relationship'] = app.selectedRelationShip
        employeeInfoObj['dob'] = app.dob
        employeeInfoObj['gender'] = app.selectedGender
        employeeInfoObj['state'] = app.state
        employeeInfoObj['zip'] = app.zip
        employeeInfoObj['dependent_info'] = []
        app.employeeInfoList.push(employeeInfoObj)
        app.selectedPlanType === 'IO' ? app.showModal = false : app.showModal = true
      }
    },
    cancelEmployeeInfo() {
      let app = this
      app.showAddEmployeeInfo = false
    },
    showEmployeeInfo () {
      let app = this
      app.showAddEmployeeInfo = true
    },
    clearAddEmployeeInfo () {
      let app = this
      app.selectedPlanId = null,
          app.selectedPlanType = '',
          app.primaryFullName = '',
          app.firstName = '',
          app.lastName = '',
          app.selectedRelationShip = 'P',
          app.dob = null,
          app.selectedGender = '',
          app.state = '',
          app.zip =  null
    },
    submitAllEmployee () {
      let app = this
      app.$v.groupValidation.$touch();
      if (app.$v.groupValidation.$invalid) {
        return false;
      } else {
        let url;
        let payload = {}
        url = `v2/group-census`
        payload['group_name'] = app.groupInfo.name
        payload['group_address'] = app.groupInfo.address
        payload['group_requested_start_date'] = app.groupInfo.requestedStartDate
        payload['group_contact_name'] = app.groupInfo.contractName
        payload['group_contact_email'] = app.groupInfo.contractEmail
        payload['group_contact_phone'] = app.groupInfo.contractPhoneNumber
        payload['group_plans'] = app.changeGroupPlanKeys();
        payload['group_industry'] = app.groupInfo.selectedIndustry
        payload['submitter_name'] = app.submitterInfo.name
        payload['submitter_phone'] = app.submitterInfo.phoneNumber
        payload['submitter_email'] = app.submitterInfo.email
        payload['group_census'] = app.convertIntegerToString(app.employeeInfoList);
        payload['platform_name'] = app.platformName;
        let loader = this.$loading.show();
        AuthLessApi.post(url, payload)
            .then((res) => {
              app.$notify(res.data.message);
              app.groupInfo = {}
              app.selectedGroupPlans = {}
              app.employeeInfoList = []
              app.groupInfo.selectedGroupPlans = []
              app.showAddEmployeeInfo = true
              location.href = "/group-verify";
              !app.isRepAuthenticated && app.$store.dispatch('groupCensusSubmitterInfo', {});
              app.selectedPlans = []
            })
            .catch((err) => {
              app.$notify(err.response.data.message, "error");
            })
            .finally(() => {
              loader.hide();
            });
        app.$v.$reset();
      }
    },
    changeGroupPlanKeys () {
      let app = this
      let groupPlansList = []
      app.selectedPlans.forEach ((plans) => {
        let Obj = {
          'plan_name': plans.text,
          'id': plans.value
        }
        groupPlansList.push(Obj)
      })
      return groupPlansList
    },
    deleteEmployeeInfo (index) {
      let app = this
      app.employeeInfoList.splice(index, 1);
      app.employeeInfoList.length > 4 ? app.showAddEmployeeInfo = false : app.showAddEmployeeInfo = true
    },
    deleteDependent (Obj) {
      let app = this
      let employeeIndex = Obj.employeeIndex
      let depIndex = Obj.depIndex
      app.employeeInfoList[employeeIndex]?.dependent_info.splice(depIndex, 1);
    },
    editEmployeeInfo(index) {
      let app = this
      app.editGroupInfo = true
      app.showAddEmployeeInfo = true
      app.editIndex = index
      app.selectedPlanId = app.employeeInfoList[index].plan,
          app.selectedPlanType = app.employeeInfoList[index].plan_type,
          app.primaryFullName = app.employeeInfoList[index].primary_fullname,
          app.firstName = app.employeeInfoList[index].first_name,
          app.lastName = app.employeeInfoList[index].last_name,
          app.selectedRelationShip = app.employeeInfoList[index].relationship,
          app.dob = app.employeeInfoList[index].dob,
          app.selectedGender = app.employeeInfoList[index].gender,
          app.state = app.employeeInfoList[index].state,
          app.zip =  app.employeeInfoList[index].zip
    },
    getPlanList () {
      let app = this
      const url = `v2/group-census/plans?platform_name=${app.platformName}&submitter_email=${app.submitterInfo.email}`
      let loader = app.$loading.show();
      AuthLessApi.get(url)
          .then((response) => {
            let planListsResponse = response.data.data
            planListsResponse.forEach ((plans) => {
              let Obj = {
                text: plans.web_display_name,
                value: plans.pid
              }
              app.planLists.push(Obj)
            })
            loader.hide();
          }).finally( () => {
        app.$loading.hide();
      })
    },
    getIndustryList () {
      let app = this
      const url = `v2/group-census/group-industry`
      AuthLessApi.get(url)
          .then((response) => {
            let industryListResponse = response.data.data
            industryListResponse.forEach ((industry) => {
              let Obj = {
                text: industry.title,
                value: industry.value
              }
              app.groupIndustryList.push(Obj)
            })
          })
    },
    convertIntegerToString(employeeList) {
      return employeeList.map(employee => {
        return {
          ...employee,
          'plan': employee.plan.toString(),
          'zip': employee.zip.toString()
        }
      })
    },
    changeGroupExcelKeys (filterGroupPlanList) {
      let groupPlansList = []
      filterGroupPlanList.forEach ((plans) => {
        let Obj = {
          'text': plans.plan_name,
          'value': plans.id
        }
        groupPlansList.push(Obj)
      })
      return groupPlansList
    },
    getStateList () {
      let app = this
      let stateListObj = {}
      stateListObj = Helper.getStatesList()
      for (const key in stateListObj) {
        if (Object.prototype.hasOwnProperty.call(stateListObj, key)) {
          app.stateLists.push({ text: stateListObj[key], value: key });
        }
      }
    },
    updateTotals() {
      let app = this
      app.totalEmployees = app.employeeInfoList.length;
      app.totalDependents = app.employeeInfoList.reduce((acc, employee) => {
        return acc + (employee.dependent_info ? employee.dependent_info.length : 0);
      }, 0);
      app.totalAddedInfo = app.totalEmployees + app.totalDependents
    }
  },
  watch: {
    groupQuoteExcel: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          let app = this
          app.groupInfo.name = app.groupQuoteExcel.group_name
          app.groupInfo.address = app.groupQuoteExcel.group_address
          app.groupInfo.requestedStartDate = app.groupQuoteExcel.group_requested_start_date
          app.groupInfo.contractName = app.groupQuoteExcel.group_contact_name
          app.groupInfo.contractEmail = app.groupQuoteExcel.group_contact_email
          app.groupInfo.contractPhoneNumber = app.groupQuoteExcel.group_contact_phone
          app.selectedPlans = app.changeGroupExcelKeys(app.groupQuoteExcel.group_plans)
          app.groupInfo.selectedIndustry = app.groupQuoteExcel.group_industry
          app.employeeInfoList = app.groupQuoteExcel.group_census
          app.cancelEmployeeInfo();
        }
      },
      deep: true,
    },
    employeeInfoList: {
      handler() {
        let app = this
        app.updateTotals();
      },
      deep: true
    }
  },
}
</script>

<style scoped></style>
<template>
  <b-modal
      id="add-dependent"
      centered
      size="xl"
      :visible="show"
      @show="renderDependentInfo"
      @hide="handleClose"
      hide-footer
  >
    <div class="title-text text-center mb-4">
      <h5><strong>{{isPlanTypeIF ? 'Add/Edit Dependents' : 'Add/Edit Dependent'}}</strong></h5>
    </div>
    <div class="invalid-error-msg text-center mb-2">
      <span v-if="isPlanTypeIF"> For plan type family at least 1 spouse and 1 child is required in order to proceed. </span>
      <span v-else-if="isPlanTypeIC"> For plan type (Member + Children) at least 1 child is required in order to proceed. </span>
      <span v-else-if="isPlanTypeIS"> For plan type (Member + Spouse) only 1 spouse is required in order to proceed. </span>
    </div>
    <div class="row g-0 mt-2 mb-2" v-if="depInfo.length > 0">
      <div class="col-xl-4" v-for="(employee, index) in  depInfo" :key="index">
        <div
            class="edit-member-container "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div>
            <span class=""
            >Dependent {{ index + 1 }}: {{ employee.first_name }}
              {{ employee.last_name }}</span
            >
            </div>
            <div class="mt-1 text-end">
              <i
                  class="fas fa-edit mr-1 fa-icon-container fa-icon-edit text-capitalize gg"
                  v-b-tooltip.hover
                  :title="`Edit Dependent ${index + 1}: ${employee.first_name} ${
                employee.last_name
              }`"
                  @click="editDependentInfo(index)"
              ></i>
              <i
                  class="fas fa-trash-alt mr-1 fa-icon-container text-capitalize trash-icon-btn"
                  v-b-tooltip.hover
                  :title="`Delete Dependent ${index + 1}: ${employee.first_name} ${
                employee.last_name
              }`"
                  @click="deleteDependentInfo(index)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAddDepBtn">
      <div class="row add-dep-inner-container">
        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title">First Name</label>
          <span class="required-asterisk">*</span>
          <input type="text"
                 v-model="dep.first_name"
                 :class="{'form-invalid': $v.dep.first_name.$error}"
                 class="input-field" placeholder="First Name" />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.first_name.required && $v.dep.first_name.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title">Last Name</label>
          <span class="required-asterisk">*</span>
          <input type="text"
                 v-model="dep.last_name"
                 :class="{'form-invalid': $v.dep.last_name.$error}"
                 class="input-field" placeholder="Last Name" />
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.last_name.required && $v.dep.last_name.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title mb-2">DOB</label>
          <span class="required-asterisk">*</span>
          <date-picker
              aria-describedby="input-feedback"
              placeholder="MM/DD/YYYY"
              class="date-picker-text"
              v-model="dep.dob"
              :class="{'date-invalid': $v.dep.dob.$error}"
              value-type="format"
              format="MM/DD/YYYY"
              :disabled-date="disableDates"
          >
          </date-picker>
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.dob.required && $v.dep.dob.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title">Gender</label>
          <span class="required-asterisk">*</span>
          <select v-model="dep.gender"
                  :class="{'form-invalid': $v.dep.gender.$error}"
                  class="custom-select mt-2">
            <option value="" disabled> Select Gender</option>
            <option v-for="(gender, genderIndex) in genderList"
                    :value="gender.value"
                    :key="genderIndex">{{gender.text}}</option>
          </select>
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.gender.required && $v.dep.gender.$error">
            Field is required
          </div>
        </div>
        <div class="col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3">
          <label class="label-title">Relationship</label>
          <span class="required-asterisk">*</span>
          <select v-model="dep.relationship"
                  :class="{'form-invalid': $v.dep.relationship.$error}"
                  class="custom-select mt-2">
            <option value="" disabled> Select Relationship</option>
            <option v-for="(relationship, relIndex) in relationShipList"
                    :value="relationship.value"
                    :class="{'disabled-option': isSpouseDisabled && relationship.value === 'S'}"
                    :disabled="relationship.value === 'S' && isSpouseDisabled"
                    :key="relIndex">{{relationship.text}}</option>
          </select>
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dep.relationship.required && $v.dep.relationship.$error">
            Field is required
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="text-center me-4" v-if="showAddDepBtn">
        <button class="add-dependent-btn" @click="cancelDepModal" v-if="editDependent">Cancel</button>
        <button class="add-dependent-btn" @click="addDependent">{{editDependent ? 'Update' : ' + Add' }} Dependent</button>
      </div>
    </div>
    <div>
      <div class="d-flex justify-content-center" v-if="isDepInfoAddedValid">
        <div class="text-center me-4">
          <button class="add-dependent-btn" @click="submitDepInfo">Submit</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import Helper from "../../../resource/Helper";
export default {
  name: "AddDependentModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number
    },
    employeeInfoList: {
      type: Array
    }
  },
  data() {
    return {
      dob: "",
      variants: ["close-btn"],
      appendDatepicker: false,
      relationShipList: [],
      genderList: [
        { text: 'Male', value: 'Male' },
        { text: 'Female', value: 'Female' },
      ],
      selectedRelationShip: '',
      depInfo: [],
      dep: {
        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        relationship: ''
      },
      editDependent: false,
      editDepIndex: null,
      isPlanTypeIF: false,
      isPlanTypeIC: false,
      isPlanTypeIS: false,
      isDepInfoAddedValid: false,
      showAddDepBtn: true
    };
  },
  computed: {
    isSpouseDisabled() {
      return this.depInfo.some(dep => dep.relationship === 'S');
    }
  },

  validations: {
    dep: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      dob: {
        required
      },
      gender: {
        required
      },
      relationship: {
        required
      }
    },
    depInfo: ['dep']
  },
  methods: {
    renderDependentInfo() {
      let app = this;
      let empIndex = app.index
      app.depInfo = []
      app.depInfo = app.employeeInfoList[empIndex].dependent_info
      app.editDependent = false
      app.clearDepObj();
      app.checkPlanTypeRelationShip();
      app.checkPlanType();
      app.checkDepInfoValidation();
      app.$v.$reset();
    },
    disableDates (date) {
      return Helper.disableFutureDates(date)
    },
    cancelDepModal () {
      let app = this
      app.$bvModal.hide('add-dependent');
    },
    checkPlanTypeRelationShip () {
      let app = this
      let empIndex = app.index
      app.relationShipList = []
      if (app.employeeInfoList[empIndex].plan_type == 'IS') {
        let realtionObj =  [{ text: 'Spouse', value: 'S' }]
        app.relationShipList = realtionObj
      } else if (app.employeeInfoList[empIndex].plan_type == 'IC') {
        let realtionObj =  [{ text: 'Child', value: 'C' }]
        app.relationShipList = realtionObj
      } else if ((app.employeeInfoList[empIndex].plan_type == 'IF')) {
        let realtionObj =  [ { text: 'Spouse', value: 'S' },{ text: 'Child', value: 'C' }]
        app.relationShipList = realtionObj
      }
    },
    checkPlanType () {
      let app = this
      let empIndex = app.index
      app.employeeInfoList[empIndex].plan_type === 'IF' ? app.isPlanTypeIF = true: app.isPlanTypeIF = false
      app.employeeInfoList[empIndex].plan_type === 'IC' ? app.isPlanTypeIC = true: app.isPlanTypeIC = false
      app.employeeInfoList[empIndex].plan_type === 'IS' ? app.isPlanTypeIS = true: app.isPlanTypeIS = false
    },
    addDependent() {
      let app = this;
      app.$v.depInfo.$touch();
      if (app.$v.depInfo.$invalid) {
        return false;
      } else {
        if (app.editDependent) {
          let index = app.editDepIndex
          let editDep = app.depInfo[index]
          editDep.first_name = app.dep.first_name
          editDep.last_name = app.dep.last_name
          editDep.dob = app.dep.dob
          editDep.gender = app.dep.gender
          editDep.relationship = app.dep.relationship
          app.editDependent = false
          app.clearDepObj();
        } else {
          app.depInfo.push(app.dep)
          app.$emit('checkAddDepBtn', app.index)
          app.clearDepObj();
        }
        app.checkDepInfoValidation();
        app.$v.$reset();
      }
    },
    checkDepInfoValidation () {
      let app = this
      let empIndex = app.index
      let planType = app.employeeInfoList[empIndex].plan_type
      if (planType == 'IC') {
        app.showAddDepBtn = true
        app.depInfo.length > 0 ?  app.isDepInfoAddedValid = true : app.isDepInfoAddedValid = false
      }
      if (planType == 'IS') {
        app.depInfo.length > 0 ?  app.isDepInfoAddedValid = true : app.isDepInfoAddedValid = false
        app.depInfo.length  > 0 ? app.showAddDepBtn = false : app.showAddDepBtn = true
      }
      if (planType == 'IF') {
        app.showAddDepBtn = true
        app.isDepInfoAddedValid = app.isValidDepInfo();
      }
    },
    isValidDepInfo() {
      let hasSpouse = this.depInfo.some(dep => dep.relationship === 'S');
      let hasChild = this.depInfo.some(dep => dep.relationship === 'C');
      return hasSpouse && hasChild;
    },
    clearDepObj() {
      let app = this
      app.dep = {
        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        relationship: ''
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    handleClose() {
      this.$emit("closeModal");
    },
    editDependentInfo(index) {
      let app = this
      app.dep.first_name = app.depInfo[index].first_name
      app.dep.last_name = app.depInfo[index].last_name
      app.dep.dob = app.depInfo[index].dob
      app.dep.gender = app.depInfo[index].gender
      app.dep.relationship = app.depInfo[index].relationship
      app.editDependent = true
      app.editDepIndex = index
      app.showAddDepBtn = true
    },
    deleteDependentInfo (index) {
      let app = this
      app.depInfo.splice(index, 1);
      app.checkDepInfoValidation();
    },
    submitDepInfo () {
      let app = this
      let depInfoObj = {
        'index': app.index,
        'depInfo': app.depInfo
      }
      app.$emit('addDependentInfo', depInfoObj)
      app.$notify('Successfully Added Dependent Info')
      app.checkDepInfoValidation();
    }
  },
  watch: {
    depInfo: {
      handler() {
        let app = this
        let empIndex = app.index
        let planType = app.employeeInfoList[empIndex].plan_type
        if (planType == 'IF') {
          if (this.dep.relationship === 'S' && this.isSpouseDisabled) {
            this.dep.relationship = '';
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.disabled-option {
  background-color: #e0e0e0;
  color: #a0a0a0;
  pointer-events: none;
}
</style>
import axios from "axios";
import router from "../router/index";
import store from "../store";
import endpoint from "@/resource/EndPoints/endpoints"
import Vue from "vue";
export default class Helper {

  static validateEmail(email) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  }
  static validatePassword(password) {
    return new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    ).test(password); // eslint-disable-line
  }
  static logout() {
    this.clearToken();
    axios.get("/v1/logout");
    this.clearToken();
    router.push({ name: "Login" });
  }
  static forceLogout() {
    this.clearToken();
    router.push({ name: "Login" });
  }

  static clearToken() {
    store.commit("setAuthentication", false);
    store.commit("setAccessToken", "");
    store.commit("setRepEmail", "");
    store.commit("setRepId", "");
    store.commit("setRepData", "")
    store.commit("setRootUser", "")
    store.commit("setUpLineInfo", {});
    store.commit("setReferralFlag", false);
    store.commit("setReferralId", '');
    store.commit("setUpLineInfo", {});
    store.commit("setViewMessageUserInfo", {});
    store.commit("setPlatFormStatus", false)
  }

  static setRepData(data) {
    const repData = {
      id: data.agent_id ? data.agent_id : "",
      name: data.name ? data.name : "",
      email: data.email ? data.email : "",
      phone: this.formatPhoneNumber(data.telephone),
      image: data.image ? data.image : "",
      code: data.agent_code ? data.agent_code : "",
      has_downline: data.has_downline,
      id_for_tree:data.id_for_tree,
      is_data_confirmed:data.is_data_confirmed,
      platformList: data.platform,
      agentLevel: data.agent_level,
      agentLevel2: data.agent_level_2
    }
    store.commit("setRepData", repData)
  }

  static checkPlatformStatus (data) {
    if (data.platform.data.length < 1) {
      store.commit("setPlatFormStatus", true)
    } else {
      store.commit("setPlatFormStatus", false)
    }
  }
  static setLoginData(data) {
    store.commit("setAccessToken", data.access_token);
    store.commit("setRepId", data.agent.agent_id);
    store.commit("setOtpCode", "");
    store.commit("setRootUser", data.agent.is_root)
    store.commit("setLastLogin", data.last_login)
    store.commit("setIsVip", data.is_vip)
    const repData = {
      id: data.user.id,
      name: data.user.name,
      email: data.user.email,
      phone: this.formatPhoneNumber(data.user.phone),
      image: data.user.image,
      code: data.user.code ? data.user.code : ""
    }
    store.commit("setRepData", repData)
  }


  static setLoginDetails(data) {
    this.setLoginData(data);
    if (store.getters.authenticated == false) {
      router.push({ name: "LoginSave" });
    } else {
      router.push({ name: "Dashboard" })
    }
  }

  static redirect(url) {
    window.location = window.location.hostname + url;
  }
  static infoMessage(
    thisObj,
    variant = "success",
    message = "This is message"
  ) {
    let typeTitle;
    typeTitle = variant == "danger" ? 'error' : variant
    thisObj.$root.$toast.open({
      message: message.charAt(0).toUpperCase() + message.slice(1),
      type: typeTitle.toLowerCase(),
      duration: 3000,
      dismissible: true,
      position: "top-right",
      queue: true
    })
  }

  static alert(status = "success", message = "This is test") {
    switch (status) {
      case "success":
        Vue.notify({
          group: "alert",
          title: "Success!",
          type: "success",
          text: message,
        });
        break;
      case "error":
        Vue.notify({
          group: "alert",
          title: "Error!",
          type: "error",
          text: message,
        });
        break;
      case "warning":
        Vue.notify({
          group: "alert",
          title: "Warning!",
          type: "warn",
          text: message,
        });
        break;
      case "info":
        Vue.notify({
          group: "alert",
          title: "Info!",
          text: message,
        });
        break;
    }
  }

  static delayRedirect(routeName, delaySec = 0, data = []) {
    setTimeout(() => {
      router.push({
        name: routeName,
        params: {
          items: data,
        },
        meta: {
          reload: true,
        },
      });
    }, delaySec * 1000);
  }
  static validateDate(date) {
    return new Date(date) != "Invalid Date";
  }
  static downloadFile(fileUrl) {
    window.location = fileUrl;
  }

  static getStatesList() {
    return {
      AK: "Alaska",
      AL: "Alabama",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District of Columbia",
      FL: "Florida",
      GA: "Georgia",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    };
  }

  static getRelationList() {
    return {
      Spouse: "Spouse",
      "Uncle or Aunt": "Uncle or Aunt",
      "Nephew or Niece": "Nephew or Niece",
      "Brother or Sister": "Brother or Sister",
      Child: "Child",
      "Ex Spouse": "Ex Spouse",
      Mother: "Mother",
      Father: "Father",
      "Life Partner": "Life Partner",
      Trust: "Trust",
      Friend: "Friend",
      Other: "Other",
    };
  }

  static formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  static getAddressTypes() {
    return [
      { value: "business", text: "Business" },
      { value: "billing", text: "Billing" },
    ]
  }



  static accountTypes() {
    return [
      { value: "savings", text: "Saving" },
      { value: "checking", text: "Checking" },
    ]
  }

  static accountHolderTypes() {
    return [
      { value: "individual", text: "Individual" },
      { value: "company", text: "Company" },
    ]
  }

  static getStates() {
    let states = this.getStatesList();
    let result = []
    for (const [key, value] of Object.entries(states)) {
      result.push({ "value": key, "text": value })
    }
    return result
  }

  /**
    * convert object params to url
    */
  static objToUrlParams(obj) {
    return Object.keys(obj)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key] ? obj[key] : ''))
      .join("&");
  }

  static validateCustomPassword(value) {
    /*eslint-disable*/
    var pwd = value
    var nScore = 0;
    var nLength = 0;
    var nAlphaUC = 0;
    var nAlphaLC = 0;
    var nNumber = 0;
    var nSymbol = 0;
    var nMidChar = 0;
    var nRequirements = 0;
    var nAlphasOnly = 0;
    var nNumbersOnly = 0;
    var nRepChar = 0;
    var nConsecAlphaUC = 0;
    var nConsecAlphaLC = 0;
    var nConsecNumber = 0;
    var nConsecSymbol = 0;
    var nConsecCharType = 0;
    var nSeqAlpha = 0;
    var nSeqNumber = 0;
    var nSeqChar = 0;
    var nMultLength = 4;
    var nMultAlphaUC = 2;
    var nMultAlphaLC = 2;
    var nMultNumber = 4;
    var nMultSymbol = 6;
    var nMultMidChar = 2;
    var nMultRequirements = 2;
    var nMultConsecAlphaUC = 2;
    var nMultConsecAlphaLC = 2;
    var nMultConsecNumber = 2;
    var nMultSeqAlpha = 3;
    var nMultSeqNumber = 3;
    var nTmpAlphaUC = "";
    var nTmpAlphaLC = "";
    var nTmpNumber = "";
    var nTmpSymbol = "";
    var sAlphas = "abcdefghijklmnopqrstuvwxyz";
    var sNumerics = "01234567890";
    /* var sComplexity = saStrength[index.TOOSHORT]; */
    var sColor = "#eee";
    var nMinPwdLen = 6;
    if (pwd) {
      nScore = parseInt(pwd.length * nMultLength);
      nLength = pwd.length;
      var arrPwd = pwd.replace(/\s+/g, "").split(/\s*/);
      var arrPwdLen = arrPwd.length;
      // console.log("pwdLenght",arrPwdLen)
      /* Loop through password to check for Symbol, Numeric, Lowercase and Uppercase pattern matches */
      for (var a = 0; a < arrPwdLen; a++) {
        if (arrPwd[a].match(new RegExp(/[A-Z]/g))) {
          if (nTmpAlphaUC !== "") {
            if (nTmpAlphaUC + 1 == a) {
              nConsecAlphaUC++;
              nConsecCharType++;
            }
          }
          nTmpAlphaUC = a;
          nAlphaUC++;
        } else if (arrPwd[a].match(new RegExp(/[a-z]/g))) {
          if (nTmpAlphaLC !== "") {
            if (nTmpAlphaLC + 1 == a) {
              nConsecAlphaLC++;
              nConsecCharType++;
            }
          }
          nTmpAlphaLC = a;
          nAlphaLC++;
        } else if (arrPwd[a].match(new RegExp(/[0-9]/g))) {
          if (a > 0 && a < arrPwdLen - 1) {
            nMidChar++;
          }
          if (nTmpNumber !== "") {
            if (nTmpNumber + 1 == a) {
              nConsecNumber++;
              nConsecCharType++;
            }
          }
          nTmpNumber = a;
          nNumber++;
        } else if (arrPwd[a].match(new RegExp(/[^a-zA-Z0-9_]/g))) {
          if (a > 0 && a < arrPwdLen - 1) {
            nMidChar++;
          }

          if (nTmpSymbol !== "") {
            if (nTmpSymbol + 1 == a) {
              nConsecSymbol++;
              nConsecCharType++;
            }
          }
          nTmpSymbol = a;
          nSymbol++;
        }

        /* Internal loop through password to check for repeated characters */
        for (var b = 0; b < arrPwdLen; b++) {
          if (arrPwd[a].toLowerCase() == arrPwd[b].toLowerCase() && a != b) {
            nRepChar++;
          }
        }
      }

      /* Check for sequential alpha string patterns (forward and reverse) */
      for (var s = 0; s < 23; s++) {
        var sFwd = sAlphas.substring(s, parseInt(s + 3));
        var sRev = sFwd.strReverse();
        if (
          pwd.toLowerCase().indexOf(sFwd) != -1 ||
          pwd.toLowerCase().indexOf(sRev) != -1
        ) {
          nSeqAlpha++;
          nSeqChar++;
        }
      }

      /* Check for sequential numeric string patterns (forward and reverse) */
      for (var s = 0; s < 8; s++) {
        var sFwd = sNumerics.substring(s, parseInt(s + 3));
        var sRev = sFwd.strReverse();
        if (
          pwd.toLowerCase().indexOf(sFwd) != -1 ||
          pwd.toLowerCase().indexOf(sRev) != -1
        ) {
          nSeqNumber++;
          nSeqChar++;
        }
      }

      /* Modify overall score value based on usage vs requirements */

      /* General point assignment */
      if (nAlphaUC > 0 && nAlphaUC < nLength) {
        nScore = parseInt(nScore + (nLength - nAlphaUC) * nMultAlphaUC);
      }
      if (nAlphaLC > 0 && nAlphaLC < nLength) {
        nScore = parseInt(nScore + (nLength - nAlphaLC) * nMultAlphaLC);
      }
      if (nNumber > 0 && nNumber < nLength) {
        nScore = parseInt(nScore + nNumber * nMultNumber);
      }
      if (nSymbol > 0) {
        nScore = parseInt(nScore + nSymbol * nMultSymbol);
      }
      if (nMidChar > 0) {
        nScore = parseInt(nScore + nMidChar * nMultMidChar);
      }

      /* Point deductions for poor practices */
      if ((nAlphaLC > 0 || nAlphaUC > 0) && nSymbol === 0 && nNumber === 0) {
        // Only Letters
        nAlphasOnly = nLength;
        nScore = parseInt(nScore - nAlphasOnly);
      }
      if (nAlphaLC === 0 && nAlphaUC === 0 && nSymbol === 0 && nNumber > 0) {
        // Only Numbers
        nNumbersOnly = nLength;
        nScore = parseInt(nScore - nNumbersOnly);
      }
      if (nRepChar > 0) {
        // Same character exists more than once
        nScore = parseInt(nScore - nRepChar);
      }
      if (nConsecAlphaUC > 0) {
        // Consecutive Uppercase Letters exist
        nScore = parseInt(nScore - nConsecAlphaUC * nMultConsecAlphaUC);
      }
      if (nConsecAlphaLC > 0) {
        // Consecutive Lowercase Letters exist
        nScore = parseInt(nScore - nConsecAlphaLC * nMultConsecAlphaLC);
      }
      if (nConsecNumber > 0) {
        // Consecutive Numbers exist
        nScore = parseInt(nScore - nConsecNumber * nMultConsecNumber);
      }
      if (nSeqAlpha > 0) {
        // Sequential alpha strings exist (3 characters or more)
        nScore = parseInt(nScore - nSeqAlpha * nMultSeqAlpha);
      }
      if (nSeqNumber > 0) {
        // Sequential numeric strings exist (3 characters or more)
        nScore = parseInt(nScore - nSeqNumber * nMultSeqNumber);
      }

      /* Determine if mandatory requirements have been met */
      if (nLength >= nMinPwdLen) {
        nRequirements = 0;
        var nMinReqChars = 4;
        if (nAlphaUC >= 1) {
          nRequirements++;
        }
        if (nAlphaLC >= 1) {
          nRequirements++;
        }
        if (nNumber >= 1) {
          nRequirements++;
        }
        if (nSymbol >= 1) {
          nRequirements++;
        }
        // console.log("nScore",nScore)

        if (nRequirements >= nMinReqChars) {
          nScore = parseInt(nScore + nRequirements * nMultRequirements);
        } else {
          if (nScore > 64) {
            nScore = nScore - (nRequirements * 10)
          }
        }

      }
      //again determining the password strend if upperCase,lowerCase , minLength ,Symbol and Number
      return nScore >= 60;
      //document.querySelector('.score').innerText = nScore;
    }
  }

  static dashboardGetters() {
    store.dispatch("getPendingStats");
    //todo other dashboard stats
  }

  static messageUserTypes() {
    return [
      { value: 'A', text: 'Reps' },
      { value: 'M', text: 'Members' },
      { value: 'G', text: 'Groups' },
    ]
  }

  static confirmDialog(title, message) {
    return new Promise((resolve) => {
      Vue.$confirm({
        title,
        message,
        button: {
          yes: 'Yes',
          no: 'Cancel'
        },
        callback: (confirm) => {
          resolve(confirm);
        }
      });
    })
  }

  static simpleEditorConfig() {
    return {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "|",
        "|",
        "undo",
        "redo",
      ],
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      },
    }
  }

  static formattedDataWithComma(data) {
    return !(data == null || data === '') ? data + ',' : ''
  }

  static formattedAddress(address1, address2, city, state, zip) {
    address1 = this.formattedDataWithComma(address1);
    address2 = this.formattedDataWithComma(address2);
    city = this.formattedDataWithComma(city);
    state = this.formattedDataWithComma(state);
    zip = zip ? zip : '';
    return `${address1} ${address2} ${city} ${state} ${zip}`;
  }

  static formattedAddress2(address2, city, state, zip) {
    address2 = this.formattedDataWithComma(address2);
    city = this.formattedDataWithComma(city);
    state = this.formattedDataWithComma(state);
    zip = zip ? zip : '';
    return `${address2} ${city} ${state} ${zip}`;
  }

  static getAnlayticsUrl() {
    let [repId, url] = [btoa(store.getters.repId), "https://corenroll.com/analytics/index.php?agent_id="]
    return `${url}${repId}`
  }

  static getMonths() {
    return [
      { value: 1, text: 'January' },
      { value: 2, text: 'February' },
      { value: 3, text: 'March' },
      { value: 4, text: 'April' },
      { value: 5, text: 'May' },
      { value: 6, text: 'June' },
      { value: 7, text: 'July' },
      { value: 8, text: 'August' },
      { value: 9, text: 'September' },
      { value: 10, text: 'October' },
      { value: 11, text: 'November' },
      { value: 12, text: 'December' },
    ]
  }

  static benefitStoreLink() {
    const [agentId, benefitStoreUrl] = [
      store.getters.repId,
      endpoint.BENEFIT_STORE_URL,
    ];
    return `${benefitStoreUrl}/agent-home?agent_id=${btoa(agentId)}`;
  }

  static calendarAppLink() {
    return process.env.VUE_APP_REP_CALENDAR_APP_URL+"auth/login"
  }

  static hideSideBar() {
    let rep = store.getters.rep
    if(rep && (rep.id == 1609 || rep.code == 'MHNY4610')) {
      return true
    }
    return false
  }
  static hideSideBarCorContract () {
    let rep = store.getters.rep
    if( rep.code == 'PHOH3123' || rep.code == 'SSOH5445' || rep.code == '5BHNJ457' || rep.code == 'BLUE8888') {
      return false
    } else {
      return true
    }
  }

  static hideSideManageVideo () {
    let rep = store.getters.rep
    if( rep.code == 'PHOH3123' || rep.code == 'SSOH5445') {
      return false
    } else {
      return true
    }
  }
  static getQuillToolbarOptions() {
    return [
      ["bold", "italic", "underline"],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }, { header: 3 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["clean"],
      ["link", "image"],
      ['clean']                                         // remove formatting button
    ];
  }
  static convertDateFormat(params) {
    if (params) {
      const inputDateString = params;
      const inputDate = new Date(inputDateString);

      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      const year = inputDate.getFullYear();
      const month = months[inputDate.getMonth()];
      const day = inputDate.getDate();

      let hours = inputDate.getHours();
      const minutes = inputDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      hours = hours ? hours : 12; // Handle midnight (0) and noon (12)

      const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;

      return formattedDate
    }
  }
  static disableFutureDates(date) {
    return date > new Date();
  }
}

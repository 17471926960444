var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.mainClass
  }, [_c('router-link', {
    attrs: {
      "to": _vm.routeLink ? _vm.routeLink : ''
    }
  }, [_c('div', {
    class: _vm.routeLink.name == this.$route.name ? "pending-blocks active-pending-item" : 'pending-blocks'
  }, [_c('div', {
    staticClass: "pending-icon"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "pending-content"
  }, [_c('h3', [_vm._v(_vm._s(_vm.title))])]), _c('div', {
    class: !_vm.badgefill ? 'pending-badge fill-plain' : 'pending-badge'
  }, [_c('span', [_vm._v(_vm._s(_vm.value ? _vm.value : 0))])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
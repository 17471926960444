import Api from "@/resource/Api"

const state = {
    primaryInfo : null,
}

const getters = {
    primaryInfo: state => state.primaryInfo,
}

const actions = {
    loadPrimaryInfo({commit}, enrollmentId){
        let url ="v2/rep-24hrs-edit/" + enrollmentId + "/primary-info";
        Api.get(url)
            .then((res) => {
                let response = res.data.data;
                commit('FORM_DATA', response);
            }).catch((err) => {
                console.log('err', err);
                Api.notification(err.response.status, err.response.message)
            })
    }
}

const mutations = {
    FORM_DATA(state, data){
        state.primaryInfo = data;
    },

}

export default{
    state,
    getters,
    actions,
    mutations
}
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "breadcrumb-main"
  }, [_c('b-breadcrumb', [_c('b-breadcrumb-item', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Dashboard'
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-home"
  }), _vm._v(" Home "), _c('b-icon', {
    attrs: {
      "icon": "house",
      "scale": "1.25",
      "shift-v": "1.25",
      "aria-hidden": "true"
    }
  })], 1)], 1), _vm._l(_vm.items, function (item) {
    return [_c('b-breadcrumb-item', {
      key: item.title,
      attrs: {
        "active": item.active
      }
    }, [item.path && item.path.name && item.path.params ? [_c('router-link', {
      attrs: {
        "to": {
          name: item.path.name,
          params: item.path.params
        }
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])] : item.path && item.path.name ? [_c('router-link', {
      attrs: {
        "to": {
          name: item.path.name
        }
      }
    }, [_c('span', {
      staticStyle: {
        "color": "#03C3EC !important"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])])] : [_vm._v(" " + _vm._s(item.title) + " ")]], 2)];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
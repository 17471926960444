var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', {
          attrs: {
            "id": "label-".concat(_vm.labelFor),
            "for": _vm.labelFor
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        })]), _c('b-form-select', {
          class: _vm.inputClass,
          attrs: {
            "id": _vm.labelFor,
            "state": _vm.getValidationState(validationContext),
            "aria-describedby": "input-feedback",
            "options": _vm.options
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null,
                  "disabled": ""
                }
              }, [_vm._v(" " + _vm._s(_vm.defaultOption) + " ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _c('b-form-invalid-feedback', {
          attrs: {
            "id": "input-feedback"
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
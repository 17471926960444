var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.computedLabel)
          }
        })]), _c('multiselect', {
          class: _vm.selectClassName(validationContext),
          attrs: {
            "name": _vm.labelName,
            "options": _vm.options,
            "custom-label": _vm.customLabel,
            "multiple": true,
            "searchable": true,
            "placeholder": _vm.computedPlaceholder,
            "close-on-select": false,
            "clear-on-select": false,
            "preserve-search": true,
            "hide-selected": false,
            "show-no-results": false,
            "loading": _vm.isLoading,
            "options-limit": _vm.optionsLimit,
            "internal-search": _vm.localSearch,
            "local-search": _vm.localSearch,
            "showNoResults": true
          },
          on: {
            "remove": _vm.removeHandler,
            "select": _vm.selectHandler,
            "input": _vm.toggleHandler,
            "focus": _vm.autoCompleteHandler,
            "search-change": _vm.searchHandler,
            "open": _vm.openHandler
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref) {
              var values = _ref.values;
              return [values.length ? _c('span', {
                staticClass: "multiselect__single"
              }, [_vm._v(" " + _vm._s(values.includes("ALL") ? 'All ' : values.length) + " " + _vm._s(_vm.selectionLabel) + " Selected ")]) : _vm._e()];
            }
          }], null, true),
          model: {
            value: _vm.computedInputData,
            callback: function callback($$v) {
              _vm.computedInputData = $$v;
            },
            expression: "computedInputData"
          }
        }), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.getValidationState(validationContext),
            "id": "input-feedback "
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
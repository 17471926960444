<template>
  <b-form-group :class="formGroupClass">
    <validation-provider
      :name="labelName"
      :rules="rules"
      v-slot="validationContext"
    >
      <label :id="`label-${labelFor}`" :for="labelFor">
        <span v-html="computedLabel" />
      </label>
      <b-form-file
        :id="labelFor"
        browse-text="Choose File"
        :state="getValidationState(validationContext)"
        @change="fileHandler"
        v-model="computedInputData"
        :readonly="readonly"
        :placeholder="computedPlaceholder"
        :accept="computedMimeTypes"
      >
      </b-form-file>
     <error-text
        v-if="
          validationContext.errors[0] || (apiErrors)
        "
        :errors="
          validationContext.errors && validationContext.errors.length > 0
            ? validationContext.errors
            : apiErrors
            ? apiErrors[fieldName]
            : ''
        "
        :field="fieldName"
      />
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    vmodel: {
      required: true,
      default: null,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    fileClass: {
      type: String,
      required: false,
    },
    acceptMimeTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    apiErrors: {
      required: false,
    },
    fieldName:{
      required: false,
    }
  },
  components: {
    ValidationProvider,
  },
  data: () => {
    return {
      file: null,
    };
  },
  computed: {
    computedMimeTypes() {
      return this.acceptMimeTypes.toString();
    },
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `No File Choosen`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function () {
        return this.vmodel;
      },
      set: function () {
        this.$emit("update:vmodel", this.file ? this.file : null);
      },
    },
  },
  methods: {
    fileHandler(e) {
      const file = e.target.files[0];
      this.file = file;
      this.imageSrc = URL.createObjectURL(file);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style>
.input-required {
  color: red !important;
}

.custom-file-label {
  display: none !important;
}
</style>

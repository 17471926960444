<template>
  <div class="table-pagination" v-if="items.total_records > perPage">
    <b-pagination
      v-if="items.total_records > 0"
      v-model="items.current_page"
      :total-rows="items.total_records"
      :per-page="perPage"
      align="right"
      prev-text="Prev"
      next-text="Next"
      first-text="First"
      last-text="Last"
      @change="handlePageChange"
    >
    </b-pagination>
  </div>
</template>

<script>
import Helper from "@/resource/Helper";
import axios from "axios";
export default {
  name: "Pagination",
  props: {
    rows: {
      required: true,
    },
    filters: {
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      items: [],
      filterParams: {},
    };
  },
  methods: {
    handlePageChange(page) {
      let url, pageSize;
      pageSize = this.filters.page_size ? this.filters.page_size : this.perPage;
      url = `${this.items.path}${
        this.items.path.includes("?") ? "&" : "?"
      }page_size=${pageSize}&page=${page}`;
      this.getData(url);
    },
    // goToUrl(url) {
    //   if (this.filterQuery) {
    //     url = url + `&${this.filterQuery}`;
    //   }
    //   this.getData(url);
    // },
    async getData(url) {
      if (this.$route.path == '/view-msg') {
        let userInfo = this.$store.getters.viewMessageUserInfo
        this.filterParams.user_id =  userInfo.user_id
        this.filterParams.user_message_type =  userInfo.user_message_type
      }
      // checking the route path inorder to pass rep id on view group quote.
      if (this.$route.path == '/view-group-quote') {
        this.filterParams['rep_id'] = this.$store.getters.repId
      }
      if (this.filterQuery) {
        url = `${url}&${this.filterQuery}`;
      }
      let loader = this.$loading.show();
      await axios
        .get(url)
        .then((response) => {
          let data = response.data;
          this.$emit("update:rows", data);
        })
        .catch(() => {
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  mounted() {
     this.$root.$on("searchEvent", (data) => {
      this.filterParams = data;
      this.handlePageChange(1)
    });
  },
  computed: {
     perPage(){
        return this.items.per_page ? this.items.per_page : 10
      },
    filterQuery() {
      return Helper.objToUrlParams(this.filterParams);
    },
  },
  created() {
    this.items = this.rows;
    this.filterParams = this.filters;
  },
  watch: {
    rows(value) {
      this.items = value;
    },
    filters(value) {
      this.filterParams = value;
    },
  },
};
</script>

<style >

.page-link{
  border:none !important;
  color:#778699 !important;
}
/* .page-item:last-child .page-link{
color:#29bcff !important;
} */
.page-item.active .page-link{
  background-color:unset !important;
  color:#29BCFF !important;
  border-bottom:3px solid #29BCFF !important;
}
.page-item .page-link:focus{
  box-shadow:unset !important;
}

.pagination ul li {
  font-size: 13px;
}

.table-pagination {
  margin-top: -20px;

}

.pagination {
  margin-top: 30px !important;
}

@media(max-width: 499px){
  .pagination{
    display: inline;
  }
}
</style>

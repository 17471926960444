var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('registration-auth-header')], 1), _c('div', {
    staticClass: "group-page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid p-0"
  }, [_c('div', {
    staticClass: "top-title"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "group-univ-btn text-center mt-0",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.renderUploadExcel
    }
  }, [_vm._v(" Import Excel File ")]), _c('button', {
    staticClass: "group-univ-btn text-center mt-0",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.routeToDashboard
    }
  }, [_vm._v("Dashboard ")])]), _c('div', [_c('group-info-field', {
    attrs: {
      "groupQuoteExcel": _vm.groupQuoteExcel
    },
    on: {
      "routeToVerification": _vm.routeToVerification
    }
  })], 1), _c('div', [_c('upload-excel-file-modal', {
    on: {
      "getGroupQuoteExcelList": _vm.getGroupQuoteExcelList
    }
  })], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', {
    staticClass: "group-census-label"
  }, [_vm._v("Group Quote")])]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    class: _vm.className,
    attrs: {
      "href": "javascript:void(0);",
      "title": _vm.title
    },
    on: {
      "click": _vm.clicked
    }
  }, [_vm.iconClass ? _c('i', {
    class: _vm.iconClass
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.haveText ? _vm.title : "") + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }
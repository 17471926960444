<template>
  <div :class="formGroupClass">
    <b-form-group>
      <validation-provider
        :name="labelName"
        :rules="rules"
        v-slot="validationContext"
      >
        <label :for="labelFor ? labelFor : ''">
          <span v-html="computedLabel" />
        </label>
        <multiselect
          v-model="computedInputData"
          :options="options"
          :label="label"
          :track-by="trackBy"
          :key="trackBy"
          :class="selectClassName(validationContext)"
          @focus.native="autoCompleteHandler"
          @input="inputHandler"
          :placeholder="placeholder"
          :disabled="isDisabled"
        >
        </multiselect>
        <b-form-invalid-feedback
          :state="getValidationState(validationContext)"
          id="input-feedback"
        >
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  name:"InputMultiSelectSimple",
  props: {
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select Option",
    },
    vmodel: {
      required:true
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      required: true,
    },
    isDisabled:{
      required:false,
      default:false
    },
    label:{
      required:false,
      default:"text"
    },
    trackBy:{
      required:false,
      default:"value"
    }
  },
  components: {
    ValidationProvider,
  },
  computed: {
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.labelName + "<span class='input-required'>*</span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function () {
        return this.vmodel;
      },
      set: function (value) {
        this.$emit("update:vmodel", value);
      },
    },
  },
  methods: {
    inputHandler() {
      this.$emit("onInput");
    },
    selectClassName(validationContext) {
      let className = "";
      if (this.rules) {
        if (validationContext.errors[0]) {
          className = "invalid-input-block";
        } else if (validationContext.valid) {
          className = "valid-input-block";
        } else {
          className = "";
        }
      }
      return `${className} ${this.inputClass}`;
    },
    autoCompleteHandler() {
      document
        .querySelector(".multiselect__input")
        .setAttribute("autocomplete", "off");
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style>
.input-required {
  color: red;
}

.invalid-input-block > .multiselect__tags {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.valid-input-block > .multiselect__tags {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
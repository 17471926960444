var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "add-dependent",
      "centered": "",
      "size": "xl",
      "visible": _vm.show,
      "hide-footer": ""
    },
    on: {
      "show": _vm.renderDependentInfo,
      "hide": _vm.handleClose
    }
  }, [_c('div', {
    staticClass: "title-text text-center mb-4"
  }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.isPlanTypeIF ? 'Add/Edit Dependents' : 'Add/Edit Dependent'))])])]), _c('div', {
    staticClass: "invalid-error-msg text-center mb-2"
  }, [_vm.isPlanTypeIF ? _c('span', [_vm._v(" For plan type family at least 1 spouse and 1 child is required in order to proceed. ")]) : _vm.isPlanTypeIC ? _c('span', [_vm._v(" For plan type (Member + Children) at least 1 child is required in order to proceed. ")]) : _vm.isPlanTypeIS ? _c('span', [_vm._v(" For plan type (Member + Spouse) only 1 spouse is required in order to proceed. ")]) : _vm._e()]), _vm.depInfo.length > 0 ? _c('div', {
    staticClass: "row g-0 mt-2 mb-2"
  }, _vm._l(_vm.depInfo, function (employee, index) {
    return _c('div', {
      key: index,
      staticClass: "col-xl-4"
    }, [_c('div', {
      staticClass: "edit-member-container "
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', [_c('span', {}, [_vm._v("Dependent " + _vm._s(index + 1) + ": " + _vm._s(employee.first_name) + " " + _vm._s(employee.last_name))])]), _c('div', {
      staticClass: "mt-1 text-end"
    }, [_c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "fas fa-edit mr-1 fa-icon-container fa-icon-edit text-capitalize gg",
      attrs: {
        "title": "Edit Dependent ".concat(index + 1, ": ").concat(employee.first_name, " ").concat(employee.last_name)
      },
      on: {
        "click": function click($event) {
          return _vm.editDependentInfo(index);
        }
      }
    }), _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "fas fa-trash-alt mr-1 fa-icon-container text-capitalize trash-icon-btn",
      attrs: {
        "title": "Delete Dependent ".concat(index + 1, ": ").concat(employee.first_name, " ").concat(employee.last_name)
      },
      on: {
        "click": function click($event) {
          return _vm.deleteDependentInfo(index);
        }
      }
    })])])])]);
  }), 0) : _vm._e(), _vm.showAddDepBtn ? _c('div', [_c('div', {
    staticClass: "row add-dep-inner-container"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("First Name")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.dep.first_name,
      expression: "dep.first_name"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.dep.first_name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "First Name"
    },
    domProps: {
      "value": _vm.dep.first_name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.dep, "first_name", $event.target.value);
      }
    }
  }), !_vm.$v.dep.first_name.required && _vm.$v.dep.first_name.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Last Name")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.dep.last_name,
      expression: "dep.last_name"
    }],
    staticClass: "input-field",
    class: {
      'form-invalid': _vm.$v.dep.last_name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Last Name"
    },
    domProps: {
      "value": _vm.dep.last_name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.dep, "last_name", $event.target.value);
      }
    }
  }), !_vm.$v.dep.last_name.required && _vm.$v.dep.last_name.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title mb-2"
  }, [_vm._v("DOB")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('date-picker', {
    staticClass: "date-picker-text",
    class: {
      'date-invalid': _vm.$v.dep.dob.$error
    },
    attrs: {
      "aria-describedby": "input-feedback",
      "placeholder": "MM/DD/YYYY",
      "value-type": "format",
      "format": "MM/DD/YYYY",
      "disabled-date": _vm.disableDates
    },
    model: {
      value: _vm.dep.dob,
      callback: function callback($$v) {
        _vm.$set(_vm.dep, "dob", $$v);
      },
      expression: "dep.dob"
    }
  }), !_vm.$v.dep.dob.required && _vm.$v.dep.dob.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()], 1), _c('div', {
    staticClass: "col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Gender")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.dep.gender,
      expression: "dep.gender"
    }],
    staticClass: "custom-select mt-2",
    class: {
      'form-invalid': _vm.$v.dep.gender.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.dep, "gender", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select Gender")]), _vm._l(_vm.genderList, function (gender, genderIndex) {
    return _c('option', {
      key: genderIndex,
      domProps: {
        "value": gender.value
      }
    }, [_vm._v(_vm._s(gender.text))]);
  })], 2), !_vm.$v.dep.gender.required && _vm.$v.dep.gender.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-4  col-xl-4 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    staticClass: "label-title"
  }, [_vm._v("Relationship")]), _c('span', {
    staticClass: "required-asterisk"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.dep.relationship,
      expression: "dep.relationship"
    }],
    staticClass: "custom-select mt-2",
    class: {
      'form-invalid': _vm.$v.dep.relationship.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.dep, "relationship", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select Relationship")]), _vm._l(_vm.relationShipList, function (relationship, relIndex) {
    return _c('option', {
      key: relIndex,
      class: {
        'disabled-option': _vm.isSpouseDisabled && relationship.value === 'S'
      },
      attrs: {
        "disabled": relationship.value === 'S' && _vm.isSpouseDisabled
      },
      domProps: {
        "value": relationship.value
      }
    }, [_vm._v(_vm._s(relationship.text))]);
  })], 2), !_vm.$v.dep.relationship.required && _vm.$v.dep.relationship.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Field is required ")]) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.showAddDepBtn ? _c('div', {
    staticClass: "text-center me-4"
  }, [_vm.editDependent ? _c('button', {
    staticClass: "add-dependent-btn",
    on: {
      "click": _vm.cancelDepModal
    }
  }, [_vm._v("Cancel")]) : _vm._e(), _c('button', {
    staticClass: "add-dependent-btn",
    on: {
      "click": _vm.addDependent
    }
  }, [_vm._v(_vm._s(_vm.editDependent ? 'Update' : ' + Add') + " Dependent")])]) : _vm._e()]), _c('div', [_vm.isDepInfoAddedValid ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "text-center me-4"
  }, [_c('button', {
    staticClass: "add-dependent-btn",
    on: {
      "click": _vm.submitDepInfo
    }
  }, [_vm._v("Submit")])])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    class: _vm.className,
    attrs: {
      "src": _vm.icon,
      "title": _vm.newTitle
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-form-group :class="formGroupClass">
    <validation-provider
      :name="labelName"
      :rules="rules"
      v-slot="validationContext"
    >
      <label :id="`label-${labelFor}`" :for="labelFor">
        <span v-html="computedLabel" />
      </label>

      <b-form-textarea
        :id="labelFor"
        :state="getValidationState(validationContext)"
        v-model="computedInputData"
        :readonly="readonly"
        :placeholder="computedPlaceholder"
        :rows="rows"
        :cols="cols"
      ></b-form-textarea>

      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: false,
    },
    vmodel: {
      required: true,
      default: null,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    rows: {
      type: Number,
      required: false,
      default: 6,
    },
    cols: {
      type: Number,
      required: false,
      default: 200,
    },
    placeholder : {
      required:false
    }
  },
  components: {
    ValidationProvider,
  },
  computed: {
    computedPlaceholder() {
      if (!this.placeholder) {
        return `Enter ${this.labelName}`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function () {
        return this.vmodel;
      },
      set: function (value) {
        this.$emit("update:vmodel", value ? value : "");
      },
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style>
.input-required {
  color: red !important;
}
</style>

<template>
  <a :href="link" target="_blank" v-b-tooltip.hover download :title="title">
    <i class="fas fa-download" />
  </a>
</template>
<script>
export default {
  name: "DownloadButton",
  props: {
    link: {
      type: String,
      required: true,
    },
    title:{
      required:false,
      type:String,
      default:"Download"
    }
  },
};
</script>
 
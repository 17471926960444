import Vue from 'vue'
import moment from "moment";

Vue.filter('truncate', function (value, limit) {
    if (value.length > limit) {
        return value.substring(0, limit) + "..."
    }
    return value
})

Vue.filter('currency', function (value) {
    
    value = (typeof value == 'string') ? parseFloat(value.replace(",", "")) : parseFloat(value)
    if (value % 1 == 0) {
        value= `$${parseInt(value)}.00`;
    }else{
        value= `$${value.toFixed(2)}`;
    }
    return value
})

Vue.filter('percentage', function (value, decimals) {
    if (!value) {
        value = 0
    }
    if (!decimals) {
        decimals = 0
    }
    value = value * 100
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    value = value + '%'
    return value
})

Vue.filter('us_phone', function (value) {
    var cleaned = ('' + value).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
})




Vue.filter('percent', function (value) {
    if (!value) {
        value = 0
    }
    value = value.toFixed(2) + '%'
    return value
})
Vue.filter('date_format', function (value, defaultFormat = "YYYY-MM-DD", dateFormat = "MM/DD/YYYY", defaultValue = '') {
    if (moment(value, defaultFormat, true).isValid()) {
        return moment(value, defaultFormat, true).format(dateFormat);
    }
    return defaultValue ? defaultValue : "N/A";
})


Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1)
});